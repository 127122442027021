import { Pipe, PipeTransform } from '@angular/core';
import { UnifiedSoundProfile } from '@models/sound-profile.model';

/**
 * Pipe to filter sound profiles by their types.
 * Usage: | filterProfilesByType:'hello':'clap':'laugh'
 */
@Pipe({
  name: 'filterProfilesByType',
  standalone: true
})
export class FilterProfilesByTypePipe implements PipeTransform {
  transform(
    results: { profile: UnifiedSoundProfile; similarity: number }[],
    ...types: string[]
  ): { profile: UnifiedSoundProfile; similarity: number }[] {
    return results.filter(result => types.includes(result.profile.type));
  }
}