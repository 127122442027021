<section class="max-w-full lg:max-w-screen-xl mx-auto p-12">
  <div class="mb-8 flex justify-between items-center">
    <button class="btn btn-default btn-outline" (click)="goBack()">
      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-left" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <line x1="5" y1="12" x2="19" y2="12" />
        <line x1="5" y1="12" x2="11" y2="18" />
        <line x1="5" y1="12" x2="11" y2="6" />
      </svg>
      Back to Users
    </button>
    <div class="flex items-center space-x-2">
      <a [routerLink]="['/admin/all-videos']" [queryParams]="{userId: user?.id}" class="btn btn-primary btn-outline">
        View User's Videos
      </a>
      @if (user && !user.roles.includes('admin') && !user.roles.includes('superadmin')) {
        <div class="dropdown dropdown-end">
          <label tabindex="0" class="btn btn-error m-1">Manage User</label>
          <ul tabindex="0" class="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
            <li><a class="text-warning" (click)="showConfirmation('rejected')">Suspend User</a></li>
            <li><a class="text-error" (click)="confirmDeleteUser()">Delete User</a></li>
          </ul>
        </div>
      }
    </div>
  </div>

  @if (user) {
    <div class="space-y-12 p-12 bg-base-100 border border-base-300 rounded-lg">
      <h2 class="text-3xl lg:text-4xl font-bold mb-8 text-secondary">
        {{ (user.firstName && user.lastName) ? (user.firstName + ' ' + user.lastName) : 'User Details' }}
      </h2>

      <div class="space-y-6">
        <div class="form-control">
          <label class="label">
            <span class="label-text font-bold">Approval Status</span>
          </label>
          <div class="flex items-center space-x-2">
            <span class="badge {{ getApprovalStatusClass(user.approvedStatus) }}">
              {{ user.approvedStatus | titlecase }}
            </span>
          </div>
        </div>

        @if (user.approvedStatus === 'rejected') {
          <div class="form-control">
            <label class="label">
              <span class="label-text font-bold">Rejection Reason</span>
            </label>
            <p>{{ user.approvedStatusReason || 'No reason provided' }}</p>
          </div>
        }

        <div class="form-control">
          <label class="label">
            <span class="label-text font-bold">Profile Complete</span>
          </label>
          <div class="flex items-center space-x-2">
            <span class="badge {{ user.profileComplete ? 'badge-success' : 'badge-warning' }}">
              {{ user.profileComplete ? 'Yes' : 'No' }}
            </span>
          </div>
        </div>

        <div class="form-control">
          <label class="label">
            <span class="label-text font-bold">Has user requested access?</span>
          </label>
          <div class="flex items-center space-x-2">
            <span class="badge {{ user.profileCompleteAccessRequestEmailSent ? 'badge-success' : 'badge-warning' }}">
              {{ user.profileCompleteAccessRequestEmailSent ? 'Yes' : 'No' }}
            </span>
            @if (user.profileCompleteAccessRequestEmailSent && user.profileCompleteAccessRequestDate) {
              <span class="text-sm text-gray-600">
                (Requested on: {{ user.profileCompleteAccessRequestDate | firestoreDate | date:'medium' }})
              </span>
            }
          </div>
        </div>

        <div class="divider"></div>

        <div class="form-control">
          <label class="label">
            <span class="label-text font-bold">First Name</span>
          </label>
          <p>{{ user.firstName || 'N/A' }}</p>
        </div>

        <div class="form-control">
          <label class="label">
            <span class="label-text font-bold">Last Name</span>
          </label>
          <p>{{ user.lastName || 'N/A' }}</p>
        </div>

        <div class="form-control">
          <label class="label">
            <span class="label-text font-bold">Occupation</span>
          </label>
          <p>{{ user.occupation || 'N/A' }}</p>
        </div>

        <div class="form-control">
          <label class="label">
            <span class="label-text font-bold">Work Email</span>
          </label>
          <p>{{ user.workEmail || 'N/A' }}</p>
        </div>

        <div class="form-control">
          <label class="label">
            <span class="label-text font-bold">Organisation Name</span>
          </label>
          <p>{{ user.organizationName || 'N/A' }}</p>
        </div>

        <div class="form-control">
          <label class="label">
            <span class="label-text font-bold">Organisation Address</span>
          </label>
          <p>{{ user.organizationAddress || 'N/A' }}</p>
        </div>

        <div class="form-control">
          <label class="label">
            <span class="label-text font-bold">AHPRA number or Professional License number</span>
          </label>
          <p>{{ user.licenseNumber || 'N/A' }}</p>
        </div>

        <div class="divider"></div>

        <div class="form-control">
          <label class="label">
            <span class="label-text font-bold">Prior experience with absence seizures</span>
          </label>
          <p>{{ user.priorExperience || 'N/A' }}</p>
        </div>
      </div>

      <div class="mt-6 flex justify-start space-x-4">
        @if (user.approvedStatus !== 'approved') {
          <button class="btn btn-success" (click)="showConfirmation('approved')">Approve</button>
        }
        @if (user.approvedStatus === 'approved') {
          <button class="btn btn-error" (click)="showConfirmation('rejected')">Suspend User</button>
        } @else if (user.approvedStatus !== 'rejected') {
          <button class="btn btn-error" (click)="showConfirmation('rejected')">Reject</button>
        }
      </div>
    </div>
  } @else {
    <div class="p-12">
      <p>Loading user details...</p>
    </div>
  }
</section>

<!-- Confirmation Modal -->
<dialog [open]="showConfirmationModal" class="modal modal-bottom sm:modal-middle">
  <div class="modal-box">
    <h3 class="text-lg font-bold">Confirm {{ actionType === 'delete' ? 'User Deletion' : 'Status Change' }}</h3>
    <p class="py-4">
      @if (actionType === 'approved') {
        An Approval email will now be sent to {{ user?.email }}
      } @else if (actionType === 'rejected') {
        A Rejection email will now be sent to {{ user?.email }}
      } @else if (actionType === 'suspend') {
        A Suspension email will now be sent to {{ user?.email }}
      } @else if (actionType === 'delete') {
        Are you sure you want to delete this user? This action cannot be undone.
      }
    </p>
    
    @if (actionType === 'rejected' || actionType === 'suspend') {
      <div class="form-control">
        <label class="label">
          <span class="label-text">Provide reason (optional):</span>
        </label>
        <textarea 
          class="textarea textarea-bordered" 
          placeholder="Enter reason for rejection or suspension"
          [(ngModel)]="rejectionReason">
        </textarea>
      </div>
    }

    <div class="modal-action flex justify-start">
      @if (actionType === 'delete') {
        <button class="btn btn-error mr-2" (click)="deleteUser()">Delete User</button>
      } @else {
        <button class="btn btn-primary mr-2" (click)="confirmStatusChange()">Ok, proceed</button>
      }
      <button class="btn btn-outline btn-secondary" (click)="cancelStatusChange()">Cancel</button>
    </div>
  </div>
</dialog>