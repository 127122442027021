import { Injectable, signal } from '@angular/core';

export interface Toast {
  message: string;
  type: 'success' | 'error' | 'info';
  id: number;
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private toasts = signal<Toast[]>([]);
  private counter = 0;

  getToasts() {
    return this.toasts;
  }

  showToast(message: string, type: 'success' | 'error' | 'info' = 'info') {
    const id = this.counter++;
    this.toasts.update(toasts => [...toasts, { message, type, id }]);
    setTimeout(() => this.removeToast(id), 5000); // Remove after 5 seconds
  }

  removeToast(id: number) {
    this.toasts.update(toasts => toasts.filter(t => t.id !== id));
  }

  show(message: string, type: 'success' | 'error' | 'info' = 'info') {
    this.showToast(message, type);
  }
}