import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoginModalService {
    private openLoginModalSource = new Subject<'login' | 'register'>();
    openLoginModal$ = this.openLoginModalSource.asObservable();

    openLoginModal(mode: 'login' | 'register') {
        this.openLoginModalSource.next(mode);
    }
}
