<section class="mt-0 pt-0 my-4 p-4 sm:my-8 sm:p-8 lg:my-12 lg:p-12 max-w-full lg:max-w-screen-xl mx-auto">
    <!-- Audio Library Controls -->
    <div class="mb-8 flex justify-between items-center">
        <h2 class="text-2xl font-bold text-secondary">Audio Library</h2>
        <details class="dropdown dropdown-end">
            <summary class="btn btn-ghost">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M4 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
                    <path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
                    <path d="M20 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
                </svg>
            </summary>
            <ul class="menu dropdown-content bg-base-100 rounded-box z-[1] w-52 p-2 shadow">
                <li>
                    <button (click)="testAgainstProfiles()" [disabled]="isRecording() || isTesting()">
                        {{ isTesting() ? 'Testing...' : 'Test against profiles' }}
                    </button>
                </li>
                <li>
                    <button (click)="exportAllProfiles()" [disabled]="isExporting()">
                        {{ isExporting() ? 'Exporting...' : 'Export All Profiles' }}
                    </button>
                </li>
                <li>
                    <button (click)="exportDeviceSettings()" [disabled]="isExporting()">
                        {{ isExporting() ? 'Exporting...' : 'Export Device Settings' }}
                    </button>
                </li>
            </ul>
        </details>
    </div>

    <!-- Webcam and Detection Settings Grid -->
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-8">
        <!-- Webcam Module -->
        <div class="card bg-base-100 lg:shadow-xl">
            <div class="card-body">
                <h3 class="card-title text-lg mb-4">Webcam Module</h3>
                <app-webcam 
                    [detectionMode]="false" 
                    [demoMode]="true"
                    [debugMode]="true"
                    class="transition-all duration-1000 ease-in-out">
                </app-webcam>
            </div>
        </div>

        <!-- Detection Settings Card -->
        <div class="card bg-base-100 lg:shadow-xl">
            <div class="card-body">
                <h3 class="card-title text-lg mb-4">Detection Settings</h3>
                
                <!-- Device Type Selector -->
                <div class="form-control w-full max-w-md mb-6">
                    <div *ngIf="showDeviceTypeWarning()" class="alert alert-warning shadow-sm mb-2">
                        <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                        </svg>
                        <span class="text-xs">Activate webcam and match device type</span>
                    </div>

                    <label class="label">
                        <span class="label-text font-medium">Device Type</span>
                    </label>
                    <select class="select select-bordered w-full" 
                            [ngModel]="selectedDeviceType()"
                            (ngModelChange)="onDeviceTypeChange($event)">
                        <option *ngFor="let type of deviceTypes" [value]="type">
                            {{ type }}
                        </option>
                    </select>
                    <label class="label">
                        <span class="label-text-alt text-xs text-base-content/70">
                            Settings below are specific to the selected device type
                        </span>
                    </label>
                </div>

                <!-- Divider -->
                <div class="divider"></div>
                
                <!-- Device-specific settings below -->
                <div *ngIf="selectedDeviceType()">
                    <!-- Main Sensitivity Control -->
                    <div class="form-control w-full max-w-md mb-6">
                        <label class="label">
                            <span class="label-text font-medium">Sound Sensitivity</span>
                            <span class="label-text-alt text-xs text-base-content/60">
                                (Low Frequency Energy Ratio)
                            </span>
                        </label>
                        <input type="range" 
                               class="range range-secondary" 
                               [ngModel]="(currentDeviceSettings()?.lowFrequencyEnergyRatio ?? 0.4) * 100"
                               (ngModelChange)="onLowFrequencyEnergyRatioChange($event)" 
                               min="0" 
                               max="100" 
                               step="5" />
                        <div class="label">
                            <span class="label-text-alt">Less Sensitive</span>
                            <span class="label-text-alt">More Sensitive</span>
                        </div>
                        <p class="text-xs text-base-content/70 mt-1">
                            Adjusts how easily the system detects breath sounds
                        </p>
                    </div>

                    <!-- Sound Stability -->
                    <div class="form-control w-full max-w-md mb-6">
                        <label class="label">
                            <span class="label-text font-medium">Sound Stability Required</span>
                            <span class="label-text-alt text-xs text-base-content/60">
                                (Consistency Tolerance)
                            </span>
                        </label>
                        <input type="range" 
                               class="range range-secondary" 
                               [ngModel]="(currentDeviceSettings()?.consistencyTolerance ?? 0.3) * 100"
                               (ngModelChange)="onConsistencyToleranceChange($event)" 
                               min="0" 
                               max="100" 
                               step="5" />
                        <div class="label">
                            <span class="label-text-alt">More Forgiving</span>
                            <span class="label-text-alt">More Strict</span>
                        </div>
                        <p class="text-xs text-base-content/70 mt-1">
                            Controls how steady the sound needs to be for detection
                        </p>
                    </div>

                    <!-- Advanced Settings Collapse -->
                    <div class="collapse collapse-plus bg-base-200">
                        <input type="checkbox" /> 
                        <div class="collapse-title text-sm font-medium">
                            Advanced Settings
                        </div>
                        <div class="collapse-content">
                            <!-- Frequency Threshold -->
                            <div class="form-control w-full max-w-md mb-4">
                                <label class="label">
                                    <span class="label-text">Low Frequency Threshold</span>
                                    <span class="label-text-alt text-xs text-base-content/60">Hz</span>
                                </label>
                                <input type="number" 
                                       class="input input-bordered w-full" 
                                       [ngModel]="currentDeviceSettings()?.lowFrequencyThreshold"
                                       (ngModelChange)="onLowFrequencyThresholdChange($event)"
                                       min="0" />
                                <label class="label">
                                    <span class="label-text-alt">Range: 0-500 Hz</span>
                                </label>
                            </div>

                            <!-- Frequency Tolerance -->
                            <div class="form-control w-full max-w-md">
                                <label class="label">
                                    <span class="label-text">Frequency Tolerance</span>
                                    <span class="label-text-alt text-xs text-base-content/60">Hz</span>
                                </label>
                                <input type="number" 
                                       class="input input-bordered w-full" 
                                       [ngModel]="currentDeviceSettings()?.frequencyTolerance"
                                       (ngModelChange)="onFrequencyToleranceChange($event)"
                                       min="0" />
                                <label class="label">
                                    <span class="label-text-alt">Range: 0-500 Hz</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Sound Profiles Tabs -->
    <div class="card bg-base-100 lg:shadow-xl">
        <div class="card-body">
            <h3 class="card-title text-lg mb-4">Sound Pattern Recording</h3>
            
            <div role="tablist" class="tabs tabs-boxed bg-base-200">
                <button role="tab" 
                        class="tab tab-lg transition-all duration-200" 
                        [class.tab-active]="activeTab() === 'positive'"
                        [class.btn-secondary]="activeTab() === 'positive'"
                        (click)="setActiveTab('positive')">
                    <span class="flex items-center gap-2">
                        <span>💨</span>
                        <span>Positive Pattern</span>
                    </span>
                </button>
                <button role="tab" 
                        class="tab tab-lg transition-all duration-200" 
                        [class.tab-active]="activeTab() === 'negative'"
                        [class.btn-secondary]="activeTab() === 'negative'"
                        (click)="setActiveTab('negative')">
                    <span class="flex items-center gap-2">
                        <span>❌</span>
                        <span>Negative Patterns</span>
                    </span>
                </button>
            </div>

            <!-- Positive Tab Content -->
            <div [class.hidden]="activeTab() !== 'positive'" class="mt-4">
                <div class="card bg-base-100 mb-8 px-1 lg:px-4">
                    <div class="card-body px-1 lg:px-4">
                        <h3 class="card-title text-lg font-semibold">Positive Blow Pattern Calibration:</h3>
                        <p class="mb-4">Create a frequency domain profile of blow audio that will be used for FFT-based pattern
                            matching.</p>

                        <div class="grid grid-cols-1 gap-6 mb-8">
                            <!-- Breath Pattern Card -->
                            <div class="card bg-base-100">
                                <div class="card-body px-1 lg:px-4">
                                    <h4 class="card-title text-base">Blow Pattern</h4>
                                    <button class="btn btn-primary w-fit relative" (click)="startRecording('blow')"
                                        [disabled]="isRecording() || isTesting() || breathProfiles().length >= MAX_PROFILES_PER_TYPE">
                                        💨 Record Blow
                                        <span *ngIf="isRecording() && !isTesting() && currentRecordingType() === 'blow'"
                                            class="absolute -top-1 -right-1 flex h-3 w-3">
                                            <span
                                                class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                                            <span class="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                                        </span>
                                    </button>
                                    <div class="overflow-x-auto">
                                        <table class="table w-full">
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Created</th>
                                                    <th>Created By</th>
                                                    <th>Device Type</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let profile of breathProfiles(); trackBy: trackByFn">
                                                    <td>{{ profile.id }}</td>
                                                    <td>{{ profile.createdAt | timestamp | date:'short' }}</td>
                                                    <td>{{ getUserDisplayName(profile.createdBy) }}</td>
                                                    <td>
                                                        <select class="select select-bordered w-full max-w-xs"
                                                            [(ngModel)]="profile.deviceType" (change)="updateDeviceType(profile)">
                                                            <option [ngValue]="null">Not specified</option>
                                                            <option *ngFor="let type of deviceTypes" [value]="type">{{ type }}
                                                            </option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <button class="btn btn-circle btn-sm" (click)="playAudio(profile)"
                                                            [disabled]="isPlaying() === profile.id">
                                                            <ng-container *ngIf="isPlaying() !== profile.id; else pauseIcon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
                                                                    viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path stroke-linecap="round" stroke-linejoin="round"
                                                                        stroke-width="2"
                                                                        d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                                                                    <path stroke-linecap="round" stroke-linejoin="round"
                                                                        stroke-width="2" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                </svg>
                                                            </ng-container>
                                                            <ng-template #pauseIcon>
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
                                                                    viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path stroke-linecap="round" stroke-linejoin="round"
                                                                        stroke-width="2"
                                                                        d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                </svg>
                                                            </ng-template>
                                                        </button>
                                                        <button class="btn btn-sm btn-ghost"
                                                            (click)="confirmDelete(profile)">🗑️</button>
                                                        <button class="btn btn-sm btn-ghost"
                                                            (click)="showProfileDetails(profile)">👁️</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Negative Tab Content -->
            <div [class.hidden]="activeTab() !== 'negative'" class="mt-4">
                <h4 class="text-lg font-semibold mb-4">Negative Pattern Calibration</h4>
                <p class="mb-6">Create frequency domain profiles of common sounds that should not trigger the device.</p>
                
                <!-- Laugh Section -->
                <div class="mb-8">
                    <h5 class="text-md font-medium mb-2">Laugh Pattern</h5>
                    <div class="card">
                        <div class="card-body px-1">
                            <button class="btn btn-primary w-fit relative" (click)="startRecording('laugh')"
                                [disabled]="isRecording() || laughProfiles().length >= MAX_PROFILES_PER_TYPE">
                                😄 Record Laugh
                                <span *ngIf="isRecording() && currentRecordingType() === 'laugh'"
                                    class="absolute -top-1 -right-1 flex h-3 w-3">
                                    <span
                                        class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                                    <span class="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                                </span>
                            </button>
                            <div class="overflow-x-auto">
                                <table class="table w-full">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Created</th>
                                            <th>Created By</th>
                                            <th>Device Type</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let profile of laughProfiles(); trackBy: trackByFn">
                                            <td>{{ profile.id }}</td>
                                            <td>{{ profile.createdAt | timestamp | date:'short' }}</td>
                                            <td>{{ getUserDisplayName(profile.createdBy) }}</td>
                                            <td>
                                                <select [(ngModel)]="profile.deviceType"
                                                    (change)="updateDeviceType(profile)"
                                                    class="select select-bordered w-full max-w-xs">
                                                    <option [ngValue]="null">Not specified</option>
                                                    <option *ngFor="let type of deviceTypes" [value]="type">{{ type }}
                                                    </option>
                                                </select>
                                            </td>
                                            <td>
                                                <button class="btn btn-circle btn-sm" (click)="playAudio(profile)"
                                                    [disabled]="isPlaying() === profile.id">
                                                    <ng-container *ngIf="isPlaying() !== profile.id; else pauseIcon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
                                                            viewBox="0 0 24 24" stroke="currentColor">
                                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                                stroke-width="2"
                                                                d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                                stroke-width="2" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                        </svg>
                                                    </ng-container>
                                                    <ng-template #pauseIcon>
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
                                                            viewBox="0 0 24 24" stroke="currentColor">
                                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                                stroke-width="2"
                                                                d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                        </svg>
                                                    </ng-template>
                                                </button>
                                                <button class="btn btn-sm btn-ghost"
                                                    (click)="confirmDelete(profile)">🗑️</button>
                                                <button class="btn btn-sm btn-ghost"
                                                    (click)="showProfileDetails(profile)">👁️</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Hello Section -->
                <div class="mb-8">
                    <h5 class="text-md font-medium mb-2">Hello Pattern</h5>
                    <div class="card">
                        <div class="card-body px-1">
                            <button class="btn btn-primary w-fit relative" (click)="startRecording('hello')"
                                [disabled]="isRecording() || helloProfiles().length >= MAX_PROFILES_PER_TYPE">
                                👋 Record Hello
                                <span *ngIf="isRecording() && currentRecordingType() === 'hello'"
                                    class="absolute -top-1 -right-1 flex h-3 w-3">
                                    <span
                                        class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                                    <span class="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                                </span>
                            </button>
                            <div class="overflow-x-auto">
                                <table class="table w-full">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Created</th>
                                            <th>Created By</th>
                                            <th>Device Type</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let profile of helloProfiles(); trackBy: trackByFn">
                                            <td>{{ profile.id }}</td>
                                            <td>{{ profile.createdAt | timestamp | date:'short' }}</td>
                                            <td>{{ getUserDisplayName(profile.createdBy) }}</td>
                                            <td>
                                                <select class="select select-bordered w-full max-w-xs"
                                                    [(ngModel)]="profile.deviceType" (change)="updateDeviceType(profile)">
                                                    <option [ngValue]="null">Not specified</option>
                                                    <option *ngFor="let type of deviceTypes" [value]="type">{{ type }}
                                                    </option>
                                                </select>
                                            </td>
                                            <td>
                                                <button class="btn btn-circle btn-sm" (click)="playAudio(profile)"
                                                    [disabled]="isPlaying() === profile.id">
                                                    <ng-container *ngIf="isPlaying() !== profile.id; else pauseIcon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
                                                            viewBox="0 0 24 24" stroke="currentColor">
                                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                                stroke-width="2"
                                                                d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                                stroke-width="2" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                        </svg>
                                                    </ng-container>
                                                    <ng-template #pauseIcon>
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
                                                            viewBox="0 0 24 24" stroke="currentColor">
                                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                                stroke-width="2"
                                                                d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                        </svg>
                                                    </ng-template>
                                                </button>
                                                <button class="btn btn-sm btn-ghost"
                                                    (click)="confirmDelete(profile)">🗑️</button>
                                                <button class="btn btn-sm btn-ghost"
                                                    (click)="showProfileDetails(profile)">👁️</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Clap Section -->
                <div class="mb-8">
                    <h5 class="text-md font-medium mb-2">Clap Pattern</h5>
                    <div class="card">
                        <div class="card-body px-1">
                            <button class="btn btn-primary w-fit relative" (click)="startRecording('clap')"
                                [disabled]="isRecording() || clapProfiles().length >= MAX_PROFILES_PER_TYPE">
                                👏 Record Clap
                                <span *ngIf="isRecording() && currentRecordingType() === 'clap'"
                                    class="absolute -top-1 -right-1 flex h-3 w-3">
                                    <span
                                        class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                                    <span class="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                                </span>
                            </button>
                            <div class="overflow-x-auto">
                                <table class="table w-full">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Created</th>
                                            <th>Created By</th>
                                            <th>Device Type</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let profile of clapProfiles(); trackBy: trackByFn">
                                            <td>{{ profile.id }}</td>
                                            <td>{{ profile.createdAt | timestamp | date:'short' }}</td>
                                            <td>{{ getUserDisplayName(profile.createdBy) }}</td>
                                            <td>
                                                <select class="select select-bordered w-full max-w-xs"
                                                    [(ngModel)]="profile.deviceType" (change)="updateDeviceType(profile)">
                                                    <option [ngValue]="null">Not specified</option>
                                                    <option *ngFor="let type of deviceTypes" [value]="type">{{ type }}
                                                    </option>
                                                </select>
                                            </td>
                                            <td>
                                                <button class="btn btn-circle btn-sm" (click)="playAudio(profile)"
                                                    [disabled]="isPlaying() === profile.id">
                                                    <ng-container *ngIf="isPlaying() !== profile.id; else pauseIcon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
                                                            viewBox="0 0 24 24" stroke="currentColor">
                                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                                stroke-width="2"
                                                                d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                                stroke-width="2" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                        </svg>
                                                    </ng-container>
                                                    <ng-template #pauseIcon>
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
                                                            viewBox="0 0 24 24" stroke="currentColor">
                                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                                stroke-width="2"
                                                                d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                        </svg>
                                                    </ng-template>
                                                </button>
                                                <button class="btn btn-sm btn-ghost"
                                                    (click)="confirmDelete(profile)">🗑️</button>
                                                <button class="btn btn-sm btn-ghost"
                                                    (click)="showProfileDetails(profile)">👁️</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Add this at the end of your component template -->
    <dialog id="profile_details_modal" class="modal">
        <div class="modal-box">
            <div class="modal-action">
                <form method="dialog">
                    <button class="btn">Close</button>
                </form>
            </div>
            <h3 class="font-bold text-lg mb-4">Profile Details</h3>
            @if (selectedProfile) {
            <div class="overflow-x-auto">
                <table class="table w-full">
                    <thead>
                        <tr>
                            <th>Property</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        @for (entry of selectedProfileEntries; track entry.key) {
                        <tr>
                            <td>{{ entry.key }}</td>
                            <td>
                                @if (isArray(entry.value)) {
                                <div class="flex items-center">
                                    <span class="mr-2">{{ formatValue(entry.value, entry.key) }}</span>
                                    <button class="btn btn-xs" (click)="toggleArrayExpansion(entry.key)">
                                        {{ expandedArrays[entry.key] ? 'Collapse' : 'Expand' }} Data
                                    </button>
                                </div>
                                } @else {
                                {{ formatValue(entry.value, entry.key) }}
                                }
                            </td>
                        </tr>
                        }
                    </tbody>
                </table>
            </div>
            }

        </div>
    </dialog>

</section>
