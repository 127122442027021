<div class="drawer z-10">
    <input id="my-drawer-3" type="checkbox" class="drawer-toggle" #drawerCheckbox />
    <div class="drawer-content flex flex-col">
      <!-- Navbar -->
      <div class="w-full navbar sticky fixed-1 lg:static top-50 z-20 lg:pe-4"> 
        <div class="flex-none lg:hidden">
          <label for="my-drawer-3" aria-label="open sidebar" class="btn btn-square btn-ghost">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              class="inline-block w-6 h-6 stroke-current">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </label>
        </div>
        <div class="flex-1 flex items-center lg:mx-2">
          <a routerLink="/" routerLinkActive="active" class="mr-4 flex items-center">
            <img src="assets/images/logo.svg" alt="Logo" class="h-6 mr-2  sm:inline-block">
            <span class="text-sm sm:text-lg text-accent font-semibold whitespace-nowrap hidden sm:inline">Trigger absence</span>
          </a>
          <ul class="menu menu-horizontal gap-2 hidden lg:flex">
            <li><a routerLink="/" routerLinkActive="active">Home</a></li>
            <li><a href="#process">The Process</a></li>
            <li><a href="#aboutUs">About Us</a></li>
            <li><a href="#contactUs">Contact Us</a></li>
          </ul>
        </div>
        <div class="flex-none">
          <app-login></app-login>
        </div>
      </div>
    </div>
    <div class="drawer-side z-30">
      <label for="my-drawer-3" aria-label="close sidebar" class="drawer-overlay"></label>      
      <ul class="menu p-4 w-80 min-h-full bg-base-200 pt-20">        
        <li><a routerLink="/" routerLinkActive="active" (click)="toggleDrawer()" class="btn btn-default text-start py-4 px-6 text-xl">Home</a></li>
        <li><a href="#process" (click)="toggleDrawer()" class="btn btn-default text-start py-4 px-6 text-xl">The Process</a></li>
        <li><a href="#aboutUs" (click)="toggleDrawer()" class="btn btn-default text-start py-4 px-6 text-xl">About Us</a></li>
        <li><a href="#contactUs" (click)="toggleDrawer()" class="btn btn-default text-start py-4 px-6 text-xl">Contact Us</a></li>
      </ul>
    </div>
  </div>