<div class="music-container">
    <div *ngFor="let note of notes" 
         class="music-note" 
         [class.paused]="!isAnimating"
         [style.top]="note.top" 
         [style.left]="note.left"
         [style.font-size]="note.size"
         [style.animation-delay]="note.delay"
         [style.animation-duration]="note.duration">
      🎵
    </div>
  </div>