import { Component, OnInit, inject, signal, computed } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AdminStats } from '@shared/models/admin-stats.model';
import { AdminStatsService } from '@shared/services/admin-stats.service';

@Component({
  selector: 'app-admin-overview',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class OverviewComponent implements OnInit {
  private adminStatsService = inject(AdminStatsService);

  stats = signal<AdminStats | null>(null);
  error = signal<string | null>(null);
  loading = signal(true);
  compareAllTime = signal(false);

  showContent = computed(() => this.stats() !== null && !this.loading() && !this.error());

  protected readonly Object = Object;

  ngOnInit() {
    this.fetchStats();
  }

  async fetchStats() {
    try {
      this.loading.set(true);
      await this.adminStatsService.updateStats();
      const statsDoc = await this.adminStatsService.getStats();
      if (statsDoc) {
        this.stats.set(statsDoc);
      } else {
        this.error.set('Admin stats document does not exist');
      }
    } catch (err) {
      console.error('Error fetching admin stats:', err);
      this.error.set('Error fetching admin stats');
    } finally {
      this.loading.set(false);
    }
  }

  toggleCompareAllTime() {
    this.compareAllTime.update(value => !value);
  }
}