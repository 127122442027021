import { Component, OnInit, Input, AfterViewInit, ViewChild, ElementRef, ChangeDetectionStrategy, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Chart, ChartConfiguration, ChartDataset } from 'chart.js';
import { UnifiedSoundProfile } from '@models/sound-profile.model';

@Component({
  selector: 'app-sound-profile-visualiser',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './sound-profile-visualiser.component.html', 
  styleUrls: ['./sound-profile-visualiser.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SoundProfileVisualiserComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() profiles: UnifiedSoundProfile[] = [];
  @ViewChild('chartCanvas') chartCanvasRef!: ElementRef;

  private chart: Chart | undefined;
  private previousProfilesHash: string = '';

  activeTab: 'peakFreqVsConsistency' | 'riseVsFall' | 'averageVsMax' = 'peakFreqVsConsistency';

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    console.log('SoundProfileVisualiser: ngOnInit called');
  }

  ngAfterViewInit() {
    console.log('SoundProfileVisualiser: ngAfterViewInit called');
    this.initializeChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['profiles'] && !changes['profiles'].firstChange) {
      const currentHash = this.hashProfiles(this.profiles);
      if (currentHash !== this.previousProfilesHash) {
        this.updateChart();
        this.previousProfilesHash = currentHash;
      }
    }
  }

  private initializeChart() {
    const ctx = this.chartCanvasRef.nativeElement.getContext('2d');
    const config: ChartConfiguration<'scatter'> = {
      type: 'scatter',
      data: {
        datasets: this.createDatasets()
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            type: 'linear',
            position: 'bottom',
            title: { display: true, text: 'Peak Frequency (Hz)' }
          },
          y: {
            type: 'linear',
            position: 'left',
            title: { display: true, text: 'Consistency Score' }
          }
        }
      }
    };

    this.chart = new Chart(ctx, config);
    console.log('SoundProfileVisualiser: Chart initialized');
  }

  private updateChart() {
    if (!this.chart) {
      console.log('SoundProfileVisualiser: Chart not initialized, initializing now');
      this.initializeChart();
      return;
    }

    this.updateChartOptions();
    this.chart.data.datasets = this.createDatasets();
    this.chart.update();
  }

  private createDatasets(): ChartDataset<'scatter', { x: number; y: number; }[]>[] {
    const profileTypes = ['blow', 'laugh', 'clap', 'hello'];
    const colors = ['rgba(75, 192, 192, 0.6)', 'rgba(255, 99, 132, 0.6)', 'rgba(255, 206, 86, 0.6)', 'rgba(153, 102, 255, 0.6)'];

    const datasets: ChartDataset<'scatter', { x: number; y: number; }[]>[] = profileTypes.map((type, index) => ({
      type: 'scatter',
      label: type.charAt(0).toUpperCase() + type.slice(1),
      data: this.profiles
        .filter(p => p.type === type && p.id !== 'test-result')
        .map(p => this.getDataPoint(p)),
      backgroundColor: colors[index],
      pointRadius: 5,
      pointHoverRadius: 7,
    }));

    // Add test result as a separate dataset
    const testResult = this.profiles.find(p => p.id === 'test-result');
    if (testResult) {
      datasets.push({
        type: 'scatter',
        label: 'Test Result',
        data: [this.getDataPoint(testResult)],
        backgroundColor: 'rgba(255, 255, 255, 1)',
        pointRadius: 10,
        pointHoverRadius: 12,
        borderColor: 'rgba(0, 0, 0, 1)',
        borderWidth: 2,
      });
    }

    return datasets;
  }

  private getDataPoint(profile: UnifiedSoundProfile): { x: number, y: number } {
    let x, y;
    if (this.activeTab === 'peakFreqVsConsistency') {
      x = profile.peakFrequency || 0;
      y = profile.consistencyScore || 0;
    } else if (this.activeTab === 'riseVsFall') {
      x = profile.riseTime * 1000 || 0;
      y = profile.fallTime * 1000 || 0;
    } else {
      x = profile.averageLevel || 0;
      y = profile.maxLevel || 0;
    }
    return { x, y };
  }

  setActiveTab(tab: 'peakFreqVsConsistency' | 'riseVsFall' | 'averageVsMax') {
    this.activeTab = tab;
    this.updateChart();
  }

  private updateChartOptions() {
    if (!this.chart) return;

    let xAxisTitle, yAxisTitle;
    if (this.activeTab === 'peakFreqVsConsistency') {
      xAxisTitle = 'Peak Frequency (Hz)';
      yAxisTitle = 'Consistency Score';
    } else if (this.activeTab === 'riseVsFall') {
      xAxisTitle = 'Rise Time (ms)';
      yAxisTitle = 'Fall Time (ms)';
    } else {
      xAxisTitle = 'Average Level';
      yAxisTitle = 'Max Level';
    }

    this.chart.options.scales = {
      x: {
        type: 'linear',
        position: 'bottom',
        title: { display: true, text: xAxisTitle }
      },
      y: {
        type: 'linear',
        position: 'left',
        title: { display: true, text: yAxisTitle }
      }
    };
  }

  private hashProfiles(profiles: UnifiedSoundProfile[]): string {
    return profiles.map(p => p.id).sort().join(',');
  }
}