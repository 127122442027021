<div class="bubble-container">
  <div *ngFor="let bubble of bubbles" 
       class="bubble" 
       [class.paused]="!isAnimating"
       [style.left]="bubble.left" 
       [style.font-size]="bubble.size"
       [style.animation-delay]="bubble.delay"
       [style.animation-duration]="bubble.duration">
    🫧
  </div>
</div>