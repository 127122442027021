import { Component, computed, signal, effect, inject, OnInit } from '@angular/core';
import { RouterOutlet, Router, NavigationEnd } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import '@shared/firebase-config'; // Initialize Firebase
import { UserService } from '@shared/services/user.service';
import { filter } from 'rxjs/operators';
import { ToastService } from '@shared/services/toast.service';
import { CommonModule } from '@angular/common';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { SoundProfileService } from '@shared/services/sound-profile.service'; // Import SoundProfileService

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  imports: [RouterOutlet, SharedModule, CommonModule]
})
export class AppComponent implements OnInit {
  private userService = inject(UserService);
  private router = inject(Router);
  private toastService = inject(ToastService);
  private swUpdate = inject(SwUpdate);
  private soundProfileService = inject(SoundProfileService); // Inject SoundProfileService
  toasts = this.toastService.getToasts();

  showPendingBanner = computed(() => {
    const user = this.userService.user();
    return user && user.approvedStatus === 'pending' && !user.profileComplete;
  });

  showProfileUpdatedBanner = signal(false);
  showUpdateAlert = signal(false);

  constructor() {
    // Effect for user profile updates
    effect(() => {
      const user = this.userService.user();
      if (user && user.profileComplete && user.approvedStatus === 'pending') {
        // Use queueMicrotask to schedule the signal update outside of the effect
        queueMicrotask(() => {
          this.showProfileUpdatedBanner.set(true);
        });
      }
    });

    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe(() => {
      // Hide the banner when navigating to a new page
      this.showProfileUpdatedBanner.set(false);
    });

    // Load sound profiles on initialization
    this.soundProfileService.loadSoundProfiles();

    // Effect to initialize audio detection once profiles are loaded
    effect(() => {
      if (this.soundProfileService.profilesLoaded()) {
        console.log('Sound profiles loaded. Initializing audio detection.');
        // Initialize or start audio detection here
        // For example:
        // this.audioDetectionService.startListening();
      }
    });
  }

  ngOnInit() {
    if (this.swUpdate.isEnabled) {
      // Immediate check for updates
      this.swUpdate.checkForUpdate().then(() => {
        console.log('Checked for updates');
      });

      this.swUpdate.versionUpdates.subscribe(event => {
        if (event.type === 'VERSION_READY') {
          console.log('New version available:', (event as VersionReadyEvent).latestVersion);
          this.showUpdateAlert.set(true);
        }
      });

      // Periodically check for updates (e.g., every 6 hours)
      setInterval(() => {
        this.swUpdate.checkForUpdate();
      }, 6 * 60 * 60 * 1000);
    }
  }

  updateApp() {
    this.swUpdate.activateUpdate().then(() => document.location.reload());
  }

  navigateToAccount() {
    this.router.navigate(['/user/account']);
  }

  dismissProfileUpdatedBanner() {
    this.showProfileUpdatedBanner.set(false);
  }
}