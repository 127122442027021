import { Component, ViewChild } from '@angular/core';
import { LoginComponent } from '../login/login.component';
import { environment } from '@environments/environment';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  currentYear: number = new Date().getFullYear();
  version: string = environment.version;
  @ViewChild('loginComponent') loginComponent!: LoginComponent;
}
