import { Injectable } from '@angular/core';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { BehaviorSubject } from 'rxjs';
import { getFirestoreInstance, getAuthInstance } from '../firebase-config';
import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AppConfigService {
    private configSubject = new BehaviorSubject<any>(null);
    config$ = this.configSubject.asObservable();

    constructor() { }

    async loadConfig(): Promise<void> {
        const firestore = getFirestoreInstance();
        const auth = getAuthInstance();

        // If we're in development and bypassAuth is true, wait for default auth
        if (environment.useEmulators && environment.bypassAuth) {
            // Wait for a moment to ensure auth is initialized
            await new Promise(resolve => setTimeout(resolve, 1000));
        }

        try {
            const configRef = doc(firestore, 'config', 'root');
            const configSnap = await getDoc(configRef);
            
            if (configSnap.exists()) {
                this.configSubject.next(configSnap.data());
            } else {
                const defaultConfig = { erudaDebug: false };
                await setDoc(configRef, defaultConfig);
                this.configSubject.next(defaultConfig);
            }
        } catch (error) {
            console.error('Error loading config:', error);
            // Fallback to default config
            this.configSubject.next({ erudaDebug: false });
        }
    }

    get erudaDebug(): boolean {
        return this.configSubject.value?.erudaDebug || false;
    }
}