import { Injectable, inject } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserService } from '@services/user.service';
import { map, take } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  private userService = inject(UserService);
  private router = inject(Router);

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    if (environment.bypassAuth) {
      return of(true);
    }

    return this.userService.getUserState().pipe(
      map(user => {
        const isAdmin = this.userService.isAdmin();
        const isPending = this.userService.isPending();

        if (!user) {
          return this.router.createUrlTree(['/']);
        }

        if (route.data['requiresAdmin'] && !isAdmin) {
          return this.router.createUrlTree(['/']);
        }

        if (route.data['requiresApproval'] && isPending) {
          // Check if the route is for the "My Videos" page
          if (state.url.includes('/user/videos')) {
            return true;
          }

          return this.router.createUrlTree(['/user/account']);
        }

        return true;
      })
    );
  }
}