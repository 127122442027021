import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';

interface NoteData {
  top: string;
  left: string;
  size: string;
  delay: string;
  duration: string;
}

@Component({
  selector: 'app-music-overlay',
  templateUrl: './music-overlay.component.html',
  styleUrls: ['./music-overlay.component.css'],
  standalone: true,
  imports: [CommonModule]
})
export class MusicOverlayComponent implements OnChanges {
  @Input() isAnimating: boolean = false;
  @Input() audioLevel: number = 0;

  notes: NoteData[] = [];
  private readonly MAX_NOTES = 20;
  private readonly MIN_DURATION = 3;
  private readonly MAX_DURATION = 6;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['audioLevel']) {
      this.updateNotes();
    }
  }

  private updateNotes() {
    const targetCount = Math.min(Math.floor(this.audioLevel * 20) + 5, this.MAX_NOTES);

    while (this.notes.length < targetCount) {
      this.notes.push(this.createNoteData());
    }
    while (this.notes.length > targetCount) {
      this.notes.pop();
    }

    this.notes.forEach(note => {
      note.duration = `${this.MIN_DURATION + (1 - this.audioLevel) * (this.MAX_DURATION - this.MIN_DURATION)}s`;
    });
  }

  private createNoteData(): NoteData {
    return {
      top: `${Math.random() * 100}%`,
      left: '-50px',
      size: `${Math.random() * 1 + 1}rem`,
      delay: `-${Math.random() * this.MAX_DURATION}s`,
      duration: `${this.MIN_DURATION + Math.random() * (this.MAX_DURATION - this.MIN_DURATION)}s`
    };
  }
}