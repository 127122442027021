<div class="tabs tabs-bordered flex justify-start">
    <a class="tab" [class.tab-active]="activeTab === 'peakFreqVsConsistency'"
        (click)="setActiveTab('peakFreqVsConsistency')">Peak Freq vs Consistency</a>
    <a class="tab" [class.tab-active]="activeTab === 'riseVsFall'" (click)="setActiveTab('riseVsFall')">Rise vs Fall</a>
    <a class="tab" [class.tab-active]="activeTab === 'averageVsMax'" (click)="setActiveTab('averageVsMax')">Average vs
        Max</a>
</div>

<div class="mt-4 mb-6">
    <div *ngIf="activeTab === 'peakFreqVsConsistency'" class="text-sm">
        <h3 class="font-semibold mb-2">Peak Freq vs Consistency</h3>
        <p>This scatter plot illustrates the relationship between the peak frequency and consistency score of sound
            profiles, offering insights into spectral and temporal stability. Blow sounds generally have lower peak
            frequencies and moderate consistency scores, so profiles with unusually high frequencies or extreme
            consistency values in the "blow" category could indicate potential misclassifications.</p>
    </div>
    <div *ngIf="activeTab === 'riseVsFall'" class="text-sm">
        <h3 class="font-semibold mb-2">Rise vs Fall</h3>
        <p>This chart plots the rise time against the fall time of sound profiles, highlighting the distinctive envelope
            characteristics of different sound types. Blow profiles typically cluster with shorter rise times and longer
            fall times, so any outliers in this region might indicate non-blow sounds being misclassified as blows.</p>
    </div>
    <div *ngIf="activeTab === 'averageVsMax'" class="text-sm">
        <h3 class="font-semibold mb-2">Average vs Max</h3>
        <p>By comparing the average sound level to the maximum level, this visualization reveals the dynamic range and
            intensity patterns of various sound profiles. Blow sounds often exhibit a moderate average level with a
            higher max level, so profiles deviating significantly from this pattern may suggest incorrect
            classifications.</p>
    </div>
</div>

<div class="chart-container relative w-full max-w-[1024px]">
    <canvas #chartCanvas></canvas>
</div>