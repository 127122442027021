import { Injectable, inject, signal, effect, Injector, runInInjectionContext, OnInit } from '@angular/core';
import { Firestore, collection, collectionData } from '@angular/fire/firestore';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { UnifiedSoundProfile, DeviceType, DEVICE_TYPES } from '@models/sound-profile.model';
import { toObservable } from '@angular/core/rxjs-interop';
import { SoundProfileService } from './sound-profile.service';
import { DeviceSettingsService } from './device-settings.service';

// Constants for audio detection
const DEBUG_MODE = false;
const FFT_SIZE = 1024;
const SAMPLE_RATE = 44100;
const LOW_FREQUENCY_THRESHOLD = 400; // Hz
const FREQUENCY_TOLERANCE = 300; // Hz
const CONSISTENCY_TOLERANCE = 0.3;
const LOW_FREQUENCY_ENERGY_RATIO_TOLERANCE = 0.2;
const AMPLITUDE_TOLERANCE = 0.3;
const THRESHOLD_REDUCTION_FACTOR = 0.8;

const RECALIBRATION_INTERVAL = 5000; // Recalibrate every 5 seconds

interface AudioFeatures {
  peakFrequency: number;
  consistencyScore: number;
  averageAmplitude: number;
  lowFrequencyEnergyRatio: number;
  spectralRolloff: number;
  zeroCrossingRate: number;
}

// Add these constants at the top
const DEVICE_THRESHOLDS = {
  'open mic': 0.15,  // Higher threshold for open mic
  'desktop + microphone': 0.05,
  'desktop + webcam': 0.05,
  'mobile': 0.05,
  'mobile + headphone': 0.05
} as const;

@Injectable({
  providedIn: 'root'
})
export class AudioDetectionService implements OnInit {
  private firestore: Firestore = inject(Firestore);
  private soundProfileService = inject(SoundProfileService);
  private deviceSettingsService = inject(DeviceSettingsService);

  private audioContext: AudioContext | null = null;
  public analyser: AnalyserNode | null = null;
  private mediaStreamSource: MediaStreamAudioSourceNode | null = null;
  public dataArray: Uint8Array | null = null;

  private soundProfiles: { [key: string]: UnifiedSoundProfile[] } = {
    blow: [],
    laugh: [],
    clap: [],
    hello: []
  };

  private isInitialized = false;
  private isListening = false;
  private injector = inject(Injector);
  private isWarmingUp = false;
  private warmUpStartTime: number | null = null;

  private detectionThreshold = 0.7;
  private detectionInterval = 100; // ms
  private sampleRate = SAMPLE_RATE;
  private fftSize = FFT_SIZE;

  private detectedSoundSignal = signal<string | null>(null);
  private validBreathDetectedSignal = signal<boolean>(false);

  private audioDataSubject = new BehaviorSubject<Uint8Array | null>(null);
  public audioData$ = this.audioDataSubject.asObservable();

  private matchedProfileSubject = new BehaviorSubject<UnifiedSoundProfile | null>(null);
  public matchedProfile$ = this.matchedProfileSubject.asObservable();
  
  private blowDetectedSignal = signal<boolean>(false);
  private blowProfiles: UnifiedSoundProfile[] = [];

  private recalibrationTimer: any;

  private currentDeviceType: DeviceType = 'desktop + webcam'; // Default device type

  constructor() {
    this.loadSoundProfiles();
    this.loadBlowProfiles();
  }

  ngOnInit() {
    effect(() => {
      if (this.soundProfileService.profilesLoaded()) {
        this.startListening();
      }
    });
  }

  public reset(): void {
    this.stopListening();
    this.audioContext = null;
    this.analyser = null;
    this.mediaStreamSource = null;
    this.dataArray = null;
    this.isInitialized = false;
    this.isListening = false;
    this.detectedSoundSignal.set(null);
    this.validBreathDetectedSignal.set(false);
    this.blowDetectedSignal.set(false);
    this.isWarmingUp = false;
    this.warmUpStartTime = null;
    this.clearRecalibrationTimer();
  }

  async initAudio(stream: MediaStream): Promise<void> {
    if (this.isInitialized) {
      if (DEBUG_MODE) console.log('Audio already initialized, resetting...');
      this.reset();
    }
    
    try {
      await this.loadSoundProfiles();
      await this.loadBlowProfiles();

      this.audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
      this.analyser = this.audioContext.createAnalyser();
      this.analyser.fftSize = this.fftSize;
      this.mediaStreamSource = this.audioContext.createMediaStreamSource(stream);
      this.mediaStreamSource.connect(this.analyser);
      this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);
      this.isInitialized = true;
      this.isWarmingUp = true;
      this.warmUpStartTime = Date.now();
      if (DEBUG_MODE) console.log('Audio initialized successfully with profiles loaded');
      this.startRecalibrationTimer();
    } catch (error) {
      console.error('Error initializing audio:', error);
      throw error;
    }
  }

  startListening(): void {
    if (!this.isInitialized) {
      console.error('Audio not initialized. Call initAudio() first.');
      return;
    }

    if (this.isListening) {
      if (DEBUG_MODE) console.log('Already listening');
      return;
    }

    this.isListening = true;
    this.detectSound();
    this.startRecalibrationTimer();
  }

  stopListening(): void {
    this.isListening = false;
    this.clearRecalibrationTimer();
  }

  private startRecalibrationTimer(): void {
    this.clearRecalibrationTimer();
    this.recalibrationTimer = setInterval(() => this.recalibrate(), RECALIBRATION_INTERVAL);
  }

  private clearRecalibrationTimer(): void {
    if (this.recalibrationTimer) {
      clearInterval(this.recalibrationTimer);
      this.recalibrationTimer = null;
    }
  }

  private async recalibrate(): Promise<void> {
    if (DEBUG_MODE) console.log('Recalibrating audio detection...');
    
    // Reload sound profiles
    await this.loadSoundProfiles();
    await this.loadBlowProfiles();
    
    // Reset audio context
    if (this.audioContext) {
      await this.audioContext.close();
    }
    
    // Reinitialize audio
    if (this.mediaStreamSource?.mediaStream) {
      await this.initAudio(this.mediaStreamSource.mediaStream);
    } else {
      console.error('No media stream available for recalibration');
    }
    
    // Restart listening
    this.startListening();
    
    if (DEBUG_MODE) console.log('Recalibration complete');
  }

  private async detectSound(): Promise<void> {
    if (!this.isListening || !this.analyser || !this.dataArray) return;

    this.analyser.getByteFrequencyData(this.dataArray);
    this.audioDataSubject.next(this.dataArray);

    const features = this.extractFeatures(this.dataArray);
    if (DEBUG_MODE) console.log('Extracted features:', features);

    const matchedProfile = await this.compareAudioWithProfiles(this.dataArray);
    if (DEBUG_MODE) console.log('Matched profile:', matchedProfile);

    this.matchedProfileSubject.next(matchedProfile);

    if (matchedProfile && matchedProfile.type === 'blow') {
      this.detectedSoundSignal.set('blow');
      this.validBreathDetectedSignal.set(true);
    } else {
      this.detectedSoundSignal.set(matchedProfile ? matchedProfile.type : null);
      this.validBreathDetectedSignal.set(false);
    }

    setTimeout(() => this.detectSound(), this.detectionInterval);
  }

  private async compareAudioWithProfiles(audioData: Uint8Array): Promise<UnifiedSoundProfile | null> {
    const features = this.extractFeatures(audioData);
    if (DEBUG_MODE) console.log('Extracted features:', features);

    // Check if the features match any blow profile
    for (const profile of this.blowProfiles) {
        // Await the async method
        const isBlowMatch = await this.isAudioMatchingBlowProfile(features, profile);
        
        if (isBlowMatch) {
            // Before confirming it's a blow, check against other profiles
            const nonBlowMatch = await this.checkAgainstNonBlowProfiles(features);
            
            if (nonBlowMatch) {
                if (DEBUG_MODE) console.log('Matched non-blow profile:', nonBlowMatch);
                this.blowDetectedSignal.set(false);
                return nonBlowMatch;
            }
            
            if (DEBUG_MODE) console.log('Blow profile matched:', profile, 'Profile ID:', profile.id);
            this.blowDetectedSignal.set(true);
            return profile;
        }
    }

    if (DEBUG_MODE) console.log('No matching blow profile found');
    this.blowDetectedSignal.set(false);

    // Check other profiles
    return this.checkAgainstNonBlowProfiles(features);
  }

  private async checkAgainstNonBlowProfiles(features: AudioFeatures): Promise<UnifiedSoundProfile | null> {
    for (const profileType in this.soundProfiles) {
        if (profileType !== 'blow') {
            for (const profile of this.soundProfiles[profileType]) {
                // Use a method that returns a boolean
                const isMatch = this.isAudioMatchingProfile(features, profile);
                
                if (isMatch) {
                    return profile;
                }
            }
        }
    }
    return null;
  }

  private async findMatchingBlowProfile(features: AudioFeatures): Promise<UnifiedSoundProfile | null> {
    for (const profile of this.blowProfiles) {
        // Await the async method
        const isMatch = await this.isAudioMatchingBlowProfile(features, profile);
        
        if (isMatch) {
            return profile;
        }
    }
    return null;
  }

  private async isAudioMatchingBlowProfile(features: AudioFeatures, profile: UnifiedSoundProfile): Promise<boolean> {
    // Enhanced debug logging
    if (DEBUG_MODE) {
        console.group('Audio Matching Blow Profile');
        console.log('Current Device Type:', this.currentDeviceType);
        console.log('Detection Threshold:', this.detectionThreshold);
        console.log('Features:', features);
        console.log('Profile:', profile);
    }

    // Early return if open mic with zero sensitivity
    if (this.currentDeviceType === 'open mic' && this.detectionThreshold === 0) {
        if (DEBUG_MODE) console.log('Open mic with zero sensitivity - rejecting all input');
        console.groupEnd();
        return false;
    }

    // Device-specific thresholds from settings
    const deviceSettings = await this.deviceSettingsService.getDeviceSettings(this.currentDeviceType);
    
    if (DEBUG_MODE) {
        console.log('Device Settings:', deviceSettings);
    }

    // Use optional chaining and nullish coalescing for safe access
    const frequencyTolerancePercent = deviceSettings?.frequencyTolerance ?? 300;
    const consistencyTolerance = deviceSettings?.consistencyTolerance ?? 1;
    const lowFrequencyEnergyRatioTolerance = deviceSettings?.lowFrequencyEnergyRatio ?? 0.9;
    const amplitudeThreshold = deviceSettings?.amplitudeThreshold ?? 0.05;
    const detectionThreshold = deviceSettings?.detectionThreshold ?? this.detectionThreshold;

    const frequencyDiffPercent = Math.abs(features.peakFrequency - profile.peakFrequency) / profile.peakFrequency * 100;
    const frequencyMatch = frequencyDiffPercent <= frequencyTolerancePercent;
    
    const consistencyMatch = Math.abs(features.consistencyScore - profile.consistencyScore) <= consistencyTolerance;
    
    const lowFrequencyEnergyRatioMatch = Math.abs(features.lowFrequencyEnergyRatio - profile.lowFrequencyEnergyRatio) 
        <= lowFrequencyEnergyRatioTolerance;
    
    const amplitudeMatch = features.averageAmplitude >= profile.threshold * amplitudeThreshold;

    const result = frequencyMatch && consistencyMatch && lowFrequencyEnergyRatioMatch && amplitudeMatch;

    if (DEBUG_MODE) {
        console.log('Frequency Match:', frequencyMatch);
        console.log('Consistency Match:', consistencyMatch);
        console.log('Low Frequency Energy Ratio Match:', lowFrequencyEnergyRatioMatch);
        console.log('Amplitude Match:', amplitudeMatch);
        console.log('Final Result:', result);
        console.groupEnd();
    }

    return result;
  }

  private extractFeatures(audioData: Uint8Array): AudioFeatures {
    const fftSize = this.fftSize;
    const sampleRate = this.sampleRate;

    // Use Uint8Array methods directly
    const peakIndex = this.findPeakIndex(audioData);
    const peakFrequency = (peakIndex * sampleRate) / (2 * fftSize);

    // Calculate consistency score
    const mean = this.calculateMean(audioData);
    const variance = this.calculateVariance(audioData, mean);
    const consistencyScore = Math.min(1, Math.max(0, 1 - (Math.sqrt(variance) / (mean + 1))));

    // Low frequency energy ratio
    const lowFrequencyBins = Math.floor((LOW_FREQUENCY_THRESHOLD / this.sampleRate) * this.fftSize);
    const lowFrequencyEnergy = this.calculateLowFrequencyEnergy(audioData, lowFrequencyBins);
    const totalEnergy = this.calculateTotalEnergy(audioData);
    const lowFrequencyEnergyRatio = lowFrequencyEnergy / totalEnergy;

    // Additional features
    const spectralRolloff = this.calculateSpectralRolloff(audioData, sampleRate);
    const zeroCrossingRate = this.calculateZeroCrossingRate(audioData);

    return {
        peakFrequency,
        consistencyScore,
        averageAmplitude: mean / 255, // Normalize to 0-1 range
        lowFrequencyEnergyRatio,
        spectralRolloff,
        zeroCrossingRate,
    };
  }

  // Helper methods to work directly with Uint8Array
  private findPeakIndex(audioData: Uint8Array): number {
    let maxValue = 0;
    let peakIndex = 0;
    for (let i = 0; i < audioData.length; i++) {
        if (audioData[i] > maxValue) {
            maxValue = audioData[i];
            peakIndex = i;
        }
    }
    return peakIndex;
  }

  private calculateMean(audioData: Uint8Array): number {
    let sum = 0;
    for (let i = 0; i < audioData.length; i++) {
        sum += audioData[i];
    }
    return sum / audioData.length;
  }

  private calculateVariance(audioData: Uint8Array, mean: number): number {
    let sumSquaredDiff = 0;
    for (let i = 0; i < audioData.length; i++) {
        const diff = audioData[i] - mean;
        sumSquaredDiff += diff * diff;
    }
    return sumSquaredDiff / audioData.length;
  }

  private calculateLowFrequencyEnergy(audioData: Uint8Array, lowFrequencyBins: number): number {
    let energy = 0;
    for (let i = 0; i < lowFrequencyBins; i++) {
        energy += audioData[i] * audioData[i];
    }
    return energy;
  }

  private calculateTotalEnergy(audioData: Uint8Array): number {
    let energy = 0;
    for (let i = 0; i < audioData.length; i++) {
        energy += audioData[i] * audioData[i];
    }
    return energy;
  }

  private calculateSpectralRolloff(fftResult: Uint8Array, sampleRate: number): number {
    const totalEnergy = fftResult.reduce((sum, value) => sum + value, 0);
    let cumulativeEnergy = 0;
    const rolloffThreshold = 0.85 * totalEnergy;

    for (let i = 0; i < fftResult.length; i++) {
      cumulativeEnergy += fftResult[i];
      if (cumulativeEnergy >= rolloffThreshold) {
        return (i / fftResult.length) * (sampleRate / 2);
      }
    }
    return sampleRate / 2;
  }

  private calculateZeroCrossingRate(audioData: Uint8Array): number {
    let crossings = 0;
    for (let i = 1; i < audioData.length; i++) {
      if ((audioData[i] > 128 && audioData[i - 1] <= 128) || 
          (audioData[i] <= 128 && audioData[i - 1] > 128)) {
        crossings++;
      }
    }
    return crossings / audioData.length;
  }

  private isAudioMatchingProfile(features: AudioFeatures, profile: UnifiedSoundProfile): boolean {
    return (
      Math.abs(features.peakFrequency - profile.peakFrequency) <= FREQUENCY_TOLERANCE &&
      Math.abs(features.consistencyScore - profile.consistencyScore) <= CONSISTENCY_TOLERANCE &&
      Math.abs(features.lowFrequencyEnergyRatio - profile.lowFrequencyEnergyRatio) <= LOW_FREQUENCY_ENERGY_RATIO_TOLERANCE &&
      Math.abs(features.averageAmplitude - profile.maxLevel) <= AMPLITUDE_TOLERANCE &&
      features.averageAmplitude >= profile.threshold * THRESHOLD_REDUCTION_FACTOR
    );
  }

  analyzeAudio(audioData: Uint8Array): { detectedSound: string | null, features: AudioFeatures } {
    const features = this.extractFeatures(audioData);
    const detectedSound = this.classifySound(features);

    this.detectedSoundSignal.set(detectedSound);
    this.validBreathDetectedSignal.set(detectedSound === 'blow');

    return { detectedSound, features };
  }

  private classifySound(features: AudioFeatures): string | null {
    // Check if the features match any blow profile
    const matchedBlowProfile = this.findMatchingBlowProfile(features);
    if (matchedBlowProfile) {
      if (DEBUG_MODE) console.log('Blow detected:', matchedBlowProfile);
      this.blowDetectedSignal.set(true);
      return 'blow';
    } else {
      this.blowDetectedSignal.set(false);
    }

    // Check other profile types if it's not a blow
    for (const profileType in this.soundProfiles) {
      if (profileType !== 'blow') {
        for (const profile of this.soundProfiles[profileType]) {
          if (this.isAudioMatchingProfile(features, profile)) {
            return profileType;
          }
        }
      }
    }

    return null;
  }

  getDetectedSound(): Observable<string | null> {
    return new Observable<string | null>(observer => {
      const effectRef = effect(() => {
        observer.next(this.detectedSoundSignal());
      });

      return () => {
        effectRef.destroy();
      };
    });
  }

  validBreathDetected(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      runInInjectionContext(this.injector, () => {
        const obs = toObservable(this.validBreathDetectedSignal);
        const subscription = obs.subscribe(observer);
        return () => subscription.unsubscribe();
      });
    });
  }

  getBlowDetectedSignal() {
    return this.blowDetectedSignal;
  }

  private async loadSoundProfiles(): Promise<void> {
    const profilesCollection = collection(this.firestore, 'config/soundSettings/soundProfiles');
    const profiles$ = collectionData(profilesCollection) as Observable<UnifiedSoundProfile[]>;

    profiles$.pipe(
      map(profiles => {
        const categorizedProfiles: { [key: string]: UnifiedSoundProfile[] } = {
          blow: [],
          laugh: [],
          clap: [],
          hello: []
        };
        profiles.forEach(profile => {
          if (profile.type in categorizedProfiles) {
            categorizedProfiles[profile.type].push(profile);
          }
        });
        return categorizedProfiles;
      })
    ).subscribe(
      categorizedProfiles => {
        this.soundProfiles = categorizedProfiles;
        if (DEBUG_MODE) console.log('Sound profiles loaded:', this.soundProfiles);
      },
      error => console.error('Error loading sound profiles:', error)
    );
  }

  private async loadBlowProfiles(): Promise<void> {
    if (DEBUG_MODE) console.log('Loading blow profiles for device type:', this.currentDeviceType);
    this.blowProfiles = await this.soundProfileService.getProfilesByTypeAndDevice('blow', this.currentDeviceType);
    
    if (this.blowProfiles.length === 0) {
      if (DEBUG_MODE) console.log('No device-specific profiles found, loading all blow profiles');
      this.blowProfiles = await this.soundProfileService.getProfilesByType('blow');
    }
    
    if (DEBUG_MODE) console.log('Loaded blow profiles:', this.blowProfiles);
  }

  async reloadSoundProfiles(): Promise<void> {
    await this.loadSoundProfiles();
    await this.loadBlowProfiles();
    this.soundProfileService.logLoadedProfiles();
  }

  getSampleRate(): number {
    return this.sampleRate;
  }

  setDetectionThreshold(threshold: number): void {
    this.detectionThreshold = threshold;
  }

  setDetectionInterval(interval: number): void {
    this.detectionInterval = interval;
  }

  setSampleRate(rate: number): void {
    this.sampleRate = rate;
  }

  setFFTSize(size: number): void {
    this.fftSize = size;
    if (this.analyser) {
      this.analyser.fftSize = size;
      this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);
    }
  }

  async loadBlowProfilesForDeviceType(deviceType: DeviceType): Promise<void> {
    this.currentDeviceType = deviceType;
    await this.loadBlowProfiles();
    
    if (this.blowProfiles.length === 0) {
      console.log(`No blow profiles found for device type: ${deviceType}. Using all profiles.`);
      await this.loadAllBlowProfiles();
    } else {
      console.log(`Switched to device type: ${deviceType}`);
    }
    
    console.log('Loaded blow profiles:', this.blowProfiles);
  }

  private async loadAllBlowProfiles(): Promise<void> {
    this.blowProfiles = await this.soundProfileService.getProfilesByType('blow');
  }

  async updateDeviceType(deviceType: DeviceType): Promise<void> {
    if (DEBUG_MODE) console.log('Updating device type to:', deviceType);
    
    this.currentDeviceType = deviceType;
    
    // Use device settings service to get threshold
    const deviceSettings = await this.deviceSettingsService.getDeviceSettings(deviceType);
    this.detectionThreshold = deviceSettings.detectionThreshold ?? DEVICE_THRESHOLDS[deviceType];
    
    if (DEBUG_MODE) {
      console.log(`Updated detection threshold to ${this.detectionThreshold} for ${deviceType}`);
      console.log('Device Settings:', deviceSettings);
    }
    
    await this.loadBlowProfiles();
    
    // Only use device-specific profiles
    this.blowProfiles = await this.soundProfileService.getProfilesByTypeAndDevice('blow', deviceType);
    
    if (this.blowProfiles.length === 0) {
      console.warn(`No blow profiles found for device type: ${deviceType}. Blow detection may not work correctly.`);
    }
    
    // Reinitialize audio if already running
    if (this.mediaStreamSource?.mediaStream) {
      await this.initAudio(this.mediaStreamSource.mediaStream);
    }
  }

  private isBlowProfile(features: AudioFeatures, profile: UnifiedSoundProfile): boolean {
    // Make amplitude matching more strict for open mic
    const amplitudeThreshold = this.currentDeviceType === 'open mic' ? 0.05 : 0.02;
    const amplitudeMatch = features.averageAmplitude > amplitudeThreshold;

    // Add minimum consistency requirement - stricter for open mic
    const minConsistency = this.currentDeviceType === 'open mic' ? 0.4 : 0.3;
    const consistencyMatch = features.consistencyScore > minConsistency;

    // Make frequency matching more strict for open mic
    const maxFrequencyDiff = this.currentDeviceType === 'open mic' ? 20 : 30;
    const frequencyMatch = Math.abs(features.peakFrequency - profile.dominantFrequency) < maxFrequencyDiff;

    // Add low frequency ratio check - stricter for open mic
    const lowFreqRatioTolerance = this.currentDeviceType === 'open mic' ? 0.15 : 0.2;
    const lowFreqRatioMatch = Math.abs(features.lowFrequencyEnergyRatio - profile.lowFrequencyEnergyRatio) < lowFreqRatioTolerance;

    return amplitudeMatch && 
           consistencyMatch && 
           frequencyMatch && 
           lowFreqRatioMatch;
  }
}