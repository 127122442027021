import { Component, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-not-found',
  standalone: true,
  imports: [CommonModule, RouterLink],
  template: `
    <div class="flex flex-col items-center justify-center min-h-screen bg-gray-100">
        <img src="assets/images/logo.svg" alt="Logo" class="inline-block h-36 mr-2">            
        <h1 class="text-4xl font-bold mb-4">Page not found</h1>
        <p class="mb-4">Uh oh, we can't seem to find the page you're looking for.</p>
        <a routerLink="/" class="btn btn-outline btn-secondary">Go back to homepage</a>
    </div>
  `,
})
export class NotFoundComponent implements OnInit {
  currentUrl: string;

  constructor(private router: Router) {
    this.currentUrl = this.router.url;
  }

  ngOnInit(): void { }
}
