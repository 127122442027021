<footer class="footer p-10">  
  <nav>
    <h6 class="footer-title">The Research</h6> 
    <a class="link link-hover">About Us</a>
    <a class="link link-hover">Contact Us</a>
    
  </nav> 
  <nav>
    <h6 class="footer-title">The Process</h6> 
    <a class="link link-hover" href="#step1">Step 1: Record Video</a>
    <a class="link link-hover" href="#step2">Step 2: Annotate and upload</a>
    <a class="link link-hover" href="#step3">Step 3: Receive feedback and consultation</a>    
  </nav>
  <nav>
    <h6 class="footer-title">Legal</h6> 
    <a [routerLink]="['/legal']" [fragment]="'privacy-policy'" class="link link-hover">Privacy Policy</a>
    <a [routerLink]="['/legal']" [fragment]="'terms-of-use'" class="link link-hover">Terms of Use</a>
    <a [routerLink]="['/legal']" [fragment]="'moderation'" class="link link-hover">Why we moderate access</a>
    <a [routerLink]="['/legal']" [fragment]="'cookie-policy'" class="link link-hover">Cookie Policy</a>
  </nav>
  <div class="w-full text-center">
    <p>In partnership with Hunter New England Health and Vast Puddle.</p>
  </div>

</footer>

<footer class="footer footer-center p-4 bg-base-300 -mb-12 text-base-content rounded-tl-lg rounded-tr-lg">
  <div class="container mx-auto flex flex-col sm:flex-row items-center justify-between">
    <div class="order-2 sm:order-1 sm:w-1/3"></div>
    <div class="order-1 sm:order-2 sm:w-1/3 text-center mb-2 sm:mb-0">
      <p class="text-base-content/50 text-sm">Copyright © {{ currentYear }} - All rights reserved by Trigger absence</p>
    </div>
    <div class="order-3 sm:w-1/3 flex justify-end mt-2 sm:mt-0">
      <div class="badge badge-ghost text-xs opacity-50">v{{ version }}</div>
    </div>
  </div>
</footer>