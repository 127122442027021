import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';

interface BubbleData {
  left: string;
  size: string;
  delay: string;
  duration: string;
}

@Component({
  selector: 'app-bubble-overlay',
  templateUrl: './bubble-overlay.component.html',
  styleUrls: ['./bubble-overlay.component.css'],
  standalone: true,
  imports: [CommonModule]
})
export class BubbleOverlayComponent implements OnChanges {
  @Input() isAnimating: boolean = false;
  @Input() audioLevel: number = 0;

  bubbles: BubbleData[] = [];
  private readonly MAX_BUBBLES = 20;
  private readonly MIN_DURATION = 1.5;
  private readonly MAX_DURATION = 4;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['audioLevel']) {
      this.updateBubbles();
    }
  }

  private updateBubbles() {
    const targetCount = Math.min(Math.floor(this.audioLevel * 20) + 5, this.MAX_BUBBLES);

    while (this.bubbles.length < targetCount) {
      this.bubbles.push(this.createBubbleData());
    }
    while (this.bubbles.length > targetCount) {
      this.bubbles.pop();
    }

    this.bubbles.forEach(bubble => {
      bubble.duration = `${this.MIN_DURATION + (1 - this.audioLevel) * (this.MAX_DURATION - this.MIN_DURATION)}s`;
    });
  }

  private createBubbleData(): BubbleData {
    return {
      left: `${Math.random() * 100}%`,
      size: `${Math.random() * 1 + 1}rem`,
      delay: `-${Math.random() * this.MAX_DURATION}s`,
      duration: `${this.MIN_DURATION + Math.random() * (this.MAX_DURATION - this.MIN_DURATION)}s`
    };
  }
}