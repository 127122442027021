import { Pipe, PipeTransform } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';

interface TimestampLike {
  seconds: number;
  nanoseconds: number;
}

@Pipe({
  name: 'timestamp',
  standalone: true
})
export class TimestampPipe implements PipeTransform {
  transform(value: Date | Timestamp | TimestampLike | null | undefined): Date | null {
    if (value instanceof Timestamp) {
      return value.toDate();
    }
    if (value instanceof Date) {
      return value;
    }
    if (this.isTimestampLike(value)) {
      return new Date(value.seconds * 1000 + value.nanoseconds / 1000000);
    }
    return null;
  }

  private isTimestampLike(value: any): value is TimestampLike {
    return (
      typeof value === 'object' &&
      value !== null &&
      'seconds' in value &&
      'nanoseconds' in value &&
      typeof value.seconds === 'number' &&
      typeof value.nanoseconds === 'number'
    );
  }
}