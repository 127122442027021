import { Injectable, inject } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile, toBlobURL } from '@ffmpeg/util';
import { HttpClient } from '@angular/common/http';

declare var FFmpegWASM: any;

@Injectable({
  providedIn: 'root'
})
export class VideoTrimService {
  private messageSubject = new Subject<any>();
  private ffmpegRef: FFmpeg | null = null;
  private loadedSubject = new BehaviorSubject<boolean>(false);
  private progressSubject = new BehaviorSubject<number>(0);
  private transcodeLogSubject = new BehaviorSubject<string | null>(null);

  private http = inject(HttpClient);

  protected baseURLCore = 'https://unpkg.com/@ffmpeg/core@0.12.3/dist/umd';
  protected baseURLFFMPEG = 'https://unpkg.com/@ffmpeg/ffmpeg@0.12.6/dist/umd';

  constructor() {}

  getMessages(): Observable<any> {
    return this.messageSubject.asObservable();
  }

  getLoaded(): Observable<boolean> {
    return this.loadedSubject.asObservable();
  }

  getProgress(): Observable<number> {
    return this.progressSubject.asObservable();
  }

  getTranscodeLog(): Observable<string | null> {
    return this.transcodeLogSubject.asObservable();
  }

  async load() {
    try {
      const ffmpegBlobURL = await this.toBlobURLPatched(`${this.baseURLFFMPEG}/ffmpeg.js`, 'text/javascript', (js: any) => {
        return js.replace('new URL(e.p+e.u(814),e.b)', 'r.worker814URL');
      });

      const config = {
        worker814URL: await toBlobURL(`${this.baseURLFFMPEG}/814.ffmpeg.js`, 'text/javascript', true),
        coreURL: await toBlobURL(`${this.baseURLCore}/ffmpeg-core.js`, 'text/javascript', true),
        wasmURL: await toBlobURL(`${this.baseURLCore}/ffmpeg-core.wasm`, 'application/wasm', true),
      };

      await import(/* @vite-ignore */ffmpegBlobURL);
      this.ffmpegRef = new FFmpegWASM.FFmpeg();
      this.ffmpegRef?.on('log', ({ message }) => {
        console.log(message);
        this.transcodeLogSubject.next(message);
      });
      this.ffmpegRef?.on('progress', ({ progress }) => {
        this.progressSubject.next(Math.ceil(progress * 100));
      });
      await this.ffmpegRef?.load(config);
      console.log('ffmpeg.load success');
      this.loadedSubject.next(true);
    } catch (error) {
      console.error('Error loading FFmpeg:', error);
      this.loadedSubject.next(false);
    }
  }

  async trimVideo(videoBlob: Blob, startTime: number, endTime: number): Promise<{ blob: Blob, fileName: string }> {
    if (!this.ffmpegRef) {
      throw new Error('FFmpeg is not loaded');
    }

    const inputFileName = 'input.mp4';
    const timestamp = new Date().getTime();
    const outputFileName = `trimmed_video_${timestamp}.mp4`;

    await this.ffmpegRef.writeFile(inputFileName, await fetchFile(videoBlob));

    await this.ffmpegRef.exec([
      '-ss', startTime.toString(),
      '-i', inputFileName,
      '-to', (endTime - startTime).toString(),
      '-c:v', 'libx264',
      '-preset', 'ultrafast',
      '-c:a', 'aac',
      '-strict', 'experimental',
      outputFileName
    ]);

    const data = await this.ffmpegRef.readFile(outputFileName);

    let blob: Blob;

    if (typeof data === 'string') {
      blob = new Blob([data], { type: 'video/mp4' });
    } else if (data instanceof Uint8Array) {
      blob = new Blob([data.buffer], { type: 'video/mp4' });
    } else {
      throw new Error('Unexpected data type received from FFmpeg');
    }

    console.log(`Trimmed video created: ${outputFileName}, Size: ${blob.size} bytes`);

    return { blob, fileName: outputFileName };
  }

  private toBlobURLPatched(url: string, mimeType: string, patcher: (js: string) => string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.http.get(url, { responseType: 'text' }).subscribe({
        next: (js) => {
          const patchedJS = patcher(js);
          const blob = new Blob([patchedJS], { type: mimeType });
          resolve(URL.createObjectURL(blob));
        },
        error: reject
      });
    });
  }

  loadFile(url: string): Observable<any> {
    return this.http.get(url, {
      responseType: 'arraybuffer',
      reportProgress: true,
      observe: 'events'
    });
  }
}