import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, inject, signal, effect, runInInjectionContext, Injector } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SoundProfileService } from '@services/sound-profile.service';
import { AudioDetectionService } from '@services/audio-detection.service';
import { UnifiedSoundProfile } from '@models/sound-profile.model';
import { Subscription, interval } from 'rxjs';
import { FilterProfilesByTypePipe } from '@pipes/filter-profiles-by-type.pipe';

@Component({
  selector: 'app-diagnostics',
  templateUrl: './diagnostics.component.html',
  standalone: true,
  imports: [CommonModule, FilterProfilesByTypePipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiagnosticsComponent implements OnInit, OnDestroy {
  private readonly debugConfig = {
    DEBUG_MODE: false // Set this to true to enable debug mode
  };

  private soundProfileService = inject(SoundProfileService);
  public audioDetectionService = inject(AudioDetectionService);
  private injector = inject(Injector);

  soundProfiles = signal<UnifiedSoundProfile[]>([]);
  matchResults = signal<{ profile: UnifiedSoundProfile; similarity: number }[]>([]);
  soundDetected = signal<boolean>(false);

  allProfiles = signal<UnifiedSoundProfile[]>([]);
  blowProfiles = signal<UnifiedSoundProfile[]>([]);
  otherProfiles = signal<UnifiedSoundProfile[]>([]);

  allProfilesWithSimilarity = signal<{ profile: UnifiedSoundProfile; similarity: number }[]>([]);
  blowProfilesWithSimilarity = signal<{ profile: UnifiedSoundProfile; similarity: number }[]>([]);
  otherProfilesWithSimilarity = signal<{ profile: UnifiedSoundProfile; similarity: number }[]>([]);

  private pollingSubscription: Subscription | undefined;

  private lastLogTime = 0;

  ngOnInit(): void {
    runInInjectionContext(this.injector, () => {
      this.subscribeTosoundDetection();
    });
    this.loadAllProfiles();
    this.startPolling();
    this.audioDetectionService.startListening();
  }

  ngOnDestroy(): void {
    this.pollingSubscription?.unsubscribe();
    this.audioDetectionService.stopListening();
  }

  private subscribeTosoundDetection() {
    effect(() => {
      const isDetected = this.audioDetectionService.getDetectedSound();
      this.soundDetected.set(isDetected !== null);
      if (this.debugConfig.DEBUG_MODE && Date.now() - this.lastLogTime > 1000) {
        console.log('DiagnosticsComponent: Sound detected:', isDetected);
        this.lastLogTime = Date.now();
      }
    }, { allowSignalWrites: true });
  }

  private async loadAllProfiles() {
    try {
      const profiles = await this.soundProfileService.getAllProfiles();
      this.allProfiles.set(profiles);
      this.blowProfiles.set(profiles.filter(p => p.type === 'blow'));
      this.otherProfiles.set(profiles.filter(p => p.type !== 'blow'));

      if (this.debugConfig.DEBUG_MODE) {
        console.log('Loaded all profiles:', profiles);
      }
    } catch (error) {
      console.error('Error loading profiles:', error);
    }
  }

  private startPolling() {
    this.pollingSubscription = interval(500).subscribe(() => {
      this.updateAllResults();
    });
  }

  private updateAllResults() {
    if (!this.audioDetectionService.analyser || !this.audioDetectionService.dataArray) {
      if (this.debugConfig.DEBUG_MODE) {
        console.warn('updateAllResults: Analyser or dataArray not initialized');
      }
      return;
    }

    this.audioDetectionService.analyser.getByteFrequencyData(this.audioDetectionService.dataArray);
    const currentFFT = Array.from(this.audioDetectionService.dataArray);

    if (currentFFT.length === 0) {
      if (this.debugConfig.DEBUG_MODE) {
        console.warn('updateAllResults: Current FFT is empty');
      }
      return;
    }

    const allResults = this.allProfiles().map(profile => ({
      profile,
      similarity: this.calculateSimilarity(currentFFT, profile.fftMagnitudes || [])
    }));

    this.allProfilesWithSimilarity.set(allResults);
    this.blowProfilesWithSimilarity.set(allResults.filter(r => r.profile.type === 'blow'));
    this.otherProfilesWithSimilarity.set(allResults.filter(r => r.profile.type !== 'blow'));

    this.updateMatchResults(allResults);

    if (this.debugConfig.DEBUG_MODE) {
      console.log('Updated all results:', allResults);
    }
  }

  private updateMatchResults(results: { profile: UnifiedSoundProfile; similarity: number }[]) {
    this.matchResults.set(results.filter(result => result.similarity > 0));
    if (this.debugConfig.DEBUG_MODE) {
      console.log('Updated match results:', this.matchResults());
    }
  }

  private calculateSimilarity(currentFFT: number[], profileFFT: number[]): number {
    if (!currentFFT || !profileFFT || currentFFT.length === 0 || profileFFT.length === 0) {
      return 0;
    }

    const minLength = Math.min(currentFFT.length, profileFFT.length);
    const normalizedCurrentFFT = currentFFT.slice(0, minLength);
    const normalizedProfileFFT = profileFFT.slice(0, minLength);

    const dotProduct = normalizedCurrentFFT.reduce((sum, value, index) => sum + value * normalizedProfileFFT[index], 0);
    const magnitudeCurrent = Math.sqrt(normalizedCurrentFFT.reduce((sum, value) => sum + value * value, 0));
    const magnitudeProfile = Math.sqrt(normalizedProfileFFT.reduce((sum, value) => sum + value * value, 0));

    if (magnitudeCurrent === 0 || magnitudeProfile === 0) {
      return 0;
    }

    return dotProduct / (magnitudeCurrent * magnitudeProfile);
  }

  isMatch(similarity: number): boolean {
    return similarity >= 0.7; // You might want to adjust this threshold
  }
}