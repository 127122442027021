<section class="mt-0 pt-0 my-4 p-4 sm:my-8 sm:p-8 lg:my-12 lg:p-12 max-w-full lg:max-w-screen-xl mx-auto">
  <div class="flex justify-between items-center my-4 sm:my-6 lg:my-8">
    <h3 class="text-xl sm:text-2xl lg:text-4xl font-semibold text-secondary">Settings</h3>
  </div>

  <!-- Menu Navigation -->
  <ul class="menu menu-vertical lg:menu-horizontal bg-base-200 rounded-box mb-8">
    <li><a (click)="scrollToElement('user-approval')" [class.active]="activeSection === 'user-approval'">User Approval</a></li>
    <li><a (click)="scrollToElement('download-controls')" [class.active]="activeSection === 'download-controls'">Download Controls</a></li>
    <li><a (click)="scrollToElement('input-settings')" [class.active]="activeSection === 'input-settings'">Input Settings</a></li>
    <li><a (click)="scrollToElement('augmentation-effect')" [class.active]="activeSection === 'augmentation-effect'">Augmentation Effect</a></li>
    <li><a (click)="scrollToElement('maintenance-controls')" [class.active]="activeSection === 'maintenance-controls'">Maintenance Controls</a></li>
    <li class="ml-auto">
      <a routerLink="/admin/audiosettings" class="flex items-center">
        Audio Library
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-right ml-2" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M5 12l14 0"></path>
          <path d="M13 18l6 -6"></path>
          <path d="M13 6l6 6"></path>
        </svg>
      </a>
    </li>
  </ul>
  
  <div class="flex flex-col gap-8">
    <div #userApproval id="user-approval" class="card bg-base-100 shadow-xl w-full">
      <div class="card-body">
        <h4 class="card-title text-xl mb-4">User Approval</h4>
        <div class="form-control">
          <label class="label cursor-pointer flex flex-col items-start">
            <span class="label-text mb-2">Auto approve new users</span>
            <input type="checkbox" class="toggle toggle-primary" [checked]="autoApproveUsers()" (change)="toggleAutoApproveUsers()" />
          </label>
          <p class="text-sm text-base-content/70 mt-2">Current state: {{ autoApproveUsers() ? 'ON' : 'OFF' }}</p>
        </div>
      </div>
    </div>

    <div #downloadControls id="download-controls" class="card bg-base-100 shadow-xl w-full">
      <div class="card-body">
        <h4 class="card-title text-xl mb-4">Download Controls</h4>
        <div class="form-control">
          <label class="label cursor-pointer flex flex-col items-start">
            <span class="label-text mb-2">Allow users to download videos</span>
            <input type="checkbox" class="toggle toggle-primary" [checked]="allowVideoDownloads()" (change)="toggleAllowVideoDownloads()" />
          </label>
          <p class="text-sm text-base-content/70 mt-2">Current state: {{ allowVideoDownloads() ? 'ON' : 'OFF' }}</p>
        </div>
      </div>
    </div>

  

    <div #augmentationEffect id="augmentation-effect" class="card bg-base-100 shadow-xl w-full">
      <div class="card-body">
        <h4 class="card-title text-xl mb-4">Augmentation Effect</h4>
        <div class="form-control">
          <label class="label cursor-pointer flex flex-col items-start">
            <span class="label-text mb-2">Bubbles</span>
            <input type="checkbox" class="toggle toggle-primary" [checked]="modalEffects()['bubbles']" (change)="toggleModalEffect('bubbles')" />
          </label>
        </div>
        <div class="form-control mt-2">
          <label class="label cursor-pointer flex flex-col items-start">
            <span class="label-text mb-2">Pinwheel</span>
            <input type="checkbox" class="toggle toggle-primary" [checked]="modalEffects()['pinwheel']" (change)="toggleModalEffect('pinwheel')" />
          </label>
        </div>
        <div class="form-control mt-2">
          <label class="label cursor-pointer flex flex-col items-start">
            <span class="label-text mb-2">Music</span>
            <input type="checkbox" class="toggle toggle-primary" [checked]="modalEffects()['music']" (change)="toggleModalEffect('music')" />
          </label>
        </div>
      </div>
    </div>

    <div #maintenanceControls id="maintenance-controls" class="card bg-error text-error-content w-full">
      <div class="card-body">
        <h4 class="card-title text-xl mb-4 text-error-content">Maintenance Controls</h4>
        <div class="form-control">
          <label class="label cursor-pointer">
            <span class="label-text text-error-content">Activate maintenance mode</span>
            <input type="checkbox" class="toggle toggle-error" [checked]="maintenanceModeActive()" (change)="toggleMaintenanceMode()" />
          </label>
          <p class="text-sm text-error-content/70 mt-2">Current state: {{ maintenanceModeActive() ? 'ON' : 'OFF' }}</p>
        </div>
      </div>
    </div>
  </div>
</section>