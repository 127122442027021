import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-account',
  templateUrl: './admin-account.component.html', // Corrected path
  styleUrls: ['./admin-account.component.css']
})
export class AdminAccountComponent {
  constructor() {
    console.log('AdminAccountComponent loaded');
  }
}
