import { Injectable } from '@angular/core';
import { RecordingTimerService } from './recording-timer.service';
import { ResizeService } from './resize.service';
import { take, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CanvasDrawService {
  private animationFrameId: number | null = null;

  constructor(
    private recordingTimerService: RecordingTimerService,
    private resizeService: ResizeService
  ) {}

  drawToCanvas(
    canvas: HTMLCanvasElement,
    video: HTMLVideoElement,
    drawDimensions: { drawWidth: number; drawHeight: number; xOffset: number; yOffset: number },
    detectionMode: boolean,
    formatTime: (seconds: number) => string,
    cameraVisible: boolean,
    remainingTime: number
  ): void {
    this.resizeService.setupComplete$.pipe(
      filter(complete => complete),
      take(1)
    ).subscribe(() => {
      const ctx = canvas.getContext('2d');
      if (!ctx) return;
  
      // Clear the canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);
  
      if (cameraVisible && video.readyState === video.HAVE_ENOUGH_DATA) {
        // Draw video frame if camera is visible and video is ready
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      } else {
        // Draw black background when camera is off
        ctx.fillStyle = 'black';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
      }
  
      // Always draw the timer if in detection mode
      if (detectionMode) {
        const viewportWidth = window.innerWidth;
        const isLargeViewport = viewportWidth >= 1024;

        // Adjust font size based on viewport
        const baseFontSize = isLargeViewport ? canvas.height * 0.05 : canvas.height * 0.07;
        const fontSize = Math.max(16, Math.min(baseFontSize, 24));

        ctx.font = `bold ${fontSize}px "Segoe UI", sans-serif`;
        const timerText = formatTime(remainingTime);
        
        // Calculate text width to center it horizontally
        const textMetrics = ctx.measureText(timerText);
        const textWidth = textMetrics.width;
        
        const timerX = canvas.width / 2 - textWidth / 2;
        // Adjust vertical position for small viewports
        const timerY = isLargeViewport ? fontSize + 1 : canvas.height * 0.2; // Moved further down for small viewports

        // Draw the timer text with improved visibility
        ctx.shadowColor = 'rgba(0, 0, 0, 0.5)';
        ctx.shadowBlur = 4;
        ctx.fillStyle = 'white';
        ctx.strokeStyle = 'black';
        ctx.lineWidth = 2;
        ctx.strokeText(timerText, timerX, timerY);
        ctx.fillText(timerText, timerX, timerY);

        // Reset shadow
        ctx.shadowColor = 'transparent';
        ctx.shadowBlur = 0;
      }
    });
  }


  startDrawLoop(drawFunction: () => void): void {
    const animate = () => {
      drawFunction();
      this.animationFrameId = requestAnimationFrame(animate);
    };
    animate();
  }

  stopDrawLoop(): void {
    if (this.animationFrameId !== null) {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
    }
  }
}