import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FfmpegWorkerService {
    private worker: Worker | undefined;
    private messageSubject = new Subject<any>();
    private errorSubject = new Subject<any>();

    constructor(private ngZone: NgZone) {
        if (typeof Worker !== 'undefined') {
            this.worker = new Worker(new URL('../workers/ffmpeg-worker.ts', import.meta.url));
            
            // Listen to messages from the worker
            this.worker.onmessage = ({ data }) => {
                this.ngZone.run(() => {
                    this.messageSubject.next(data);
                });
            };

            this.worker.onerror = (error) => {
                this.ngZone.run(() => {
                    this.errorSubject.next(error);
                });
            };
        } else {
            console.warn('Web Workers are not supported in this environment.');
        }
    }

    // Method to send messages to the worker
    sendMessage(message: any) {
        if (this.worker) {
            this.worker.postMessage(message);
        }
    }

    // Observable for worker messages
    get messages$(): Observable<any> {
        return this.messageSubject.asObservable();
    }

    // Observable for worker errors
    get errors$(): Observable<any> {
        return this.errorSubject.asObservable();
    }

    // Terminate the worker when not needed
    terminate() {
        if (this.worker) {
            this.worker.terminate();
            this.worker = undefined;
            this.messageSubject.complete();
            this.errorSubject.complete();
        }
    }
}