import { Injectable, signal } from '@angular/core';
import { Firestore, collection, getDocs, doc, getDoc, updateDoc, setDoc } from '@angular/fire/firestore';
import { DeviceType, DEVICE_TYPES } from '@models/sound-profile.model';
import { DeviceSettings } from '@models/device-settings.model';

export const DEFAULT_DEVICE_SETTINGS: Record<DeviceType, DeviceSettings> = {
  'desktop + microphone': {
    deviceType: 'desktop + microphone',
    lowFrequencyThreshold: 400,
    frequencyTolerance: 300,
    consistencyTolerance: 0,
    lowFrequencyEnergyRatio: 0.75,
    amplitudeThreshold: 0.05,
    detectionThreshold: 0.6
  },
  'desktop + webcam': {
    deviceType: 'desktop + webcam',
    detectionThreshold: 0.6,
    lowFrequencyThreshold: 450,
    lowFrequencyEnergyRatio: 0.31,
    consistencyTolerance: 0.68,
    frequencyTolerance: 300,
    amplitudeThreshold: 0.05
  },
  'mobile': {
    deviceType: 'mobile',
    detectionThreshold: 0.6,
    lowFrequencyThreshold: 400,
    frequencyTolerance: 300,
    amplitudeThreshold: 0.05,
    consistencyTolerance: 0.4,
    lowFrequencyEnergyRatio: 0.4
  },
  'mobile + headphone': {
    deviceType: 'mobile + headphone',
    detectionThreshold: 0.6,
    lowFrequencyThreshold: 400,
    lowFrequencyEnergyRatio: 0.25,
    consistencyTolerance: 0.3,
    frequencyTolerance: 300,
    amplitudeThreshold: 0.05
  },
  'open mic': {
    deviceType: 'open mic',
    detectionThreshold: 0.7,
    lowFrequencyThreshold: 400,
    lowFrequencyEnergyRatio: 0.5,
    consistencyTolerance: 0.5,
    frequencyTolerance: 300,
    amplitudeThreshold: 0.15
  }
};

@Injectable({
  providedIn: 'root'
})
export class DeviceSettingsService {
  private deviceSettings = signal<Record<DeviceType, DeviceSettings>>({ ...DEFAULT_DEVICE_SETTINGS });
  
  // Expose a readonly signal for components to observe
  public deviceSettingsSignal = this.deviceSettings.asReadonly();

  constructor(private firestore: Firestore) {
    this.loadDeviceSettings();
  }

  private async loadDeviceSettings(): Promise<void> {
    try {
      const settingsRef = collection(this.firestore, 'device_settings');
      const settingsSnapshot = await getDocs(settingsRef);
      
      settingsSnapshot.forEach(docSnap => {
        const data = docSnap.data() as DeviceSettings;
        if (DEVICE_TYPES.includes(data.deviceType)) {
          this.deviceSettings.update(current => ({
            ...current,
            [data.deviceType]: { 
              ...DEFAULT_DEVICE_SETTINGS[data.deviceType], 
              ...data 
            }
          }));
        }
      });
    } catch (error) {
      console.error('Error loading device settings:', error);
    }
  }

  async getDeviceSettings(deviceType: DeviceType): Promise<DeviceSettings> {
    // First, check in-memory signal
    const currentSettings = this.deviceSettings()[deviceType];
    if (currentSettings) return currentSettings;

    // Fallback to Firestore and default
    try {
      const settingsDoc = doc(this.firestore, `device_settings/${deviceType}`);
      const docSnap = await getDoc(settingsDoc);
      
      if (docSnap.exists()) {
        const settings = docSnap.data() as DeviceSettings;
        // Update signal with fetched settings
        this.deviceSettings.update(current => ({
          ...current,
          [deviceType]: { 
            ...DEFAULT_DEVICE_SETTINGS[deviceType], 
            ...settings 
          }
        }));
        return settings;
      }
    } catch (error) {
      console.error('Error fetching device settings:', error);
    }
    
    return DEFAULT_DEVICE_SETTINGS[deviceType];
  }

  async saveDeviceSettings(settings: DeviceSettings): Promise<void> {
    try {
      const settingsDoc = doc(this.firestore, `device_settings/${settings.deviceType}`);
      await setDoc(settingsDoc, settings, { merge: true });
      
      // Update signal with new settings
      this.deviceSettings.update(current => ({
        ...current,
        [settings.deviceType]: { 
          ...DEFAULT_DEVICE_SETTINGS[settings.deviceType], 
          ...settings 
        }
      }));
    } catch (error) {
      console.error('Error saving device settings:', error);
      throw error;
    }
  }

  async resetToDefaults(deviceType: DeviceType): Promise<DeviceSettings> {
    const defaultSettings = DEFAULT_DEVICE_SETTINGS[deviceType];
    
    try {
      const settingsDoc = doc(this.firestore, `device_settings/${deviceType}`);
      await setDoc(settingsDoc, defaultSettings);
      
      // Update signal with default settings
      this.deviceSettings.update(current => ({
        ...current,
        [deviceType]: defaultSettings
      }));
      
      return defaultSettings;
    } catch (error) {
      console.error(`Error resetting device settings for ${deviceType}:`, error);
      throw error;
    }
  }

  async getAllDeviceSettings(): Promise<Record<DeviceType, DeviceSettings>> {
    // Ensure all settings are loaded
    await this.loadDeviceSettings();
    return this.deviceSettings();
  }
} 