<section class="mt-0 pt-0 my-4 p-4 sm:my-8 sm:p-8 lg:my-12 lg:p-12 max-w-full lg:max-w-screen-xl mx-auto">
  <div class="flex justify-between items-center my-4 sm:my-6 lg:my-8">
    <h2 class="text-xl sm:text-2xl lg:text-4xl font-semibold text-secondary">Admin Overview</h2>
    <div class="form-control">
      <label class="label cursor-pointer">
        <span class="label-text mr-2">Compare to all time</span>
        <input type="checkbox" class="toggle" [checked]="compareAllTime()" (change)="toggleCompareAllTime()" />
      </label>
    </div>
  </div>

  @if (loading()) {
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-8">
      <div class="skeleton h-24 w-full"></div>
      <div class="skeleton h-24 w-full"></div>
      <div class="skeleton h-24 w-full"></div>
    </div>
    <div class="skeleton h-64 w-full mb-8"></div>
    <div class="skeleton h-64 w-full"></div>
  } @else if (error()) {
    <div class="alert alert-error">
      <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
      <span>{{ error() }}</span>
    </div>
  } @else if (showContent()) {
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      <div class="stats shadow">
        <div class="stat">
          <div class="stat-title text-xs uppercase">CURRENT</div>
          <div class="stat-title">Users</div>
          <div class="stat-value">
            <a [routerLink]="['/admin/users']" class="underline hover:text-primary transition-colors duration-200">{{ stats()!.current.users }}</a>
          </div>
        </div>
        @if (compareAllTime()) {
          <div class="stat bg-info text-info-content">
            <div class="stat-title text-info-content text-xs uppercase">ALL TIME</div>
            <div class="stat-title text-info-content">Users</div>
            <div class="stat-value text-info-content">{{ stats()!.allTime.users }}</div>
          </div>
        }
      </div>

      <div class="stats shadow">
        <div class="stat">
          <div class="stat-title text-xs uppercase">CURRENT</div>
          <div class="stat-title">Videos</div>
          <div class="stat-value">
            <a [routerLink]="['/admin/all-videos']" class="underline hover:text-primary transition-colors duration-200">{{ stats()!.current.videos }}</a>
          </div>
        </div>
        @if (compareAllTime()) {
          <div class="stat bg-info text-info-content">
            <div class="stat-title text-info-content text-xs uppercase">ALL TIME</div>
            <div class="stat-title text-info-content">Videos</div>
            <div class="stat-value text-info-content">{{ stats()!.allTime.videos }}</div>
          </div>
        }
      </div>

      <div class="stats shadow">
        <div class="stat">
          <div class="stat-title text-xs uppercase">CURRENT</div>
          <div class="stat-title">Diagnosed Videos</div>
          <div class="stat-value">{{ stats()!.current.diagnosedVideos }}</div>
        </div>
        @if (compareAllTime()) {
          <div class="stat bg-info text-info-content">
            <div class="stat-title text-info-content text-xs uppercase">ALL TIME</div>
            <div class="stat-title text-info-content">Diagnosed Videos</div>
            <div class="stat-value text-info-content">{{ stats()!.allTime.diagnosedVideos }}</div>
          </div>
        }
      </div>
    </div>

    @if (stats()!.current.videosByPriority) {
      <h3 class="text-2xl font-bold mt-8 mb-4">Videos by Priority</h3>
      <div class="card bg-base-100 shadow-xl">
        <div class="card-body">
          <table class="table table-xs">
            <thead>
              <tr>
                <th>Priority</th>
                <th>
                  <span class="uppercase text-xs">CURRENT</span><br>
                  Count
                </th>
                @if (compareAllTime()) {
                  <th class="bg-info text-info-content">
                    <span class="uppercase text-xs">ALL TIME</span><br>
                    Count
                  </th>
                }
              </tr>
            </thead>
            <tbody>
              @for (priority of [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5]; track priority) {
                <tr>
                  <td>{{ priority }}</td>
                  <td>
                    <a [routerLink]="['/admin/all-videos']" [queryParams]="{priority: priority}" class="underline hover:text-primary transition-colors duration-200">
                      {{ stats()!.current.videosByPriority[priority] || '-' }}
                    </a>
                  </td>
                  @if (compareAllTime()) {
                    <td class="bg-info text-info-content">{{ stats()!.allTime.videosByPriority[priority] || '-' }}</td>
                  }
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    }

    @if (stats()!.current.usersByOccupation && Object.keys(stats()!.current.usersByOccupation).length > 0) {
      <h3 class="text-2xl font-bold mt-8 mb-4">Users by Occupation</h3>
      <div class="card bg-base-100 shadow-xl">
        <div class="card-body">
          <table class="table table-xs">
            <thead>
              <tr>
                <th>Occupation</th>
                <th>
                  <span class="uppercase text-xs">CURRENT</span><br>
                  Count
                </th>
                @if (compareAllTime()) {
                  <th class="bg-info text-info-content">
                    <span class="uppercase text-xs">ALL TIME</span><br>
                    Count
                  </th>
                }
              </tr>
            </thead>
            <tbody>
              @for (occupation of Object.keys(stats()!.current.usersByOccupation); track occupation) {
                <tr>
                  <td>{{ occupation }}</td>
                  <td>
                    <a [routerLink]="['/admin/users']" [queryParams]="{occupation: occupation}" class="underline hover:text-primary transition-colors duration-200">
                      {{ stats()!.current.usersByOccupation[occupation] }}
                    </a>
                  </td>
                  @if (compareAllTime()) {
                    <td class="bg-info text-info-content">{{ stats()!.allTime.usersByOccupation[occupation] || '-' }}</td>
                  }
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    }
  } @else {
    <div class="alert alert-info">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="stroke-current shrink-0 w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
      <span>Preparing content...</span>
    </div>
  }
</section>