import { initializeApp } from 'firebase/app';
import { Firestore, getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { Auth, getAuth, connectAuthEmulator, signInWithCustomToken } from 'firebase/auth';
import { FirebaseStorage, getStorage, connectStorageEmulator } from 'firebase/storage';
import { environment } from '@environments/environment';

const app = initializeApp(environment.firebaseConfig);

const firestore: Firestore = getFirestore(app);
const auth: Auth = getAuth(app);
const storage: FirebaseStorage = getStorage(app);

if (environment.useEmulators) {
  console.log('Connecting to Firebase emulators');
  try {
    connectFirestoreEmulator(firestore, 'localhost', 8080);
    connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
    connectStorageEmulator(storage, 'localhost', 9199);
    
    // If bypassAuth is enabled in development, sign in with default user
    if (environment.bypassAuth) {
      signInWithCustomToken(auth, environment.defaultUserId)
        .catch(error => console.error('Error signing in with default user:', error));
    }
    
    console.log('Firebase emulators connected successfully');
  } catch (error) {
    console.error('Error connecting to emulators:', error);
  }
}

export function getFirestoreInstance(): Firestore {
  return firestore;
}

export function getAuthInstance(): Auth {
  return auth;
}

export function getStorageInstance(): FirebaseStorage {
  return storage;
}

export { app, firestore, auth, storage };