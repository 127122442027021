import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { UserService } from '@services/user.service';
import { User } from '@shared/models/user.model';
import { FormsModule } from '@angular/forms';
import { FirestoreDatePipe } from '@shared/pipes/firestore-date.pipe';
import { EmailService } from '@shared/services/email.service';

@Component({
  selector: 'app-user-details',
  standalone: true,
  imports: [CommonModule, RouterModule, FormsModule, FirestoreDatePipe],
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit {
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private userService = inject(UserService);
  private emailService = inject(EmailService);

  user: User | null = null;
  rejectionReason: string = '';
  showConfirmationModal: boolean = false;
  pendingStatus: 'approved' | 'rejected' | null = null;
  actionType: 'approved' | 'rejected' | 'suspend' | 'delete' | '' = '';

  ngOnInit() {
    const userId = this.route.snapshot.paramMap.get('id');
    if (userId) {
      this.userService.getUserById(userId).subscribe(user => {
        this.user = user;
        console.log('Received user data in component:', user);
      });
    }
  }

  goBack() {
    this.router.navigate(['/admin/users']);
  }

  getApprovalStatusClass(status: string): string {
    switch (status) {
      case 'approved': return 'badge-success';
      case 'rejected': return 'badge-error';
      case 'pending': return 'badge-warning';
      default: return 'badge-info';
    }
  }

  showConfirmation(status: 'approved' | 'rejected') {
    this.pendingStatus = status;
    this.showConfirmationModal = true;
    if (status === 'rejected' && this.user?.approvedStatus === 'approved') {
      this.actionType = 'suspend';
    } else {
      this.actionType = status;
    }
  }

  confirmDeleteUser() {
    this.actionType = 'delete';
    this.showConfirmationModal = true;
  }

  confirmStatusChange() {
    if (this.user && this.pendingStatus) {
      let updateData: Partial<User> = {
        approvedStatus: this.pendingStatus
      };

      if (this.pendingStatus === 'rejected') {
        updateData.approvedStatusReason = this.rejectionReason || null;
      } else if (this.pendingStatus === 'approved') {
        updateData.approvedStatusReason = null;
      }

      this.userService.updateUserApprovalStatus(this.user.id, updateData).then(() => {
        if (this.user) {
          this.user.approvedStatus = this.pendingStatus!;
          if (this.pendingStatus === 'rejected') {
            this.user.approvedStatusReason = this.rejectionReason || null;
            // Send rejection email
            this.emailService.sendRejectionEmail(this.user, this.rejectionReason || undefined);
          } else if (this.pendingStatus === 'approved') {
            this.user.approvedStatusReason = null;
            // Send approval email
            this.emailService.sendApprovalEmail(this.user);
          }
        }
        this.showConfirmationModal = false;
        this.rejectionReason = '';
        this.actionType = '';
      }).catch(error => {
        console.error('Error updating approval status:', error);
        // Handle error (e.g., show error message to user)s
      });
    }
  }

  async deleteUser() {
    if (this.user) {
      try {
        await this.userService.deleteUser(this.user.id);
        this.showConfirmationModal = false;
        this.router.navigate(['/admin/users']);
      } catch (error) {
        console.error('Error deleting user:', error);
        // Handle error (e.g., show error message to user)
      }
    }
  }

  cancelStatusChange() {
    this.showConfirmationModal = false;
    this.rejectionReason = '';
    this.actionType = '';
  }
}