import { Injectable, signal, effect } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Firestore } from '@angular/fire/firestore';
import { doc, onSnapshot } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceModeGuard implements CanActivate, CanActivateChild {
  private maintenanceMode = signal(false);

  constructor(private firestore: Firestore, private router: Router) {
    this.initMaintenanceMode();
  }

  private initMaintenanceMode() {
    onSnapshot(doc(this.firestore, 'config', 'maintenanceMode'), (docSnapshot) => {
      const config = docSnapshot.data();
      const isActive = config?.['active'] || false;
      if (isActive) {
        console.log('Maintenance mode status:', isActive);
      }
      this.maintenanceMode.set(isActive);
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    console.log('canActivate called. Maintenance mode:', this.maintenanceMode(), 'URL:', state.url);
    if (this.maintenanceMode() && state.url !== '/maintenance.html') {
      console.log('MaintenanceModeGuard: Redirecting to maintenance page');
      this.router.navigate(['/maintenance.html']);
      return false;
    }
    return true;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(childRoute, state);
  }
}