import { Component, Input, Output, EventEmitter, ViewChild, OnInit, OnDestroy, inject, Injector, signal, Signal, runInInjectionContext, effect } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BubbleOverlayComponent } from '../bubble-overlay/bubble-overlay.component';
import { MusicOverlayComponent } from '../music-overlay/music-overlay.component';
import { PinwheelOverlayComponent } from '../pinwheel-overlay/pinwheel-overlay.component';
import { DiagnosticsComponent } from '../../diagnostics/diagnostics.component';
import { AudioDetectionService } from '@services/audio-detection.service';
import { Subscription } from 'rxjs';

const DEBUG_MODE = false;

@Component({
  selector: 'app-mode-overlay',
  templateUrl: './mode-overlay.component.html',
  styleUrls: ['./mode-overlay.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    BubbleOverlayComponent,
    MusicOverlayComponent,
    PinwheelOverlayComponent,
    DiagnosticsComponent
  ]
})
export class ModeOverlayComponent implements OnInit, OnDestroy {
  @Input() isAnimating: boolean = false;
  @Input() audioLevel: number = 0;
  @Input() threshold: number = 0.05;
  @Input() acceleration: number = 1;
  @Input() availableEffects: Array<'Bubbles' | 'Pinwheel' | 'Music'> = ['Bubbles', 'Pinwheel', 'Music'];
  @Input() selectedMode: 'Bubbles' | 'Pinwheel' | 'Music' | null = 'Pinwheel';
  @Input() breathDetected: boolean = false;
  @Input() isWebcamActive!: Signal<boolean>;

  @Output() modeSelected = new EventEmitter<'Bubbles' | 'Pinwheel' | 'Music'>();

  @ViewChild(PinwheelOverlayComponent) pinwheelOverlay!: PinwheelOverlayComponent;

  private injector = inject(Injector);
  private audioDetectionService = inject(AudioDetectionService);
  private breathDetectionSubscription: Subscription | null = null;

  blowDetected = signal<boolean>(false);

  constructor() {}

  ngOnInit(): void {
    this.log('ModeOverlayComponent initialized');
    runInInjectionContext(this.injector, () => {
      this.setupBreathDetectionEffect();
      this.setupBlowDetectionEffect();
      
      // Move this effect inside runInInjectionContext
      const blowDetectedSignal = this.audioDetectionService.getBlowDetectedSignal();
      effect(() => {
        this.blowDetected.set(blowDetectedSignal());
      }, { allowSignalWrites: true });
    });
  }

  ngOnDestroy(): void {
    if (this.breathDetectionSubscription) {
      this.breathDetectionSubscription.unsubscribe();
    }
  }

  private setupBreathDetectionEffect(): void {
    this.breathDetectionSubscription = this.audioDetectionService.validBreathDetected().subscribe(isValidBreath => {
      this.log(`Effect triggered: Valid breath detected = ${isValidBreath}`);
      if (isValidBreath) {
        setTimeout(() => this.accelerateAnimation(), 0);
        this.logPositiveBreath();
      } else {
        setTimeout(() => this.decelerateAnimation(), 0);
      }
    });
  }

  private setupBlowDetectionEffect(): void {
    effect(() => {
      const blowDetected = this.audioDetectionService.getBlowDetectedSignal()();
      this.blowDetected.set(blowDetected);
      this.log(`Blow detected: ${blowDetected}`);
    }, { allowSignalWrites: true });  // Add this option
  }

  selectMode(mode: 'Bubbles' | 'Pinwheel' | 'Music') {
    this.log(`Mode selected: ${mode}`);
    this.selectedMode = mode;
    this.modeSelected.emit(mode);
  }

  getEffectEmoji(effect: string): string {
    const emoji = {
      'bubbles': '🫧',
      'pinwheel': '🎡',
      'music': '🎵'
    }[effect.toLowerCase()] || '';
    this.log(`Effect emoji for ${effect}: ${emoji}`);
    return emoji;
  }

  accelerateAnimation(): void {
    if (this.selectedMode === 'Pinwheel' && this.pinwheelOverlay) {
      this.pinwheelOverlay.accelerate();
    }
    // Add similar logic for other modes if needed
  }

  decelerateAnimation(): void {
    if (this.selectedMode === 'Pinwheel' && this.pinwheelOverlay) {
      this.pinwheelOverlay.decelerate();
    }
    // Add similar logic for other modes if needed
  }

  private logPositiveBreath() {
    if (DEBUG_MODE) {
      console.group('Positive Breath Detected');
      console.log('Current mode:', this.selectedMode);
      console.log('Audio level:', this.audioLevel);
      console.log('Threshold:', this.threshold);
      console.log('Acceleration:', this.acceleration);
      console.log('Is animating:', this.isAnimating);      
      console.groupEnd();
    }
  }

  private log(message: string) {
    if (DEBUG_MODE) {
      console.log(`ModeOverlayComponent: ${message}`);
    }
  }
}