<div class="container mx-auto px-4 py-8 max-w-full lg:max-w-screen-xl">
  <h1 class="text-4xl lg:text-5xl font-bold mb-12 text-primary">Legal Information</h1>

  <section id="privacy-policy" class="mb-16">
    <h2 class="text-2xl lg:text-4xl font-semibold mb-8 text-secondary">Privacy Policy</h2>    

    <h3 class="text-xl font-semibold mb-4">1. Introduction</h3>
    <p class="mb-6 text-base">Welcome to Trigger Absence. We are committed to protecting your privacy and handling your personal information in an open and transparent manner. This Privacy Policy explains how we collect, use, disclose, and safeguard your personal information in accordance with the Australian Privacy Principles (APPs) under the Privacy Act 1988 (Cth).</p>
    
    <h3 class="text-xl font-semibold mb-4">2. Who We Are</h3>
    <p class="mb-6 text-base">Trigger Absence is operated in partnership with Hunter New England Health, located at Lookout Road, New Lambton NSW 2305. We can be contacted at admin&#64;triggerabsence.com.</p>

    <h3 class="text-xl font-semibold mb-4">3. Information We Collect</h3>
    <p class="mb-4 text-base">We collect and process the following types of personal information:</p>
    <ul class="list-disc pl-6 mb-6 text-base">
        <li class="mb-2">Patient demographic data (e.g., name, date of birth, contact details)</li>
        <li class="mb-2">Medical history and health information</li>
        <li class="mb-2">Video recordings of patient consultations or treatments</li>
        <li class="mb-2">Any other information you provide to us through the app</li>
    </ul>

    <h3 class="text-xl font-semibold mb-4">4. How We Collect Information</h3>
    <p class="mb-4 text-base">We collect information directly from you when you:</p>
    <ul class="list-disc pl-6 mb-6 text-base">
        <li class="mb-2">Register for an account</li>
        <li class="mb-2">Use our app services</li>
        <li class="mb-2">Participate in video consultations or recordings</li>
        <li class="mb-2">Communicate with us</li>
    </ul>

    <h3 class="text-xl font-semibold mb-4">5. Purpose of Collection</h3>
    <p class="mb-4 text-base">We collect your personal information to:</p>
    <ul class="list-disc pl-6 mb-6 text-base">
        <li class="mb-2">Provide and improve our healthcare services</li>
        <li class="mb-2">Facilitate communication between patients and healthcare providers</li>
        <li class="mb-2">Maintain accurate medical records</li>
        <li class="mb-2">Comply with legal and regulatory requirements</li>
    </ul>

    <h3 class="text-xl font-semibold mb-4">6. Data Storage and Security</h3>
    <p class="mb-6 text-base">All personal information, including video recordings, is stored encrypted at rest. We implement appropriate technical and organisational measures to protect your data from unauthorised access, alteration, disclosure, or destruction.</p>

    <h3 class="text-xl font-semibold mb-4">7. Data Retention and Deletion</h3>
    <p class="mb-6 text-base">We retain your personal information and video recordings for 30 days, after which they will be automatically deleted unless you provide explicit consent for extended storage. You may request earlier deletion of your data at any time.</p>

    <h3 class="text-xl font-semibold mb-4">8. Consent for Extended Storage</h3>
    <p class="mb-6 text-base">If you wish to have your data stored for longer than 30 days, you must provide explicit consent. You can manage your consent preferences within the app settings or by contacting us directly.</p>

    <h3 class="text-xl font-semibold mb-4">9. Sharing of Information</h3>
    <p class="mb-4 text-base">We do not share your personal information with third parties except:</p>
    <ul class="list-disc pl-6 mb-6 text-base">
        <li class="mb-2">With your explicit consent</li>
        <li class="mb-2">To comply with legal obligations</li>
        <li class="mb-2">To protect our rights or the rights of others</li>
    </ul>

    <h3 class="text-xl font-semibold mb-4">10. Your Rights</h3>
    <p class="mb-4 text-base">Under Australian privacy laws, you have the right to:</p>
    <ul class="list-disc pl-6 mb-6 text-base">
        <li class="mb-2">Access your personal information</li>
        <li class="mb-2">Correct inaccurate or incomplete information</li>
        <li class="mb-2">Request deletion of your data</li>
        <li class="mb-2">Withdraw consent for data processing</li>
        <li class="mb-2">Lodge a complaint with the Office of the Australian Information Commissioner</li>
    </ul>

    <h3 class="text-xl font-semibold mb-4">11. Changes to This Policy</h3>
    <p class="mb-6 text-base">We may update this Privacy Policy from time to time. We will notify you of any significant changes through the app or via email.</p>
    
    <h3 class="text-xl font-semibold mb-4">12. Contact Us</h3>
    <p class="mb-6 text-base">If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at admin&#64;triggerabsence.com.</p>

    <p class="text-sm italic">Last updated: 18/07/2024</p>
  </section>

  <section id="terms-of-use" class="mb-16">
    <h2 class="text-2xl lg:text-4xl font-semibold mb-8 text-secondary">Terms of Use</h2>

    <h3 class="text-xl font-semibold mb-4">1. Introduction</h3>
    <p class="mb-6 text-base">Welcome to Trigger Absence. These Terms of Service ("Terms") govern your use of our application and services. By accessing or using Trigger Absence, you agree to be bound by these Terms. If you disagree with any part of the Terms, you may not use our services.</p>

    <h3 class="text-xl font-semibold mb-4">2. Definitions</h3>
    <ul class="list-disc pl-6 mb-6 text-base">
        <li class="mb-2">"App" refers to Trigger Absence</li>
        <li class="mb-2">"Service" refers to the services provided through the App</li>
        <li class="mb-2">"User" refers to you, the person accessing or using the App</li>
        <li class="mb-2">"We," "Us," or "Our" refers to Hunter New England Health</li>
    </ul>

    <h3 class="text-xl font-semibold mb-4">3. Acceptance of Terms</h3>
    <p class="mb-6 text-base">By using our App, you confirm that you have read, understood, and agree to be bound by these Terms, as well as our Privacy Policy.</p>

    <h3 class="text-xl font-semibold mb-4">4. Eligibility</h3>
    <p class="mb-6 text-base">You must be at least 18 years old and capable of forming a binding contract to use our Service. If you are accessing the Service on behalf of a business or entity, you represent that you have the authority to bind that entity to these Terms.</p>

    <h3 class="text-xl font-semibold mb-4">5. User Accounts</h3>
    <ol class="list-decimal pl-6 mb-6 text-base">
        <li class="mb-2">You are responsible for maintaining the confidentiality of your account and password.</li>
        <li class="mb-2">You agree to notify us immediately of any unauthorised use of your account.</li>
        <li class="mb-2">We reserve the right to refuse service, terminate accounts, or remove content at our discretion.</li>
    </ol>

    <h3 class="text-xl font-semibold mb-4">6. Use of the Service</h3>
    <ol class="list-decimal pl-6 mb-6 text-base">
        <li class="mb-2">You agree to use the Service only for lawful purposes and in accordance with these Terms.</li>
        <li class="mb-2">You must not use the Service in any way that could damage, disable, overburden, or impair our servers or networks.</li>
        <li class="mb-2">You must not attempt to gain unauthorised access to any part of the Service or any system or network connected to the Service.</li>
    </ol>

    <h3 class="text-xl font-semibold mb-4">7. Content and Intellectual Property Rights</h3>
    <ol class="list-decimal pl-6 mb-6 text-base">
        <li class="mb-2">The App and its original content, features, and functionality are owned by Hunter New England Health and are protected by international copyright, trademark, patent, trade secret, and other intellectual property laws.</li>
        <li class="mb-2">You retain all rights to any content you submit, post, or display on or through the Service.</li>
    </ol>

    <h3 class="text-xl font-semibold mb-4">8. Medical Disclaimer</h3>
    <ol class="list-decimal pl-6 mb-6 text-base">
        <li class="mb-2">The App is not a substitute for professional medical advice, diagnosis, or treatment.</li>
        <li class="mb-2">Always seek the advice of your physician or other qualified health provider with any questions you may have regarding a medical condition.</li>
    </ol>

    <h3 class="text-xl font-semibold mb-4">9. Limitation of Liability</h3>
    <p class="mb-6 text-base">To the fullest extent permitted by applicable law, Hunter New England Health shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from your use of the Service.</p>

    <h3 class="text-xl font-semibold mb-4">10. Indemnification</h3>
    <p class="mb-6 text-base">You agree to indemnify and hold Hunter New England Health and its affiliates, officers, agents, employees, and partners harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your use of the Service, your violation of these Terms, or your violation of any rights of another.</p>

    <h3 class="text-xl font-semibold mb-4">11. Modifications to the Service</h3>
    <p class="mb-6 text-base">We reserve the right to modify or discontinue, temporarily or permanently, the Service (or any part thereof) with or without notice. You agree that Hunter New England Health shall not be liable to you or to any third party for any modification, suspension, or discontinuance of the Service.</p>

    <h3 class="text-xl font-semibold mb-4">12. Governing Law</h3>
    <p class="mb-6 text-base">These Terms shall be governed and construed in accordance with the laws of Australia, without regard to its conflict of law provisions.</p>

    <h3 class="text-xl font-semibold mb-4">13. Changes to Terms</h3>
    <p class="mb-6 text-base">We reserve the right to update or change these Terms at any time. We will provide notice of any significant changes. Your continued use of the Service after such modifications will constitute your acknowledgement of the modified Terms and agreement to abide by them.</p>

    <h3 class="text-xl font-semibold mb-4">14. Contact Us</h3>
    <p class="mb-6 text-base">If you have any questions about these Terms, please contact us at admin&#64;triggerabsence.com.</p>

    <p class="text-sm italic">Last updated: 18/07/2024</p>
  </section>

  <section id="cookie-policy" class="mb-16">
    <h2 class="text-2xl lg:text-4xl font-semibold mb-8 text-secondary">Cookie Policy</h2>

    <h3 class="text-xl font-semibold mb-4">1. Introduction</h3>
    <p class="mb-6 text-base">This Cookie Policy explains how Trigger Absence ("we", "us", or "our"), operated in partnership with Hunter New England Health, uses cookies and similar technologies to recognise you when you visit our application. It explains what these technologies are and why we use them, as well as your rights to control our use of them.</p>

    <h3 class="text-xl font-semibold mb-4">2. What are cookies?</h3>
    <p class="mb-6 text-base">Cookies are small data files that are placed on your device when you visit a website or use an application. Cookies are widely used by online service providers to facilitate and help to make the interaction between users and websites, mobile apps and online platforms faster and easier, as well as to provide reporting information.</p>

    <h3 class="text-xl font-semibold mb-4">3. Why do we use cookies?</h3>
    <p class="mb-4 text-base">We use cookies to:</p>
    <ul class="list-disc pl-6 mb-6 text-base">
        <li class="mb-2">Remember your preferences and settings</li>
        <li class="mb-2">Maintain your sessions across the app</li>
        <li class="mb-2">Improve the performance and functionality of our app</li>
        <li class="mb-2">Understand how you use our app to help us improve it</li>
    </ul>

    <h3 class="text-xl font-semibold mb-4">4. Types of cookies we use</h3>
    <p class="mb-4 text-base">We use the following types of cookies:</p>
    <ul class="list-disc pl-6 mb-6 text-base">
        <li class="mb-2"><strong>Essential cookies:</strong> These cookies are strictly necessary to provide you with services available through our app and to use some of its features, such as access to secure areas.</li>
        <li class="mb-2"><strong>Preference cookies:</strong> These cookies are used to store your preferences and settings, such as language preferences or accessibility settings.</li>
        <li class="mb-2"><strong>Analytics cookies:</strong> These cookies help us understand how visitors interact with our app by collecting and reporting information anonymously.</li>
    </ul>

    <h3 class="text-xl font-semibold mb-4">5. How can you control cookies?</h3>
    <p class="mb-6 text-base">You have the right to decide whether to accept or reject cookies. You can set or amend your web browser controls to accept or refuse cookies. If you choose to reject cookies, you may still use our app though your access to some functionality and areas may be restricted.</p>

    <h3 class="text-xl font-semibold mb-4">6. How often will we update this Cookie Policy?</h3>
    <p class="mb-6 text-base">We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal or regulatory reasons. Please therefore re-visit this Cookie Policy regularly to stay informed about our use of cookies and related technologies.</p>

    <h3 class="text-xl font-semibold mb-4">7. Australian Privacy Principles (APP) compliance</h3>
    <p class="mb-4 text-base">Our use of cookies complies with the Australian Privacy Principles (APPs) under the Privacy Act 1988 (Cth). We ensure that:</p>
    <ul class="list-disc pl-6 mb-6 text-base">
        <li class="mb-2">We only collect information that is reasonably necessary for our functions</li>
        <li class="mb-2">We are transparent about our use of cookies</li>
        <li class="mb-2">We provide you with options to control cookie usage</li>
        <li class="mb-2">We protect the information collected via cookies from misuse, interference, loss, and unauthorised access</li>
    </ul>

    <h3 class="text-xl font-semibold mb-4">8. More information</h3>
    <p class="mb-6 text-base">If you have any questions about our use of cookies or other technologies, please contact us at admin&#64;triggerabsence.com.</p>

    <p class="text-sm italic">Last updated: 18/07/2024</p>
  </section>

  <section id="gdpr" class="mb-16">
    <h2 class="text-2xl lg:text-4xl font-semibold mb-8 text-secondary">GDPR for use in Europe</h2>
    <p class="mb-6 text-base">For our European users, we comply with the General Data Protection Regulation (GDPR) by implementing the following measures:</p>
    
    <h3 class="text-xl font-semibold mb-4">1. Lawful Basis for Processing</h3>
    <p class="mb-6 text-base">We process personal data only when we have a lawful basis to do so, such as consent, contract fulfillment, legal obligation, or legitimate interests.</p>
  
    <h3 class="text-xl font-semibold mb-4">2. Data Subject Rights</h3>
    <p class="mb-4 text-base">We respect and uphold the rights of European users, including:</p>
    <ul class="list-disc pl-6 mb-6 text-base">
      <li class="mb-2">The right to access their personal data</li>
      <li class="mb-2">The right to rectification of inaccurate data</li>
      <li class="mb-2">The right to erasure ('right to be forgotten')</li>
      <li class="mb-2">The right to restrict processing</li>
      <li class="mb-2">The right to data portability</li>
      <li class="mb-2">The right to object to processing</li>
    </ul>
  
    <h3 class="text-xl font-semibold mb-4">3. Consent Management</h3>
    <p class="mb-6 text-base">We obtain explicit consent for processing personal data where required, and provide easy ways for users to withdraw consent at any time.</p>
  
    <h3 class="text-xl font-semibold mb-4">4. Data Protection Policy</h3>
    <p class="mb-6 text-base">We have appointed a Data Protection Policy to oversee our GDPR compliance. This can be viewed by contacting us at admin&#64;triggerabsence.com.</p>
  
    <h3 class="text-xl font-semibold mb-4">5. Data Breach Notification</h3>
    <p class="mb-6 text-base">In the event of a data breach affecting European users' personal data, we will notify the relevant supervisory authority within 72 hours and affected users without undue delay.</p>
  
    <h3 class="text-xl font-semibold mb-4">6. Data Protection Impact Assessments</h3>
    <p class="mb-6 text-base">We conduct Data Protection Impact Assessments (DPIAs) for high-risk processing activities to ensure we're adequately protecting user data.</p>
  
    <h3 class="text-xl font-semibold mb-4">7. International Data Transfers</h3>
    <p class="mb-6 text-base">When transferring data outside the EEA, we ensure appropriate safeguards are in place, such as Standard Contractual Clauses or adequacy decisions.</p>
  
    <h3 class="text-xl font-semibold mb-4">8. Privacy by Design and Default</h3>
    <p class="mb-6 text-base">We implement technical and organizational measures to ensure data protection principles are integrated into our processing activities from the onset.</p>
  
    <p class="text-base">For more information about how we comply with GDPR or to exercise your rights under GDPR, please contact us at admin&#64;triggerabsence.com.</p>
  
    <p class="text-sm italic mt-6">Last updated: 18/07/2024</p>
  </section>

  <section id="moderation" class="mb-16">
    <h2 class="text-2xl lg:text-4xl font-semibold mb-8 text-secondary">Why do we moderate the use of Trigger Absence?</h2>
    <p class="mb-6 text-base">We moderate the use of Trigger Absence to ensure the privacy, security, and appropriate use of our application. Our moderation practices include:</p>
  
    <h3 class="text-xl font-semibold mb-4">1. User Authentication and Verification</h3>
    <p class="mb-6 text-base">Due to the sensitive nature of the information handled by Trigger Absence, we validate each login to verify that the person requesting access is deemed appropriate. This process includes:</p>
    <ul class="list-disc pl-6 mb-6 text-base">
      <li class="mb-2">Strict authentication protocols</li>
      <li class="mb-2">Regular account audits</li>
      <li class="mb-2">Multi-factor authentication for enhanced security</li>
    </ul>
  
    <h3 class="text-xl font-semibold mb-4">2. Data Access Controls</h3>
    <p class="mb-6 text-base">We implement robust access controls to ensure that users can only access information they are authorized to view or modify. This aligns with our commitment to data protection and privacy.</p>
  
    <h3 class="text-xl font-semibold mb-4">3. Content Monitoring</h3>
    <p class="mb-6 text-base">While respecting user privacy, we monitor the use of our application to detect and prevent any misuse, unauthorized access, or potential security threats.</p>
  
    <h3 class="text-xl font-semibold mb-4">4. Compliance with Privacy Laws</h3>
    <p class="mb-6 text-base">Our moderation practices comply with relevant privacy laws, including the Australian Privacy Principles (APPs) and GDPR for European users. This includes:</p>
    <ul class="list-disc pl-6 mb-6 text-base">
      <li class="mb-2">Collecting only necessary information</li>
      <li class="mb-2">Implementing appropriate security measures</li>
      <li class="mb-2">Respecting user rights regarding their data</li>
    </ul>
  
    <h3 class="text-xl font-semibold mb-4">5. Data Retention and Deletion</h3>
    <p class="mb-6 text-base">As part of our moderation process, we enforce our data retention policy. Personal information and video recordings are retained for 30 days, after which they are automatically deleted unless explicit consent for extended storage is provided.</p>
  
    <h3 class="text-xl font-semibold mb-4">6. User Account Management</h3>
    <p class="mb-6 text-base">We reserve the right to refuse service, terminate accounts, or remove content at our discretion if we detect violation of our terms of service or inappropriate use of the application.</p>
  
    <h3 class="text-xl font-semibold mb-4">7. Secure Data Storage</h3>
    <p class="mb-6 text-base">All personal information, including video recordings, is stored encrypted at rest. We implement appropriate technical and organisational measures to protect your data from unauthorised access, alteration, disclosure, or destruction.</p>
  
    <h3 class="text-xl font-semibold mb-4">8. Continuous Monitoring and Improvement</h3>
    <p class="mb-6 text-base">We regularly review and update our moderation practices to ensure they remain effective and aligned with the latest security standards and privacy regulations.</p>
  
    <p class="text-base">If you have any questions about our moderation practices or concerns about the use of Trigger Absence, please contact us at admin&#64;triggerabsence.com.</p>
  
    <p class="text-sm italic mt-6">Last updated: 18/07/2024</p>
  </section>
</div>