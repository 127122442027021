<section class="mt-0 pt-0 my-4 p-4 sm:my-8 sm:p-8 lg:my-12 lg:p-12 max-w-full lg:max-w-screen-xl mx-auto">
    <div class="flex justify-between items-center my-4 sm:my-6 lg:my-8">
        <h2 class="text-xl sm:text-2xl lg:text-4xl font-semibold text-secondary">Users</h2>
    </div>

    <div role="tablist" class="tabs tabs-bordered mb-4 flex justify-between">
        <div class="inline-flex">
            <a role="tab" class="tab" [ngClass]="{'tab-active': isTabActive('incomplete')}" (click)="setTab('incomplete')">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon stroke-yellow-500 mr-2" width="20" height="20"
                    viewBox="0 0 24 24" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="12" cy="12" r="6" />
                </svg>
                Incomplete
                <span class="ml-2 px-2 py-1 bg-base-200 rounded-full text-xs">{{ getUserCount('incomplete') || '-' }}</span>
            </a>
            <a role="tab" class="tab" [ngClass]="{'tab-active': isTabActive('complete_pending')}" (click)="setTab('complete_pending')">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon stroke-blue-500 mr-2" width="20" height="20"
                    viewBox="0 0 24 24" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                    <path d="M12 12l0 -3" />
                    <path d="M12 12l3 3" />
                </svg>
                Pending approval, account complete
                <span class="ml-2 px-2 py-1 bg-base-200 rounded-full text-xs">{{ getUserCount('complete_pending') || '-' }}</span>
            </a>
        </div>
        <div class="inline-flex">
            <a role="tab" class="tab" [ngClass]="{'tab-active': isTabActive('approved')}" (click)="setTab('approved')">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon stroke-green-500 mr-2" width="20" height="20"
                    viewBox="0 0 24 24" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M5 12l5 5l10 -10" />
                </svg>
                Approved
                <span class="ml-2 px-2 py-1 bg-base-200 rounded-full text-xs">{{ getUserCount('approved') || '-' }}</span>
            </a>
            <a role="tab" class="tab" [ngClass]="{'tab-active': isTabActive('rejected')}" (click)="setTab('rejected')">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon stroke-red-500 mr-2" width="20" height="20"
                    viewBox="0 0 24 24" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M18 6l-12 12" />
                    <path d="M6 6l12 12" />
                </svg>
                Rejected
                <span class="ml-2 px-2 py-1 bg-base-200 rounded-full text-xs">{{ getUserCount('rejected') || '-' }}</span>
            </a>
            <a role="tab" class="tab" [ngClass]="{'tab-active': isTabActive('all')}" (click)="setTab('all')">
                All Users
                <span class="ml-2 px-2 py-1 bg-base-200 rounded-full text-xs">{{ getUserCount('all') || '-' }}</span>
            </a>
        </div>
    </div>

    <div class="overflow-x-auto">
        @if (filteredUsers().length > 0) {
        <table class="table">
            <thead>
                <tr>
                    <th>Email Address</th>
                    <th>Name</th>
                    <th>Occupation</th>
                    <th (click)="toggleSort('joined')" class="cursor-pointer">
                        Joined
                        @if (sortColumn() === 'joined') {
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-{{ sortDirection() === 'asc' ? 'up' : 'down' }} inline-block ml-1" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <polyline [attr.points]="sortDirection() === 'asc' ? '6 15 12 9 18 15' : '6 9 12 15 18 9'"></polyline>
                            </svg>
                        }
                    </th>
                    <th>Approval Status</th>
                    <th>Profile Status</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                @for (user of filteredUsers(); track user.id) {
                <tr [ngClass]="{'bg-success': user.statusSaved}" [attr.data-user-id]="user.id">
                    <td class="align-top">{{ user.email }}</td>
                    <td class="align-top">{{ user.displayName }}</td>
                    <td class="align-top">{{ user.occupation || 'N/A' }}</td>
                    <td class="align-top">{{ user.createdAt | date:'medium' }}</td>
                    <td class="align-top">
                        <div class="flex items-center">
                            @if (currentTab() === 'all') {
                                @switch (user.approvedStatus) {
                                    @case ('pending') {
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon stroke-yellow-500 mr-2" width="20" height="20"
                                            viewBox="0 0 24 24" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <circle cx="12" cy="12" r="6" />
                                        </svg>
                                    }
                                    @case ('approved') {
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon stroke-green-500 mr-2" width="20" height="20"
                                            viewBox="0 0 24 24" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M5 12l5 5l10 -10" />
                                        </svg>
                                    }
                                    @case ('rejected') {
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon stroke-red-500 mr-2" width="20" height="20"
                                            viewBox="0 0 24 24" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M18 6l-12 12" />
                                            <path d="M6 6l12 12" />
                                        </svg>
                                    }
                                }
                            }
                            <select class="select w-full max-w-xs select-sm py-0 my-0" [ngModel]="user.approvedStatus"
                                (ngModelChange)="showConfirmationModal(user, $event)">
                                <option value="pending">Pending</option>
                                <option value="rejected">Rejected</option>
                                <option value="approved">Approved</option>
                            </select>
                        </div>
                    </td>
                    <td class="align-top">
                        @if (user.roles.includes('admin') || user.roles.includes('superadmin')) {
                            N/A
                        } @else {
                            @if (user.profileComplete) {
                                @if (user.approvedStatus === 'pending') {
                                    Pending approval, account complete
                                } @else {
                                    Complete
                                }
                            } @else {
                                Incomplete, waiting on user
                            }
                        }
                    </td>
                    <td class="align-top">
                        @if (user.statusSaved) {
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-check text-success"
                            width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                            fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M5 12l5 5l10 -10" />
                        </svg>
                        Status saved
                        }
                        @if (user.roles.includes('admin') || user.roles.includes('superadmin')) {
                            <div class="tooltip" data-tip="Cannot view admin users">
                                <button class="btn btn-sm btn-primary btn-outline ml-2" disabled>View</button>
                            </div>
                        } @else {
                            <button class="btn btn-sm btn-primary btn-outline ml-2" (click)="viewUserDetails(user)">View</button>
                        }
                    </td>
                </tr>
                }
            </tbody>
        </table>
        } @else {
        <div role="alert" class="alert">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                class="stroke-info h-6 w-6 shrink-0">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
            </svg>
            <span>No users available.</span>
        </div>
        }
    </div>
</section>

<!-- Confirmation Modal -->
<dialog #confirmationModal class="modal modal-bottom sm:modal-middle">
  <div class="modal-box">
    <h3 class="text-lg font-bold">Confirm Status Change</h3>
    <p class="py-4">An {{ pendingStatus() === 'approved' ? 'Approval' : 'Rejection' }} email will now be sent to {{ pendingUser()?.email }}</p>
    
    @if (pendingStatus() === 'rejected') {
      <div class="form-control">
        <label class="label">
          <span class="label-text">Provide rejection reason (optional):</span>
        </label>
        <textarea 
          class="textarea textarea-bordered" 
          placeholder="Enter reason for rejection"
          [(ngModel)]="rejectionReason">
        </textarea>
      </div>
    }

    <div class="modal-action">
      <button class="btn btn-primary" (click)="confirmStatusChange()">Ok, proceed</button>
      <button class="btn btn-outline btn-secondary" (click)="cancelStatusChange()">Cancel</button>
    </div>
  </div>
</dialog>