<div class="w-96 h-full overflow-auto">
    <div class="card bg-black bg-opacity-50 p-1">
        <div class="card-body p-1 text-xs">
            <!-- Debug Information -->
            <h2 class="font-bold text-white mb-1 text-left">Debug Info</h2>
            <div class="grid grid-cols-2 gap-x-2 mb-2 text-left">
                <p class="text-white">Sound Detected: {{ soundDetected() }}</p>
                <p class="text-white">Total Profiles: {{ allProfiles().length }}</p>
                <p class="text-white">Blow Profiles: {{ blowProfiles().length }}</p>
                <p class="text-white">Match Results: {{ matchResults().length }}</p>
            </div>

            <!-- All Profiles -->
            <h2 class="font-bold text-white mb-1 text-left">All Profiles</h2>
            <div class="flex flex-wrap gap-1 mb-2">
                <ng-container *ngFor="let item of allProfilesWithSimilarity()">
                    <div class="flex items-center flex-col">
                        <div class="tooltip tooltip-right" [attr.data-tip]="item.profile.type + ': ' + item.profile.id">
                            <div class="w-4 h-4 rounded-full"
                                [ngClass]="{'bg-green-500': item.similarity > 0.5, 'bg-black': item.similarity <= 0.5}">
                            </div>
                        </div>
                        <span class="text-white">{{ (item.similarity * 100).toFixed(2) }}%</span>
                    </div>
                </ng-container>
                <div *ngIf="allProfilesWithSimilarity().length === 0" class="text-white">No profiles found</div>
            </div>

            <!-- Blow Profiles -->
            <h2 class="font-bold text-white mb-1 text-left">Blow Profiles</h2>
            <div class="flex flex-wrap gap-1 mb-2">
                <ng-container *ngFor="let item of blowProfilesWithSimilarity()">
                    <div class="flex items-center flex-col">
                        <div class="tooltip tooltip-right" [attr.data-tip]="item.profile.id">
                            <div class="w-4 h-4 rounded-full"
                                [ngClass]="{'bg-green-500': item.similarity > 0.5, 'bg-black': item.similarity <= 0.5}">
                            </div>
                        </div>
                        <span class="text-white">{{ (item.similarity * 100).toFixed(2) }}%</span>
                    </div>
                </ng-container>
                <div *ngIf="blowProfilesWithSimilarity().length === 0" class="text-white">No blow profiles found</div>
            </div>

            <!-- Other Profiles -->
            <h2 class="font-bold text-white mb-1 text-left">Hello / Clap / Laugh</h2>
            <div class="flex flex-wrap gap-1">
                <ng-container *ngFor="let item of otherProfilesWithSimilarity()">
                    <div class="flex items-center flex-col">
                        <div class="tooltip tooltip-top" [attr.data-tip]="item.profile.type + ': ' + item.profile.id">
                            <div class="w-4 h-4 rounded-full"
                                [ngClass]="{'bg-green-500': item.similarity > 0.5, 'bg-black': item.similarity <= 0.5}">
                            </div>
                        </div>
                        <span class="text-white">{{ (item.similarity * 100).toFixed(2) }}%</span>
                    </div>
                </ng-container>
                <div *ngIf="otherProfilesWithSimilarity().length === 0" class="text-white">No matching profiles found
                </div>
            </div>
        </div>
    </div>
</div>