import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavigationComponent } from '@components/navigation/navigation.component';
import { FooterComponent } from '@components/footer/footer.component';
import { LoginComponent } from '@components/login/login.component';
import { AudioDetectionService } from '@services/audio-detection.service';

@NgModule({
  declarations: [FooterComponent, NavigationComponent],
  imports: [CommonModule, RouterModule, LoginComponent],
  exports: [FooterComponent, NavigationComponent, LoginComponent],
  providers: [AudioDetectionService]
})
export class SharedModule { }