import { Component, OnInit, effect } from '@angular/core';
import { Firestore, collection, addDoc } from '@angular/fire/firestore';
import { getStorageInstance } from '@shared/firebase-config';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { WebcamComponent } from '@shared/components/webcam/webcam.component';
import { UserService } from '@services/user.service';
import { ActivatedRoute } from '@angular/router';

const DEBUG_MODE = false; // Set this to true to enable logging

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  standalone: true,
  imports: [WebcamComponent]
})
export class HomeComponent implements OnInit {
  selectedFile: File | null = null;
  demoMode = false;
  debugMode: boolean = false;

  constructor(private firestore: Firestore, private userService: UserService, private route: ActivatedRoute) {
    this.log('HomeComponent instantiated');
    effect(() => {
      this.log('Homepage current user roles:', this.userService.userRoles());
    });
  }

  ngOnInit() {
    this.log('ngOnInit called');
    // Intentionally trigger an error to test Sentry integration
    // this.triggerError();

    this.route.queryParams.subscribe(params => {
      this.debugMode = params['debug'] === 'true';
      this.log('Debug mode set to:', this.debugMode);
    });
  }

  onFileSelected(event: Event) {
    this.log('onFileSelected triggered');
    const input = event.target as HTMLInputElement;
    if (input.files?.length) {
      this.selectedFile = input.files[0];
      this.log('File selected:', this.selectedFile.name);
    } else {
      this.log('No file selected');
    }
  }

  uploadFile() {
    this.log('uploadFile triggered');
    if (!this.selectedFile) {
      this.log('No file selected for upload.');
      console.error('No file selected for upload.');
      return;
    }

    const storage = getStorageInstance();
    const storageRef = ref(storage, `uploads/${this.selectedFile.name}`);

    uploadBytes(storageRef, this.selectedFile).then((snapshot) => {
      this.log('Uploaded a blob or file!', snapshot);
      getDownloadURL(snapshot.ref).then((downloadURL) => {
        this.log('File available at', downloadURL);
      });
    }).catch((error) => {
      this.log('Error uploading file:', error);
      console.error('Error uploading file:', error);
    });
  }

  onDemoModeChange(isDemo: boolean) {
    this.log('onDemoModeChange triggered with:', isDemo);
    this.demoMode = isDemo;
  }

  // private triggerError() {
  //   // Define a dummy function to avoid compilation errors
  //   const myUndefinedFunction = () => {
  //     throw new Error('This is a test error for Sentry integration.');
  //   };
  //   myUndefinedFunction();
  // }

  /**
   * Logs messages if DEBUG_MODE is enabled.
   * @param args The messages or data to log.
   */
  private log(...args: any[]): void {
    if (DEBUG_MODE) {
      console.log(...args);
    }
  }

  /**
   * Logs grouped messages if DEBUG_MODE is enabled.
   * @param label The label for the console group.
   */
  private logGroup(label: string): void {
    if (DEBUG_MODE) {
      console.group(label);
    }
  }

  /**
   * Ends a console group if DEBUG_MODE is enabled.
   */
  private logGroupEnd(): void {
    if (DEBUG_MODE) {
      console.groupEnd();
    }
  }
}