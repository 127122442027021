<!-- Add this above the video element -->
<div *ngIf="showTrimmedAlert && !readOnly" class="alert alert-success mb-4">
  <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
  </svg>
  <span>Showing new {{ trimmedDuration?.toFixed(1) }} second trimmed video</span>
</div>

<!-- Video Player -->
<video #videoElement id="videoPlayer" class="w-full h-auto mb-4" [src]="videoUrl || ''"
  (loadedmetadata)="onVideoMetadataLoaded($event)" (timeupdate)="onTimeUpdate($event)" [controls]="readOnly">
</video>

<!-- Custom Video Controls -->
<div *ngIf="!readOnly" class="mt-4 flex flex-col items-center space-y-4">

  <!-- Play Button -->
  <div class="flex justify-center">
    <button (click)="togglePlayPause()"
      class="btn btn-circle rounded-full h-16 w-16 transition-transform duration-300 mr-auto"
      style="border-radius: 50%;">
      <svg *ngIf="!isPlaying" xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" viewBox="0 0 24 24"
        fill="currentColor">
        <path d="M8 5v14l11-7z" />
      </svg>
      <svg *ngIf="isPlaying" xmlns="http://www.w3.org/2000/svg" class="h-14 w-14" viewBox="0 0 24 24"
        fill="currentColor">
        <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" />
      </svg>
    </button>
  </div>

  <!-- Add this above the slider to display Start and End Time -->
  <div class="w-full px-4 mb-2 flex justify-between text-sm text-gray-700">
    <span>{{ formatTime(originalStartTime) }}</span>
    <span>{{ formatTime(originalEndTime) }}</span>
  </div>

  <!-- Always Visible Slider with Enhanced Styling -->
  <div class="w-full mb-4 mt-4 px-4">
    <div class="relative w-full h-5 bg-gray-200 rounded-full overflow-visible">

      <!-- Vertical Lines Indicating Timed Positions -->
      <div *ngFor="let line of [1,2,3,4,5,6,7,8,9,10]"
        class="absolute top-1/2 transform -translate-y-1/2 z-20 pointer-events-none"
        [style.left]="((line - 1) * 10) + '%'">
        <div class="w-1 h-2.5 bg-black"></div>
      </div>

      <!-- Trimmed Part (Left) -->
      <div class="absolute top-0 left-0 h-full bg-neutral rounded-l-full z-10"
        [style.width]="(startTime / videoDuration * 100) + '%'">
      </div>

      <!-- Trimmed Part (Right) -->
      <div class="absolute top-0 right-0 h-full bg-neutral rounded-r-full z-10"
        [style.width]="((videoDuration - endTime) / videoDuration * 100) + '%'">
      </div>

      <!-- White Rectangle (Start Time) with Updated Tooltip -->
      <div class="absolute top-1/2 transform -translate-y-1/2 cursor-grab z-40"
        [style.left]="(startTime / videoDuration * 100) + '%'" (mousedown)="startDragging('start', $event)">
        <div class="tooltip tooltip-open" [attr.data-tip]="'START ' + formatTime(startTime)">
          <div class="w-6 h-8 bg-white rounded-sm flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-600" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 9v6m4-6v6" />
            </svg>
          </div>
        </div>
      </div>

      <!-- Blue Circle (Current Time) -->
      <div class="absolute top-1/2 transform -translate-y-1/2 w-4 h-4 bg-blue-500 rounded-full cursor-pointer z-40"
        [style.left]="showTrimControls ? 'calc(' + currentTimePosition + '% + 25px)' : currentTimePosition + '%'"
        (mousedown)="startDragging('current', $event)"></div>

      <!-- White Rectangle (End Time) with Updated Tooltip -->
      <div class="absolute top-1/2 transform -translate-y-1/2 cursor-grab z-40"
        [style.left]="(endTime / videoDuration * 100) + '%'" (mousedown)="startDragging('end', $event)">
        <div class="tooltip tooltip-open" [attr.data-tip]="'END ' + formatTime(endTime)">
          <div class="w-6 h-8 bg-white rounded-sm flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-600" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 9v6m4-6v6" />
            </svg>
          </div>
        </div>
      </div>

    </div>
  </div>

  <!-- Trim Button Row -->
  <div class="flex justify-center w-full mt-8">
    <button type="button" (click)="handleTrim()"
      class="btn btn-outline transition-transform duration-300 transform w-84"
      [ngClass]="{'translate-x-[-20px]': showTrimControls}" [class.tooltip-open]="showTooltip"
      data-tip="Drag the trim scrubbers to isolate the point of a suspected seizure and then Trim video"
      placement="top">
      <span *ngIf="trimButtonState === 'idle'" class="ml-2">✂️ Trim video</span>
      <span *ngIf="trimButtonState === 'loading'" class="ml-2">
        Loading video trimming tools
        <progress class="progress w-56"></progress>
      </span>
      <span *ngIf="trimButtonState === 'performing'" class="ml-2">
        Performing Trim
        <span class="loading loading-infinity loading-xs"></span>
      </span>
    </button>
  </div>

  <!-- Show Trim Controls Positioned Responsively -->
  <div *ngIf="showTrimControls" class="w-full flex flex-col items-center space-y-4">
    <!-- Time Input Fields and Trim Controls (hidden by default) -->
    <div class="w-full mb-4 px-4">
      <div class="card w-full">
        <div *ngIf="precisionTrim" class="card-body">
          <!-- Centered Trim button -->

          <!-- Precision Trim Placeholder -->
          <div *ngIf="precisionTrim">
            <!-- Start and End Time Inputs -->
            <form [formGroup]="timeForm" class="flex space-x-4 items-end mb-4">
              <div class="flex flex-col">
                <label for="startTime" class="mb-1">Start Time</label>
                <input id="startTime" formControlName="startTime" (blur)="onTimeInputBlur('startTime')"
                  (keydown)="onKeyDown($event, 'startTime')" class="input input-bordered w-full" />
              </div>
              <div class="flex flex-col">
                <label for="endTime" class="mb-1">End Time</label>
                <input id="endTime" formControlName="endTime" (blur)="onTimeInputBlur('endTime')"
                  (keydown)="onKeyDown($event, 'endTime')" class="input input-bordered w-full" />
              </div>
            </form>
          </div>

          <button *ngIf="trimmedVideoUrl" (click)="downloadVideo()" class="btn btn-secondary mt-4">
            Download Trimmed Video
          </button>
        </div>
      </div>
    </div>
  </div>

  
  <!-- Trim Video Button with Conditional Enabling and Tooltip -->


</div>

<!-- Hide all trim controls when in readOnly mode -->
<ng-container *ngIf="!readOnly">
  <!-- Trim Button Row -->
  <div class="flex justify-center w-full mt-8">
    <!-- ... existing trim button ... -->
  </div>

  <!-- Show Trim Controls Positioned Responsively -->
  <div *ngIf="showTrimControls" class="w-full flex flex-col items-center space-y-4">
    <!-- ... existing trim controls ... -->
  </div>
</ng-container>
