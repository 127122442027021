import { Injectable } from '@angular/core';
import { Storage, ref, uploadBytes, getDownloadURL, deleteObject } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor(private storage: Storage) {}

  async uploadVideo(file: File, videoId: string): Promise<string> {
    const filePath = `videos/${videoId}.webm`;
    const storageRef = ref(this.storage, filePath);

    // Upload the file, which will overwrite if it already exists
    await uploadBytes(storageRef, file);

    // Get the download URL
    const downloadURL = await getDownloadURL(storageRef);

    return downloadURL;
  }

  async deleteVideo(videoId: string): Promise<void> {
    const storageRef = ref(this.storage, `videos/${videoId}`);
    await deleteObject(storageRef);
  }

  async deleteVideoByFileName(fileName: string): Promise<void> {
    const storageRef = ref(this.storage, fileName);
    await deleteObject(storageRef);
  }

  async deleteVideoByUrl(videoUrl: string): Promise<void> {
    try {
      if (videoUrl.startsWith('blob:')) {
        console.log('Attempting to revoke blob URL:', videoUrl);
        URL.revokeObjectURL(videoUrl);
        console.log('Blob URL revoked successfully');
        return;
      }

      // Extract the path from the Firebase Storage URL
      const urlParts = videoUrl.split('/o/');
      if (urlParts.length < 2) {
        throw new Error('Invalid Firebase Storage URL');
      }
      const storagePath = decodeURIComponent(urlParts[1].split('?')[0]);
      
      console.log('Attempting to delete file from path:', storagePath);
      const fileRef = ref(this.storage, storagePath);
      await deleteObject(fileRef);
      console.log('File deleted successfully from Firebase Storage');
    } catch (error) {
      console.error('Error deleting video:', error);
      throw error;
    }
  }
}