<div class="container mx-auto px-4 py-8 flex">
  <div class="w-1/4 pr-8">
    <ul class="menu bg-base-200 w-56 rounded-box">
      <li><a (click)="scrollTo('overview')">Overview</a></li>
      <li><a (click)="scrollTo('key-features')">Key Features</a></li>
      <li><a (click)="scrollTo('user-registration')">User Registration</a></li>
      <li><a (click)="scrollTo('user-moderation')">User Moderation</a></li>
      <li><a (click)="scrollTo('video-submission')">Video Submission</a></li>
      <li><a (click)="scrollTo('video-moderation')">Video Moderation</a></li>
      <li><a (click)="scrollTo('audio-detection')">Audio Detection</a></li>
      <li><a (click)="scrollTo('audio-settings')">Audio Settings</a></li>
      <li><a (click)="scrollTo('audio-visualizer')">Audio Settings Visualizer</a></li>
      <li><a (click)="scrollTo('user-flow-diagram')">User Flow Diagram</a></li>
      <li><a (click)="scrollTo('patient-data')">Patient Data Management</a></li>
    </ul>
  </div>

  <div #content class="w-3/4">
    <h1 class="text-3xl font-bold mb-6">Trigger Absence Guide</h1>

    <section id="overview" class="mb-8">
      <h2 class="text-2xl font-semibold mb-4">Project Overview</h2>
      <p class="mb-4">
        Trigger Absence is a Progressive Web App (PWA) designed to assist in the rapid detection and triaging of
        suspected Absence Epilepsy. The app leverages recorded video and audio of patients undergoing hyperventilation.
        It provides a "gamified" interface to engage children during hyperventilation and allows parents and clinicians
        to securely upload recorded seizures to a central repository.
      </p>
      <p>
        The centralized repository of uploaded videos is accessed via a multi-factor secure login, enabling clinicians
        to quickly download, view, delete, and escalate cases for further investigation. Data is transmitted via
        end-to-end SSL and encrypted at rest within a data center based in Australia.
      </p>
    </section>

    <section id="key-features" class="mb-8">
      <h2 class="text-2xl font-semibold mb-4">Key Features</h2>
      <ul class="list-disc list-inside">
        <li><a href="https://cloud.google.com/storage/docs/encryption" target="_blank" rel="noopener noreferrer"
            class="text-blue-600 hover:underline">Secure video and audio upload</a></li>
        <li><a href="https://firebase.google.com/docs/auth/web/multi-factor-auth" target="_blank"
            rel="noopener noreferrer" class="text-blue-600 hover:underline">Multi-factor authentication</a></li>
        <li><a href="https://firebase.google.com/docs/hosting/custom-domain#ssl" target="_blank"
            rel="noopener noreferrer" class="text-blue-600 hover:underline">End-to-end SSL encryption</a></li>
        <li><a href="https://cloud.google.com/storage/docs/encryption/default-keys" target="_blank"
            rel="noopener noreferrer" class="text-blue-600 hover:underline">Data encryption at rest</a></li>
        <li><a href="https://en.wikipedia.org/wiki/Fast_Fourier_transform" target="_blank" rel="noopener noreferrer"
            class="text-blue-600 hover:underline">FFT-JS audio detection</a> for seizure patterns</li>
        <li><a href="https://firebase.google.com/docs/auth/web/email-link-auth" target="_blank"
            rel="noopener noreferrer" class="text-blue-600 hover:underline">Google Authentication with magic links</a>
        </li>
        <li><a href="https://firebase.google.com/docs/firestore/manage-data/structure-data" target="_blank"
            rel="noopener noreferrer" class="text-blue-600 hover:underline">Dynamic form creation for flexible data
            collection</a> (using Firestore to store and update form structures)</li>
      </ul>
    </section>


    <section id="user-registration" class="mb-8">
      <h2 class="text-2xl font-semibold mb-4">User Registration</h2>
      <p>User registration in Trigger Absence is handled through a secure Google Authentication process using magic
        links. This method provides a seamless and passwordless login experience for users.</p>
      <p>The registration process involves the following steps:</p>
      <ol class="list-decimal list-inside ml-4">
        <li>User enters their email address</li>
        <li>A magic link is sent to the provided email</li>
        <li>User clicks on the magic link to complete the registration/login process</li>
      </ol>
    </section>

    <section id="user-moderation" class="mb-8">
      <h2 class="text-2xl font-semibold mb-4">User Moderation</h2>
      <p>Admin users have the ability to moderate and manage user accounts. This includes approving new user
        registrations, managing user roles, and handling any account-related issues.</p>
    </section>

    <section id="video-submission" class="mb-8">
      <h2 class="text-2xl font-semibold mb-4">Video Submission and Trimming</h2>
      <p>Users can securely upload and trim videos of patients undergoing hyperventilation. The video submission process
        includes:</p>
      <ul class="list-disc list-inside ml-4">
        <li>Recording the video using the app's interface</li>
        <li>Trimming the video to focus on relevant sections</li>
        <li>Annotating the video with relevant information</li>
        <li>Securely uploading the trimmed video to the central repository</li>
      </ul>
      <p class="mt-4">The video trimming feature allows users to:</p>
      <ul class="list-disc list-inside ml-4">
        <li>Set start and end points for the trimmed section</li>
        <li>Preview the trimmed video before saving</li>
        <li>Adjust trim points using a slider or input fields</li>
      </ul>
    </section>

    <section id="video-moderation" class="mb-8">
      <h2 class="text-2xl font-semibold mb-4">Video Moderation</h2>
      <p>Authorized clinicians can access the uploaded videos for review and analysis. The video moderation process
        includes:</p>
      <ul class="list-disc list-inside ml-4">
        <li>Viewing uploaded videos</li>
        <li>Adding comments and annotations</li>
        <li>Escalating cases for further investigation if necessary</li>
      </ul>
    </section>

    <section id="audio-detection" class="mb-8">
      <h2 class="text-2xl font-semibold mb-4">Audio Detection and Classification</h2>
      <p class="mb-4">
        Our project utilizes advanced signal processing techniques to detect and classify various audio events, with a
        particular focus on identifying blowing sounds. This system is crucial for the app's functionality in detecting
        potential absence seizures.
      </p>
      <h3 class="text-xl font-semibold mb-2">Spectral Analysis and Feature Extraction</h3>
      <p class="mb-4">
        We employ the Fast Fourier Transform (FFT) algorithm, implemented via the `fft-js` library, to analyze the
        frequency content of audio signals. This allows us to extract key features that differentiate various sound
        types:
      </p>
      <ol class="list-decimal list-inside ml-4 mb-4">
        <li>
          <strong>Spectral Centroid:</strong> Represents the "center of mass" of the spectrum.
          <pre><code>SC = Σ(f[n] * m[n]) / Σm[n]</code></pre>
          where `f[n]` is the frequency of bin `n`, and `m[n]` is the magnitude of bin `n`.
        </li>
        <li>
          <strong>Spectral Rolloff:</strong> The frequency below which 85% of the spectrum's energy is concentrated.
          <pre><code>SR = argmax(Σm[n] >= 0.85 * Σm[n])</code></pre>
        </li>
        <li>
          <strong>Zero-Crossing Rate:</strong> The rate at which the signal changes from positive to negative or vice
          versa.
          <pre><code>ZCR = (1 / (N-1)) * Σ|sign(x[n]) - sign(x[n-1])|</code></pre>
          where `x[n]` is the signal at time `n`, and `N` is the length of the signal.
        </li>
        <li>
          <strong>Low-Frequency Energy Ratio:</strong> The ratio of energy below a certain frequency threshold to the
          total energy.
          <pre><code>LFER = Σ(m[n] for f[n] < threshold) / Σm[n]</code></pre>
        </li>
      </ol>
      <h3 class="text-xl font-semibold mb-2">Classification Criteria</h3>
      <p class="mb-4">Based on these features, we classify sounds using the following criteria:</p>
      <div class="bg-gray-100 p-4 rounded-md overflow-x-auto">
        <pre><code>
          if (isLongSustained && hasHighLowFrequencyRatio && hasLowAmplitudeVariation && 
              hasLowSpectralCentroid && hasLowSpectralRolloff && hasLowZeroCrossingRate) {{ '{' }}
            return 'blow';
          {{ '}' }} else if (isShortDuration && hasHighPeakAmplitude && hasHighSpectralCentroid) {{ '{' }}
            return 'clap';
          {{ '}' }} else if (hasMediumDuration && hasHighAmplitudeVariation && hasHighSpectralRolloff) {{ '{' }}
            return 'laugh';
          {{ '}' }} else if (hasModerateSpectralCentroid && hasModerateZeroCrossingRate) {{ '{' }}
            return 'hello';
          {{ '}' }} else {{ '{' }}
            return null; // Unknown sound
          {{ '}' }} 
        </code></pre>
      </div>
      <p class="mt-4">
        This approach allows us to accurately distinguish between blowing sounds and other common audio events,
        enhancing the app's ability to detect potential absence seizures during hyperventilation exercises.
      </p>
    </section>

    <section id="audio-settings" class="mb-8">
      <h2 class="text-2xl font-semibold mb-4">Audio Settings</h2>
      <p>The app uses advanced audio processing techniques, including FFT-JS, for detecting patterns that may indicate
        absence seizures. Admin users can configure and fine-tune these audio settings to optimize detection accuracy.
      </p>
    </section>

    <section id="audio-visualizer" class="mb-8">
      <h2 class="text-2xl font-semibold mb-4">Audio Settings Visualizer</h2>
      <p>The Audio Settings Visualizer, located at <a href="/admin/audiosettings"
          class="text-blue-600 hover:underline">admin/audiosettings</a>, is a tool for admin users to configure and
        visualize audio detection profiles. When using this tool:</p>
      <ul class="list-disc list-inside ml-4">
        <li>Focus on keeping records and profiles for Blow (positive) events where they are grouped together</li>
        <li>Remove any non-blow profiles that are close to the Blow profiles, as this may lead to less desirable results
        </li>
        <li>Use the visualizer to fine-tune the detection parameters for optimal performance</li>
      </ul>
    </section>

    <section id="user-flow-diagram" class="mb-8">
      <h2 class="text-2xl font-semibold mb-4">User Flow Diagram</h2>

      <div class="mermaid" [innerHTML]="mermaidDiagram()"></div>
    </section>

    <section id="patient-data" class="mb-8">
      <h2 class="text-2xl font-semibold mb-4">Patient Data Management</h2>
      <p>The Patient Data component allows authorized users to view and manage patient information, including:</p>
      <ul class="list-disc list-inside ml-4">
        <li>Patient demographics (name, date of birth)</li>
        <li>Recording date</li>
        <li>Event duration</li>
        <li>Seizure frequency and duration</li>
        <li>Epilepsy diagnosis</li>
        <li>Medication information</li>
        <li>Seizure occurrence details</li>
        <li>Additional comments</li>
      </ul>
      <p class="mt-4">Users can view detailed patient data and access associated videos for further analysis.</p>
    </section>
  </div>
</div>