import { Injectable, inject } from '@angular/core';
import { MailgunService } from './mailgun.service';
import { User } from '@shared/models/user.model';
import { Auth  } from '@angular/fire/auth';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  private mailgunService = inject(MailgunService);
  private auth = inject(Auth);
  private toastService = inject(ToastService);




  sendNewVideoNotification() {
    console.log('EmailService: sendNewVideoNotification method called');
    const to = 'admin@triggerabsence.com';
    const subject = 'New Video Ready';
    const text = 'A new video has been uploaded and is ready for review. You can view it at: https://triggerabsence.com/admin/all-videos';

    console.log('EmailService: Sending email with:', { to, subject, text });
    this.mailgunService.sendEmail(to, subject, text).subscribe({
      next: (response) => {
        console.log('EmailService: Email sent successfully', response);
        this.toastService.show('Email notification sent successfully', 'success');
      },
      error: (error) => {
        console.error('EmailService: Error sending email', error);
        this.toastService.show('Failed to send email notification', 'error');
      }
    });
  }

  sendNewUserApprovalRequest(user: User) {
    console.log('EmailService: sendNewUserApprovalRequest method called');
    const to = 'admin@triggerabsence.com';
    const subject = 'New User Approval Request';
    const text = `A new user ${user.firstName} ${user.lastName} from ${user.organizationName} has signed up and is requesting approval. You can review and approve users at: https://triggerabsence.com/admin/users`;

    console.log('EmailService: Sending email with:', { to, subject, text });
    this.mailgunService.sendEmail(to, subject, text).subscribe({
      next: (response) => {
        console.log('EmailService: Email sent successfully', response);
        this.toastService.show('Approval request notification sent successfully', 'success');
      },
      error: (error) => {
        console.error('EmailService: Error sending email', error);
        this.toastService.show('Failed to send approval request notification', 'error');
      }
    });
  }

  async sendWelcomeEmail(user: User): Promise<void> {
    console.log('EmailService: sendWelcomeEmail method called');
    const to = user.email;
    const subject = 'Welcome to Trigger Absence';
    const text = `Thank you for signing up with Trigger Absence. Please make sure you complete your profile at https://triggerabsence.com/user/account to activate your account.`;

    console.log('EmailService: Sending welcome email to:', to);
    this.mailgunService.sendEmail(to, subject, text).subscribe({
      next: (response) => {
        console.log('EmailService: Welcome email sent successfully', response);
        this.toastService.show('Welcome email sent successfully', 'success');
      },
      error: (error) => {
        console.error('EmailService: Error sending welcome email', error);
        this.toastService.show('Failed to send welcome email', 'error');
      }
    });
  }

  sendRejectionEmail(user: User, reason?: string) {
    console.log('EmailService: sendRejectionEmail method called');
    const to = user.email;
    const subject = 'Trigger Absence Application Status';
    let text = `Unfortunately, you have not been accepted into Trigger Absence.`;
    if (reason) {
      text += ` The reason provided is: "${reason}"`;
    }

    console.log('EmailService: Sending rejection email to:', to);
    this.mailgunService.sendEmail(to, subject, text).subscribe({
      next: (response) => {
        console.log('EmailService: Rejection email sent successfully', response);
        this.toastService.show('Rejection email sent successfully', 'success');
      },
      error: (error) => {
        console.error('EmailService: Error sending rejection email', error);
        this.toastService.show('Failed to send rejection email', 'error');
      }
    });
  }

  sendApprovalEmail(user: User) {
    console.log('EmailService: sendApprovalEmail method called');
    const to = user.email;
    const subject = 'Trigger Absence Application Approved';
    const text = `Congratulations! Your application for Trigger Absence has been approved. You can now log in and start using our services at https://triggerabsence.com`;

    console.log('EmailService: Sending approval email to:', to);
    this.mailgunService.sendEmail(to, subject, text).subscribe({
      next: (response) => {
        console.log('EmailService: Approval email sent successfully', response);
        this.toastService.show('Approval email sent successfully', 'success');
      },
      error: (error) => {
        console.error('EmailService: Error sending approval email', error);
        this.toastService.show('Failed to send approval email', 'error');
      }
    });
  }
}