import { Injectable, inject } from '@angular/core';
import { Firestore, doc, setDoc, collection, query, getDocs, getDoc, where } from '@angular/fire/firestore';
import { AdminStats } from '@shared/models/admin-stats.model';
import { Video, VideoStatus } from '@shared/models/video.model';
import { User } from '@shared/models/user.model';
import { interval, startWith, switchMap, from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminStatsService {
  private firestore: Firestore = inject(Firestore);

  constructor() {
    this.startPeriodicUpdate();
  }

  private startPeriodicUpdate() {
    interval(15 * 60 * 1000).pipe(
      startWith(0),
      switchMap(() => from(this.updateStats()))
    ).subscribe();
  }

  async updateStats() {
    const stats: AdminStats = await this.generateStats();
    const statsDoc = doc(this.firestore, 'adminStats', 'current');
    await setDoc(statsDoc, stats);
  }

  private async generateStats(): Promise<AdminStats> {
    const usersQuery = query(collection(this.firestore, 'users'));
    const videosQuery = query(collection(this.firestore, 'videos'));

    const [usersSnapshot, videosSnapshot] = await Promise.all([
      getDocs(usersQuery),
      getDocs(videosQuery)
    ]);

    const users = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as User));
    const videos = videosSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Video));

    const currentUsers = users.filter(user => user.approvedStatus === 'approved');
    const currentVideos = videos.filter(video => video.status !== 'deleted');

    const generatePriorityStats = (videoList: Video[]) => {
      return videoList.reduce((acc, video) => {
        const priority = Math.round(video.priorityScore * 2) / 2; // Round to nearest 0.5
        if (priority >= 1 && priority <= 5) {
          acc[priority] = (acc[priority] || 0) + 1;
        }
        return acc;
      }, {} as Record<number, number>);
    };

    const generateOccupationStats = (userList: User[]) => {
      return userList.reduce((acc, user) => {
        if (user.occupation) {
          acc[user.occupation] = (acc[user.occupation] || 0) + 1;
        }
        return acc;
      }, {} as Record<string, number>);
    };

    return {
      current: {
        users: currentUsers.length,
        videos: currentVideos.length,
        diagnosedVideos: currentVideos.filter(video => video.status === 'diagnosed').length,
        videosByPriority: generatePriorityStats(currentVideos),
        usersByOccupation: generateOccupationStats(currentUsers)
      },
      allTime: {
        users: users.length,
        videos: videos.length,
        diagnosedVideos: videos.filter(video => video.status === 'diagnosed').length,
        videosByPriority: generatePriorityStats(videos),
        usersByOccupation: generateOccupationStats(users)
      }
    };
  }

  async getStats(): Promise<AdminStats | null> {
    const statsDoc = doc(this.firestore, 'adminStats', 'current');
    const docSnap = await getDoc(statsDoc);
    return docSnap.exists() ? docSnap.data() as AdminStats : null;
  }
}