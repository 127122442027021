import { Injectable } from '@angular/core';
import { Firestore, doc, setDoc, serverTimestamp, collection } from '@angular/fire/firestore';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class DebugLoggerService {
  constructor(
    private firestore: Firestore,
    private userService: UserService
  ) {}

  async logDebugInfo(eventType: string, logs: string[]): Promise<void> {
    try {
      const userId = this.userService.user()?.id;
      if (!userId) {
        console.error('User ID not available for debug logging');
        return;
      }

      const debugCollectionRef = collection(this.firestore, `users/${userId}/debug`);
      const newLogRef = doc(debugCollectionRef);

      await setDoc(newLogRef, {
        timestamp: serverTimestamp(),
        eventType,
        logs,
        deviceInfo: this.getBasicDeviceInfo()
      });

    } catch (error) {
      console.error('Error logging debug info:', error);
    }
  }

  private getBasicDeviceInfo() {
    return {
      userAgent: navigator.userAgent,
      platform: navigator.platform,
      screenSize: `${window.screen.width}x${window.screen.height}`,
      windowSize: `${window.innerWidth}x${window.innerHeight}`
    };
  }
}