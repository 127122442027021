import { Injectable, signal, computed, inject } from '@angular/core';
import { Firestore, doc, getDoc, setDoc, updateDoc, deleteDoc, collection, query, where, getDocs, onSnapshot } from '@angular/fire/firestore';
import { Auth, deleteUser, User as FirebaseUser } from '@angular/fire/auth';
import { User } from '@shared/models/user.model';
import { Observable, from, of, catchError } from 'rxjs';
import { switchMap, take, tap, map } from 'rxjs/operators';
import { Storage, ref, listAll, deleteObject } from '@angular/fire/storage';
import { EmailService } from '@shared/services/email.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private firestore = inject(Firestore);
  private auth = inject(Auth);
  private storage = inject(Storage);
  private emailService = inject(EmailService);

  user = signal<User | null>(null);
  userRoles = signal<string[]>([]);
  isLoading = signal(true);

  isAdmin = computed(() => this.userRoles().includes('admin') || this.userRoles().includes('superadmin'));
  isPractitioner = computed(() => this.userRoles().includes('practitioner'));
  isSuperAdmin = computed(() => this.userRoles().includes('superadmin'));
  isPending = computed(() => {
    const user = this.user();
    return user?.approvedStatus === 'pending' || user?.approvedStatus === undefined;
  });

  userEmail = computed(() => this.user()?.email ?? null);

  constructor() {
    if (environment.bypassAuth) {
      this.setDefaultUser();
    } else {
      this.initializeAuthState();
    }
  }

  private setDefaultUser() {
    const defaultUser: User = {
      id: environment.defaultUserId,
      uid: environment.defaultUserId,
      email: 'default@example.com',
      statusSaved: true,
      createdAt: new Date(),
      roles: ['admin'], // Give admin role to default user
      approvedStatus: 'approved',
      profileComplete: true
    };
    this.updateUserData(defaultUser);
    this.isLoading.set(false);
  }

  private initializeAuthState() {
    this.auth.onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        this.fetchUserDetails(firebaseUser.uid)
          .then(() => {
            this.isLoading.set(false);
          })
          .catch((error) => {
            console.error('Error fetching user details:', error);
            this.isLoading.set(false);
          });
      } else {
        this.clearUserData();
        this.isLoading.set(false);
      }
    });
  }
  

  getUserState(): Observable<User | null> {
    if (environment.bypassAuth) {
      return of(this.user());
    }

    return new Observable<User | null>(observer => {
      const unsubscribe = this.auth.onAuthStateChanged(firebaseUser => {
        if (firebaseUser) {
          this.fetchUserDetails(firebaseUser.uid).then(() => {
            const currentUser = this.user();
            if (currentUser) {
              // Ensure roles are always an array
              currentUser.roles = currentUser.roles || ['general'];
              // Ensure approvedStatus is set
              currentUser.approvedStatus = currentUser.approvedStatus || 'pending';
              // Ensure profileComplete is set
              currentUser.profileComplete = currentUser.profileComplete || false;
            }
            observer.next(currentUser);
          });
        } else {
          this.clearUserData();
          observer.next(null);
        }
      });

      return () => unsubscribe();
    }).pipe(take(1));
  }

  getUserEmail(): string | null {
    return this.userEmail();
  }

  async fetchUserDetails(userId: string) {
    try {
      const userDocRef = doc(this.firestore, 'users', userId);
      const docSnapshot = await getDoc(userDocRef);
      if (docSnapshot.exists()) {
        const userData = docSnapshot.data() as User;
        userData.id = docSnapshot.id;
        this.updateUserData(userData);
      } else {
        // If the user document doesn't exist in Firestore, create it
        const newUser: User = {
          id: userId,
          uid: userId,
          email: this.auth.currentUser?.email || '',
          createdAt: new Date(),
          roles: ['general'],
          approvedStatus: 'pending',
          statusSaved: false
        };
        await setDoc(userDocRef, newUser);
        this.updateUserData(newUser);
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
      this.clearUserData();
    }
  }

  updateUserData(userData: User) {
    this.user.set(userData);
    this.userRoles.set(userData.roles || ['general']);
  }

  clearUserData() {
    this.user.set(null);
    this.userRoles.set([]);
  }

  async updateUserApprovalStatus(userId: string, updateData: Partial<User>): Promise<void> {
    try {
      const userDocRef = doc(this.firestore, 'users', userId);
      await updateDoc(userDocRef, updateData);

      // Update local user data if it's the current user
      if (this.user()?.id === userId) {
        this.user.update(user => ({
          ...user!,
          ...updateData
        }));
      }
    } catch (error) {
      console.error('Error updating user approval status:', error);
      throw error;
    }
  }

  async updateUserProfile(userId: string, profileData: Partial<User>): Promise<User | null> {
    try {
      const userDocRef = doc(this.firestore, 'users', userId);
      const updatedUserData = {
        ...profileData,
        profileComplete: true,
        profileCompleteAccessRequestEmailSent: false,
        profileCompleteAccessRequestDate: new Date()
      };

      await updateDoc(userDocRef, updatedUserData);

      // Update local user data
      this.user.update(user => ({
        ...user!,
        ...updatedUserData
      }));

      // Check if the user is pending approval and send email if necessary
      await this.checkAndSendApprovalRequest(userId);

      return this.user();
    } catch (error) {
      console.error('Error updating user profile:', error);
      throw error;
    }
  }

  private async checkAndSendApprovalRequest(userId: string) {
    const user = this.user();
    if (user && 
        user.profileComplete && 
        user.approvedStatus === 'pending' && 
        !user.profileCompleteAccessRequestEmailSent) {
      await this.emailService.sendNewUserApprovalRequest(user);
      
      // Update the user document to mark that the email has been sent
      const userDocRef = doc(this.firestore, 'users', userId);
      await updateDoc(userDocRef, {
        profileCompleteAccessRequestEmailSent: true
      });

      // Update local user data
      this.user.update(u => ({
        ...u!,
        profileCompleteAccessRequestEmailSent: true
      }));
    }
  }

  isProfileComplete(): Observable<boolean> {
    return this.getUserState().pipe(
      switchMap(user => {
        if (user) {
          return of(user.profileComplete || false);
        }
        return of(false);
      })
    );
  }

  async deleteAccount(): Promise<void> {
    const user = this.auth.currentUser;
    if (!user) throw new Error('No authenticated user');

    try {
      // Delete user document
      await deleteDoc(doc(this.firestore, 'users', user.uid));

      // Delete associated videos and their details
      await this.deleteUserVideos(user.uid);

      // Delete the user's authentication account
      await user.delete();

      // Clear local user data
      this.clearUserData();

      // Sign out
      await this.auth.signOut();
    } catch (error) {
      console.error('Error deleting account:', error);
      throw error;
    }
  }

  private async deleteUserVideos(userId: string): Promise<void> {
    // Delete video documents
    const videosRef = collection(this.firestore, 'videos');
    const q = query(videosRef, where('userId', '==', userId));
    const querySnapshot = await getDocs(q);

    const deletePromises = querySnapshot.docs.map(async (doc) => {
      const videoData = doc.data();
      // Use bracket notation to access 'videoUrl'
      if (videoData['videoUrl']) {
        const videoRef = ref(this.storage, videoData['videoUrl']);
        await deleteObject(videoRef);
      }
      await deleteDoc(doc.ref);
    });

    await Promise.all(deletePromises);
  }

  getPendingUsersCount(): Observable<number> {
    return this.getUserState().pipe(
      switchMap(user => {
        if (user && (this.isAdmin() || this.isSuperAdmin())) {
          return new Observable<number>(observer => {
            const usersRef = collection(this.firestore, 'users');
            const pendingUsersQuery = query(usersRef, where('approvedStatus', '==', 'pending'));
            
            const unsubscribe = onSnapshot(pendingUsersQuery, (snapshot) => {
              observer.next(snapshot.size);
            }, error => {
              console.error('Error fetching pending users count:', error);
              observer.next(0);
            });

            return { unsubscribe };
          });
        } else {
          // Return 0 if the user is not authenticated or doesn't have admin privileges
          return of(0);
        }
      }),
      catchError(error => {
        console.error('Error in getPendingUsersCount:', error);
        return of(0);
      })
    );
  }

  getUserById(userId: string): Observable<User | null> {
    const userDocRef = doc(this.firestore, `users/${userId}`);
    return from(getDoc(userDocRef)).pipe(
      map((docSnap: any) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          return { id: docSnap.id, ...data } as User;
        } else {
          console.log('No user found with ID:', userId); // Add this line for debugging
          return null;
        }
      })
    );
  }

  async deleteUser(userId: string): Promise<void> {
    try {
      // Delete user document from Firestore
      await deleteDoc(doc(this.firestore, `users/${userId}`));

      // Delete user's videos
      await this.deleteUserVideos(userId);

      // Delete user from Firebase Authentication
      const user = this.auth.currentUser;
      if (user && user.uid === userId) {
        await deleteUser(user);
      } else {
        console.warn('Cannot delete Authentication record: User not currently logged in or mismatch in user IDs');
      }

      // Clear local user data if it's the current user
      if (this.user()?.id === userId) {
        this.clearUserData();
        await this.auth.signOut();
      }

    } catch (error) {
      console.error('Error deleting user:', error);
      throw error;
    }
  }

  async createNewUser(userData: User): Promise<void> {
    try {
      console.log('Creating new user in UserService:', userData);
      const userDocRef = doc(this.firestore, 'users', userData.uid);
      
      // Create a new object with only defined properties
      const cleanUserData = Object.entries(userData).reduce((acc, [key, value]) => {
        if (value !== undefined) {
          acc[key as keyof User] = value;
        }
        return acc;
      }, {} as Partial<User>);

      // Add additional fields
      const userDataToSave: User = {
        ...cleanUserData,
        profileComplete: false,
        approvedStatus: 'pending',
        createdAt: new Date(),
        roles: ['general'],
        statusSaved: false,
        id: userData.uid,
        uid: userData.uid,
        email: userData.email
      };

      console.log('Saving user data to Firestore:', userDataToSave);
      await setDoc(userDocRef, userDataToSave);

      console.log('Updating local user data');
      this.updateUserData(userDataToSave);
      
      console.log('Sending welcome email');
      await this.emailService.sendWelcomeEmail(userDataToSave);
      console.log('Welcome email sent');
    } catch (error) {
      console.error('Error creating new user:', error);
      throw error;
    }
  }

  convertFirebaseUserToCustomUser(firebaseUser: FirebaseUser): User {
    return {
      id: firebaseUser.uid,
      uid: firebaseUser.uid,
      email: firebaseUser.email || '',
      displayName: firebaseUser.displayName || undefined,
      photoURL: firebaseUser.photoURL || undefined,
      createdAt: new Date(),
      roles: ['general'],      
      approvedStatus: 'pending',
      statusSaved: false
    };
  }

  getCurrentUserId(): Promise<string | null> {
    return new Promise((resolve, reject) => {
      const unsubscribe = this.auth.onAuthStateChanged(user => {
        unsubscribe();
        if (user) {
          resolve(user.uid);
        } else {
          resolve(null);
        }
      }, reject);
    });
  }

  getCurrentUser(): User | null {
    return this.user();
  }
  
}