import { Component, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navigation',  
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent {
  @ViewChild('drawerCheckbox') drawerCheckbox!: ElementRef<HTMLInputElement>;

  constructor(private renderer: Renderer2, private router: Router) {}

  toggleDrawer() {
    const checked = this.drawerCheckbox.nativeElement.checked;
    this.renderer.setProperty(this.drawerCheckbox.nativeElement, 'checked', !checked);
  }
}