<section class="mt-0 pt-0 my-4 p-4 sm:my-8 sm:p-8 lg:my-12 lg:p-12 max-w-full lg:max-w-screen-xl mx-auto">
  <div class="flex justify-between items-center my-4 sm:my-6 lg:my-8">
    <h3 class="text-xl sm:text-2xl lg:text-4xl font-semibold text-secondary">Patient Data</h3>
  </div>

  <div class="overflow-x-auto">
    <table class="table table-zebra w-full">
      <thead>
        <tr>
          <th>Patient Name</th>
          <th>Date of Birth</th>
          <th>Date of Recording</th>
          @if (patientDataFields.eventDuration) {
            <th>Event Duration</th>
          }
          @if (patientDataFields.frequency) {
            <th>Frequency</th>
          }
          @if (patientDataFields.duration) {
            <th>Duration</th>
          }
          @if (patientDataFields.epilepsy) {
            <th>Epilepsy</th>
          }
          @if (patientDataFields.medication) {
            <th>Medication</th>
          }
          @if (patientDataFields.seizureOccurrence) {
            <th>Seizure Occurrence</th>
          }
          @if (patientDataFields.additionalComments) {
            <th>Additional Comments</th>
          }
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        @for (video of videos(); track video.id) {
          <tr>
            <td>{{ video.patientFirstName }} {{ video.patientLastName }}</td>
            <td>{{ video.patientDateOfBirth | date:'mediumDate' }}</td>
            <td>{{ video.createdAt | formatDate }}</td>
            @if (patientDataFields.eventDuration) {
              <td>{{ video.additionalInfo.eventDuration || '-' }}</td>
            }
            @if (patientDataFields.frequency) {
              <td>{{ video.additionalInfo.frequency || '-' }}</td>
            }
            @if (patientDataFields.duration) {
              <td>{{ video.additionalInfo.duration || '-' }}</td>
            }
            @if (patientDataFields.epilepsy) {
              <td>{{ video.additionalInfo.epilepsy || '-' }}</td>
            }
            @if (patientDataFields.medication) {
              <td>{{ video.additionalInfo.medication || '-' }}</td>
            }
            @if (patientDataFields.seizureOccurrence) {
              <td>{{ video.additionalInfo.seizureOccurrence || '-' }}</td>
            }
            @if (patientDataFields.additionalComments) {
              <td>{{ video.additionalInfo.additionalComments || '-' }}</td>
            }
            <td>
              <a [routerLink]="['/admin/all-videos']" [queryParams]="{videoId: video.id}" class="btn btn-xs btn-outline">View</a>
            </td>
          </tr>
        }
      </tbody>
    </table>
  </div>
</section>