import { Injectable, inject } from '@angular/core';
import { Firestore, collection, addDoc, updateDoc, doc, Timestamp, DocumentReference, setDoc } from '@angular/fire/firestore';
import { Storage, ref, uploadBytesResumable, getDownloadURL } from '@angular/fire/storage';
import { AudioDetectionService } from './audio-detection.service';
import { UnifiedSoundProfile } from '@models/sound-profile.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { Auth } from '@angular/fire/auth';
import * as FFT from 'fft-js';

@Injectable({
    providedIn: 'root'
})
export class SoundProfileRecordingService {
    private firestore = inject(Firestore);
    private storage = inject(Storage);
    private audioDetectionService = inject(AudioDetectionService);
    private auth = inject(Auth);

    private isRecordingSubject = new BehaviorSubject<boolean>(false);
    isRecording$: Observable<boolean> = this.isRecordingSubject.asObservable();

    private isUploadingSubject = new BehaviorSubject<boolean>(false);
    isUploading$: Observable<boolean> = this.isUploadingSubject.asObservable();

    private recordingProgressSubject = new BehaviorSubject<number>(100);
    recordingProgress$: Observable<number> = this.recordingProgressSubject.asObservable();

    private uploadProgressSubject = new BehaviorSubject<number>(0);
    uploadProgress$: Observable<number> = this.uploadProgressSubject.asObservable();

    private mediaRecorder: MediaRecorder | null = null;
    private audioChunks: Blob[] = [];
    private recordingInterval: any;

    private audioContext: AudioContext | null = null;
    private analyser: AnalyserNode | null = null;
    private dataArray: Float32Array | null = null;

    private recordingTimeoutId: number | null = null;

    private mediaStream: MediaStream | null = null;

    private initAudioContext() {
        if (!this.audioContext || this.audioContext.state === 'closed') {
            this.audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
        }
    }

    public async startRecording(isTesting: boolean = false): Promise<void> {
        if (this.mediaRecorder) {
            throw new Error('Recording already in progress');
        }

        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.mediaRecorder = new MediaRecorder(stream);

        this.mediaRecorder.ondataavailable = (event) => {
            this.audioChunks.push(event.data);
        };

        this.mediaRecorder.start();
        if (!isTesting) {
            this.isRecordingSubject.next(true);
        }
    }

    public async saveSoundProfile(type: 'blow' | 'laugh' | 'clap' | 'hello', audioBlob: Blob, analyzedData: Omit<UnifiedSoundProfile, 'id' | 'filename' | 'createdAt' | 'createdBy' | 'type'>): Promise<UnifiedSoundProfile> {
        this.isUploadingSubject.next(true);
        console.log('Starting upload process for sound profile');
        console.log('Audio blob size:', audioBlob.size, 'bytes');
        const user = this.auth.currentUser;
        if (!user) {
            throw new Error('User not authenticated');
            
        }

        try {
            const filename = `${type}-${Date.now()}.${audioBlob.type.split('/')[1]}`;
            const storageRef = ref(this.storage, `soundProfiles/${filename}`);
            console.log(`Uploading file to path: soundProfiles/${filename}`);
            const uploadTask = uploadBytesResumable(storageRef, audioBlob);

            uploadTask.on('state_changed', (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                this.uploadProgressSubject.next(progress);
            });

            await uploadTask;
            const downloadURL = await getDownloadURL(storageRef);
            console.log(`File uploaded successfully. Download URL: ${downloadURL}`);

            const profile: UnifiedSoundProfile = {
                ...analyzedData,
                id: doc(collection(this.firestore, 'config/soundSettings/soundProfiles')).id,
                filename,
                createdAt: Timestamp.now(),
                createdBy: user.uid,
                type,
                downloadURL
            };

            const profileRef = doc(this.firestore, `config/soundSettings/soundProfiles/${profile.id}`);
            await setDoc(profileRef, profile);
            console.log('Sound profile saved to Firestore');

            return profile;
        } catch (error) {
            console.error('Error saving sound profile:', error);
            throw error; // Re-throw the error to be handled by the caller
        } finally {
            this.isUploadingSubject.next(false);
        }
    }

    async recordAndSaveProfile(type: 'blow' | 'laugh' | 'clap' | 'hello'): Promise<UnifiedSoundProfile> {
        await this.startRecording();

        return new Promise((resolve, reject) => {
            setTimeout(async () => {
                try {
                    const audioBlob = await this.stopRecordingAndGetBlob();
                    const analysisResult = await this.analyzeAudioBlob(audioBlob);
                    const profile = await this.saveSoundProfile(type, audioBlob, analysisResult);
                    resolve(profile);
                } catch (error) {
                    reject(error);
                }
            }, 5000); // 5 seconds recording time
        });
    }

    private async stopRecordingAndGetBlob(): Promise<Blob> {
        if (!this.mediaRecorder || this.mediaRecorder.state !== 'recording') {
            throw new Error('No active recording to stop');
        }

        return new Promise((resolve, reject) => {
            this.mediaRecorder!.onstop = () => {
                const audioBlob = new Blob(this.audioChunks, { type: 'audio/webm' });
                this.isRecordingSubject.next(false);
                this.cleanupRecording();
                resolve(audioBlob);
            };

            this.mediaRecorder!.stop();
        });
    }

    private async analyzeAudioBlob(audioBlob: Blob): Promise<Omit<UnifiedSoundProfile, 'id' | 'filename' | 'createdAt' | 'createdBy' | 'type'>> {
        this.initAudioContext();
        const arrayBuffer = await audioBlob.arrayBuffer();
        const audioBuffer = await this.audioContext!.decodeAudioData(arrayBuffer);
        const channelData = audioBuffer.getChannelData(0);
        const sampleRate = audioBuffer.sampleRate;

        const baseAnalysis = await this.analyzeAudioBuffer(audioBuffer);

        // Calculate new features
        const sustainedHighAmplitudeDuration = this.calculateSustainedHighAmplitudeDuration(channelData, sampleRate);
        const { lowFrequencyEnergyRatio, highFrequencyEnergyRatio } = this.calculateFrequencyEnergyRatios(baseAnalysis.fftMagnitudes);
        const amplitudeVariation = this.calculateAmplitudeVariation(channelData);

        return {
            ...baseAnalysis,
            sustainedHighAmplitudeDuration,
            lowFrequencyEnergyRatio,
            highFrequencyEnergyRatio,
            amplitudeVariation,
        };
    }

    private calculateSustainedHighAmplitudeDuration(channelData: Float32Array, sampleRate: number): number {
        const threshold = 0.5; // Adjust as needed
        let sustainedDuration = 0;
        let currentDuration = 0;

        for (let i = 0; i < channelData.length; i++) {
            if (Math.abs(channelData[i]) > threshold) {
                currentDuration++;
            } else {
                sustainedDuration = Math.max(sustainedDuration, currentDuration);
                currentDuration = 0;
            }
        }

        sustainedDuration = Math.max(sustainedDuration, currentDuration);
        return sustainedDuration / sampleRate;
    }

    private calculateFrequencyEnergyRatios(magnitudes: number[]): { lowFrequencyEnergyRatio: number, highFrequencyEnergyRatio: number } {
        const lowFrequencyThreshold = magnitudes.length / 4;
        const highFrequencyThreshold = magnitudes.length * 3 / 4;

        let lowEnergy = 0;
        let highEnergy = 0;
        let totalEnergy = 0;

        for (let i = 0; i < magnitudes.length; i++) {
            if (i < lowFrequencyThreshold) {
                lowEnergy += magnitudes[i];
            } else if (i > highFrequencyThreshold) {
                highEnergy += magnitudes[i];
            }
            totalEnergy += magnitudes[i];
        }

        return {
            lowFrequencyEnergyRatio: lowEnergy / totalEnergy,
            highFrequencyEnergyRatio: highEnergy / totalEnergy
        };
    }

    private calculateAmplitudeVariation(channelData: Float32Array): number {
        const mean = channelData.reduce((sum, val) => sum + Math.abs(val), 0) / channelData.length;
        const variance = channelData.reduce((sum, val) => sum + Math.pow(Math.abs(val) - mean, 2), 0) / channelData.length;
        return Math.sqrt(variance);
    }

    private analyzeAudioBuffer(audioBuffer: AudioBuffer): Omit<UnifiedSoundProfile, 'id' | 'filename' | 'createdAt' | 'createdBy' | 'type'> {
        const channelData = audioBuffer.getChannelData(0);
        const sampleRate = audioBuffer.sampleRate;

        // Calculate average, max, and min levels
        let sum = 0;
        let max = -Infinity;
        let min = Infinity;
        for (let i = 0; i < channelData.length; i++) {
            const abs = Math.abs(channelData[i]);
            sum += abs;
            if (abs > max) max = abs;
            if (abs < min) min = abs;
        }
        const averageLevel = sum / channelData.length;

        // Calculate dominant frequency using FFT
        const fftSize = 2048; // Use a power of 2 for FFT
        const fftInput = channelData.slice(0, fftSize);
        const fftResult = FFT.fft(fftInput);
        const magnitudes = FFT.util.fftMag(fftResult);
        const dominantFrequencyIndex = magnitudes.indexOf(Math.max(...magnitudes));
        const dominantFrequency = dominantFrequencyIndex * sampleRate / fftSize;

        // Calculate rise and fall times (simplified)
        const threshold = averageLevel * 0.5;
        let riseTime = 0;
        let fallTime = 0;
        for (let i = 0; i < channelData.length; i++) {
            if (Math.abs(channelData[i]) > threshold) {
                riseTime = i / sampleRate;
                break;
            }
        }
        for (let i = channelData.length - 1; i >= 0; i--) {
            if (Math.abs(channelData[i]) > threshold) {
                fallTime = (channelData.length - i) / sampleRate;
                break;
            }
        }

        // Calculate spectral centroid
        let spectralCentroid = 0;
        let totalMagnitude = 0;
        for (let i = 0; i < magnitudes.length; i++) {
            spectralCentroid += i * magnitudes[i];
            totalMagnitude += magnitudes[i];
        }
        spectralCentroid /= totalMagnitude;

        // Normalize spectral centroid (0-1 range)
        const normalizedSpectralCentroid = spectralCentroid / (magnitudes.length / 2);
        // Calculate new features
        const sustainedHighAmplitudeDuration = this.calculateSustainedHighAmplitudeDuration(channelData, sampleRate);
        const { lowFrequencyEnergyRatio, highFrequencyEnergyRatio } = this.calculateFrequencyEnergyRatios(magnitudes);
        const amplitudeVariation = this.calculateAmplitudeVariation(channelData);

        return {
            deviceType: null,
            peakAmplitude: max,
            duration: audioBuffer.duration,
            riseTime,
            fallTime,
            dominantFrequency,
            breathingPattern: [], // This would require more complex analysis
            averageLevel,
            maxLevel: max,
            minLevel: min,
            threshold,
            frequencyProfile: Array.from(magnitudes), // Store the FFT magnitudes
            fftMagnitudes: Array.from(magnitudes), // Duplicate of frequencyProfile
            backgroundNoiseLevel: min,
            consistencyScore: 1 - (max - min) / max, // Simple consistency measure
            peakFrequency: dominantFrequency,
            spectralCentroid,
            normalizedSpectralCentroid,
            // Add the new properties
            sustainedHighAmplitudeDuration,
            lowFrequencyEnergyRatio,
            highFrequencyEnergyRatio,
            amplitudeVariation,
        };
    }


    private async analyzeAndUpdateProfile(profile: UnifiedSoundProfile, audioBlob: Blob): Promise<UnifiedSoundProfile> {
        try {
            this.initAudioContext();
            const arrayBuffer = await audioBlob.arrayBuffer();
            console.log('Audio blob size:', audioBlob.size, 'bytes');
            console.log('ArrayBuffer size:', arrayBuffer.byteLength, 'bytes');
            console.log('Audio MIME type:', audioBlob.type);
    
            let audioBuffer;
            try {
                audioBuffer = await this.audioContext!.decodeAudioData(arrayBuffer);
                console.log('AudioBuffer successfully created');
            } catch (decodeError) {
                console.error('Error decoding audio data:', decodeError);
                throw new Error('Unable to decode audio data. The audio format may be unsupported.');
            }
    
            const audioData = audioBuffer.getChannelData(0);
            const uint8AudioData = new Uint8Array(audioData.buffer);
            const { detectedSound, features } = this.audioDetectionService.analyzeAudio(uint8AudioData);
    
            // Update the profile with the analysis results
            const updatedProfile: Partial<UnifiedSoundProfile> = {
                analyzedFeatures: features,
            };
    
            if (detectedSound !== null && detectedSound !== undefined) {
                updatedProfile.detectedSound = detectedSound;
            }
    
            const profileRef = doc(this.firestore, `config/soundSettings/soundProfiles/${profile.id}`);
            await updateDoc(profileRef, updatedProfile);
            console.log('Profile updated in Firestore with analysis results');
    
            return { ...profile, ...updatedProfile };
        } catch (error) {
            console.error('Error analyzing and updating profile:', error);
            // Return the original profile if analysis fails
            return profile;
        }
    }

    private cleanupRecording(): void {
        if (this.mediaStream) {
            this.mediaStream.getTracks().forEach(track => track.stop());
            this.mediaStream = null;
        }
        // Don't close the audioContext here, as it might be needed for playback
        this.mediaRecorder = null;
        this.audioChunks = [];
        this.analyser = null;
        this.dataArray = null;
    }

    public async stopRecordingAndAnalyze(): Promise<Omit<UnifiedSoundProfile, 'id' | 'filename' | 'createdAt' | 'createdBy' | 'type'>> {
        if (!this.mediaRecorder) {
            throw new Error('No active recording to stop');
        }

        return new Promise((resolve, reject) => {
            this.mediaRecorder!.onstop = async () => {
                try {
                    const audioBlob = new Blob(this.audioChunks, { type: 'audio/webm' });
                    const result = await this.analyzeAudioBlob(audioBlob);
                    this.cleanupRecording();
                    resolve(result);
                } catch (error) {
                    reject(error);
                }
            };

            this.mediaRecorder!.stop();
        });
    }
}
