/*===========================================================================*\
 * Inverse Discrete Fourier Transform (O(n^2) brute-force method)
 *
 * (c) Maximilian Bügler. 2016
 *
 * Based on and using the code by
 * (c) Vail Systems. Joshua Jung and Ben Bryan. 2015
 *
 * This code is not designed to be highly optimized but as an educational
 * tool to understand the Fast Fourier Transform.
\*===========================================================================*/

//------------------------------------------------
// Note: Some of this code is not optimized and is
// primarily designed as an educational and testing
// tool.
//-------------------------------------------------

//-------------------------------------------------
// The following code assumes a complex number is
// an array: [real, imaginary]
//-------------------------------------------------
var dft = require('./dft');
function idft(signal) {
  //Interchange real and imaginary parts
  var csignal = [];
  for (var i = 0; i < signal.length; i++) {
    csignal[i] = [signal[i][1], signal[i][0]];
  }

  //Apply dft
  var ps = dft(csignal);

  //Interchange real and imaginary parts and normalize
  var res = [];
  for (var j = 0; j < ps.length; j++) {
    res[j] = [ps[j][1] / ps.length, ps[j][0] / ps.length];
  }
  return res;
}
module.exports = idft;