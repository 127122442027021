import { Component, OnInit, signal, computed, inject, OnDestroy, ViewChild } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { collection, getDocs, deleteDoc, doc, Timestamp, onSnapshot, query, where } from 'firebase/firestore';
import { ref, deleteObject } from 'firebase/storage';
import { firestore, storage } from '@shared/firebase-config';
import { VideoDetailsComponent } from '@shared/components/video-details/video-details.component';
import { Video, VideoStatus } from '@shared/models/video.model';
import { UserService } from '@services/user.service';
import { User } from '@shared/models/user.model';
import { Subscription } from 'rxjs';
import { ToastService } from '@shared/services/toast.service';
import { TimestampToDatePipe } from '@shared/pipes/timestamp-to-date.pipe';
import { FormatDatePipe } from '@shared/pipes/format-date.pipe';
import { DeleteRunwayService } from '@shared/services/delete-runway.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { FfmpegWorkerService } from '../../../shared/services/ffmpeg-worker.service';

@Component({
  selector: 'app-videos',
  standalone: true,
  imports: [CommonModule, VideoDetailsComponent, TimestampToDatePipe, FormatDatePipe],
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.css']
})
export class VideosComponent implements OnInit, OnDestroy {
  private videosSignal = signal<Video[]>([]);
  videos = computed(() => this.sortVideos(this.videosSignal()));
  isAddVideoModalOpen = signal(false);
  editingVideo = signal<Video | null>(null);
  editingVideoData = signal<{ url: string, duration: number } | null>(null);
  
  private userService = inject(UserService);
  private formatDatePipe = inject(FormatDatePipe);
  user = signal<User | null>(null);
  private userSubscription: Subscription | null = null;
  
  canAddVideo = computed(() => {
    const currentUser = this.user();
    if (!currentUser) return false;

    // Allow if user is approved and profile is complete
    if (currentUser.approvedStatus === 'approved' && currentUser.profileComplete) return true;

    // Allow if user is approved (for manually added users who might not have a complete profile)
    if (currentUser.approvedStatus === 'approved') return true;

    // Allow if user has admin or superadmin role
    if (currentUser.roles && (currentUser.roles.includes('admin') || currentUser.roles.includes('superadmin'))) return true;

    return false;
  });
  
  showTooltip = signal(false);

  onDrawerClosed(): void {
    this.removeVideoIdParam();
  }

  private removeVideoIdParam(): void {
    const urlTree = this.router.createUrlTree([], {
      queryParams: { videoId: null },
      queryParamsHandling: 'merge',
      preserveFragment: true
    });
    this.router.navigateByUrl(urlTree);
  }

  private toastService = inject(ToastService);
  toastMessage: string | null = null;

  formDirty = false;

  private deleteRunwayService = inject(DeleteRunwayService);

  private videosLoaded = signal(false);

  private worker: Worker | null = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private swUpdate: SwUpdate,
    private ffmpegWorkerService: FfmpegWorkerService,
    // ... other dependencies
  ) {
   
  }


  ngOnInit() {
    this.subscribeToUser();
    this.cleanUrlOnRefresh();
    if (this.worker) {
      this.worker.postMessage('start');
    }

    // Example: Sending a trim command to the worker
    // Uncomment and modify as needed
    /*
    const fileName = 'example.mp4';
    const fileData = new ArrayBuffer(); // Your video data here
    const startTime = 10; // seconds
    const endTime = 20; // seconds

    this.ffmpegWorkerService.sendMessage({ command: 'trim', fileName, fileData, startTime, endTime });
    */
  }

  private subscribeToUser() {
    this.userSubscription = this.userService.getUserState().subscribe(user => {
      this.user.set(user);
      if (user) {
        console.log('Current user:', {
          uid: user.uid,
          approvedStatus: user.approvedStatus,
          profileComplete: user.profileComplete
        });
        console.log('Can add video:', this.canAddVideo());
        this.loadVideos().then(() => {
          this.videosLoaded.set(true);
          this.handleQueryParams();
        });
      } else {
        console.log('No user logged in');
        this.videosSignal.set([]);
        this.videosLoaded.set(false);
      }
    });
  }

  private handleQueryParams() {
    this.route.queryParams.subscribe(params => {
      const videoId = params['videoId'];
      const state = params['state'];
      
      if (videoId) {
        console.log('VideoId found in URL:', videoId);
        this.openEditDrawer(videoId, state);
        if (state === 'readyToRecord') {
          this.triggerRecordingModal(videoId);
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.worker) {
      this.worker.postMessage('stop');
      this.worker.terminate();
    }
    // Terminate the worker to free resources
    this.ffmpegWorkerService.terminate();
  }

  private subscribeToVideos(userId: string) {
    const videosCollection = collection(firestore, 'videos');
    const userVideosQuery = query(videosCollection, where('userId', '==', userId));
    
    onSnapshot(userVideosQuery, (snapshot) => {
      const videosData = snapshot.docs.map(doc => this.convertToVideo(doc));
      this.videosSignal.set(videosData);
    });
  }

  private convertToVideo(doc: any): Video {
    const data = doc.data();
    return {
      id: doc.id,
      patientFirstName: data['patientFirstName'] as string,
      patientLastName: data['patientLastName'] as string,
      patientDateOfBirth: this.parseDate(data['patientDateOfBirth']),
      description: data['description'] as string,
      status: data['status'] as VideoStatus,
      createdAt: this.convertTimestamp(data['createdAt'] as Timestamp),
      userId: data['userId'] as string,
      videoUrl: data['videoUrl'] as string | undefined,
      priorityScore: data['priorityScore'] as number,
      additionalInfo: data['additionalInfo'] || { symptoms: {}, conditions: {} }
    };
  }

  private parseDate(dateString: string | Timestamp | null): Date | null {
    if (!dateString) return null;
    if (dateString instanceof Timestamp) {
      return dateString.toDate();
    }
    const parsedDate = new Date(dateString);
    return isNaN(parsedDate.getTime()) ? null : parsedDate;
  }

  private convertTimestamp(timestamp: Timestamp | Date): Date {
    if (timestamp instanceof Timestamp) {
      return timestamp.toDate();
    }
    return timestamp as Date;
  }

  async deleteVideo(videoId: string, videoUrl: string | undefined) {
    if (confirm('Are you sure you want to delete this video?')) {
      try {
        if (videoUrl) {
          // Move the video file to the delete runway
          await this.deleteRunwayService.moveToDeleteRunway(videoUrl, 'recordings');
        }

        // Delete the Firestore document
        await this.deleteRunwayService.deleteFirestoreDocument('videos', videoId);

        console.log('Video deleted successfully');
        this.toastService.show('Video moved to delete runway', 'success');
      } catch (error) {
        console.error('Error deleting video:', error);
        this.toastService.show('Error deleting video', 'error');
      }
    }
  }

  openAddVideoModal() {
    if (this.canAddVideo()) {
      this.editingVideo.set(null);
      this.isAddVideoModalOpen.set(true);
      const drawerToggle = document.getElementById('add-video-drawer') as HTMLInputElement;
      if (drawerToggle) {
        drawerToggle.checked = true;
      }
    }
  }

  editVideo(videoId: string) {
    const videoToEdit = this.videos().find(v => v.id === videoId);
    if (videoToEdit) {
      console.log('Editing video:', videoToEdit);
      this.editingVideo.set(videoToEdit);
      this.isAddVideoModalOpen.set(true);
      this.updateUrl(videoId);
    } else {
      console.error('Video not found for editing');
    }
  }

  private updateUrl(videoId: string | null, state?: string) {
    console.group('Edit Video URL Debug');
    console.log('Current URL:', this.location.path());
    
    const queryParams: any = { videoId };
    if (state && state !== 'readyToRecord') {
      queryParams.state = state;
    }
    const expectedUrl = this.router.createUrlTree([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    }).toString();
    console.log('Expected URL:', expectedUrl);
    
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    });

    // Log the updated URL after navigation
    setTimeout(() => {
      console.log('Updated URL:', this.location.path());
    }, 0);

    console.groupEnd();
  }

  openDrawer(): void {
    console.log('openDrawer called, setting isAddVideoModalOpen to true');
    this.isAddVideoModalOpen.set(true);
  }

  toggleDrawer() {
    this.isAddVideoModalOpen.update(value => !value);
  }

  onVideoSaved(updatedVideo: Video) {
    console.log('Video saved:', updatedVideo);
    
    const currentVideos = this.videosSignal();
    const index = currentVideos.findIndex(v => v.id === updatedVideo.id);
    
    if (index !== -1) {
      // Update existing video
      const updatedVideos = [...currentVideos];
      updatedVideos[index] = updatedVideo;
      this.videosSignal.set(updatedVideos);
    } else {
      // Add new video
      this.videosSignal.set([...currentVideos, updatedVideo]);
    }
    
    this.closeDrawer();
    this.refreshVideos(); // Refresh the video list
  }

  onVideoDraftSaved() {
    this.closeDrawer();
    this.refreshVideos();
    this.toastService.show('Draft saved', 'success');
  }

  onDraftSaved(message: string) {
    this.toastMessage = message;
    setTimeout(() => {
      this.toastMessage = null;
    }, 3000); // Hide the toast after 3 seconds
  }

  onButtonHover(isHovering: boolean): void {
    this.showTooltip.set(isHovering && !this.canAddVideo());
  }

  calculatePatientAge(dob: Date | null): string {
    if (!dob) return 'N/A';
    const today = new Date();
    let age = today.getFullYear() - dob.getFullYear();
    const monthDiff = today.getMonth() - dob.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
      age--;
    }
    return `${age} years`;
  }

  private sortVideos(videos: Video[]): Video[] {
    return videos.sort((a, b) => {
      const dateA = a.createdAt instanceof Timestamp ? a.createdAt.toDate() : new Date(a.createdAt);
      const dateB = b.createdAt instanceof Timestamp ? b.createdAt.toDate() : new Date(b.createdAt);
      return dateB.getTime() - dateA.getTime();
    });
  }

  private refreshVideos() {
    this.fetchVideos().then(videos => {
      this.videosSignal.set(videos);
    });
  }

  private async fetchVideos(): Promise<Video[]> {
    const user = this.user();
    if (!user) throw new Error('User is not authenticated');
    const videosCollection = collection(firestore, 'videos');
    const userVideosQuery = query(videosCollection, where('userId', '==', user.uid));
    const snapshot = await getDocs(userVideosQuery);
    return snapshot.docs.map(doc => this.convertToVideo(doc));
  }

  onDrawerChange(event: Event): void {
    const isChecked = (event.target as HTMLInputElement).checked;
    if (!isChecked) {
      // Drawer is being closed
      if (this.canCloseDrawer()) {
        this.closeDrawer();
      } else {
        // Prevent closing
        (event.target as HTMLInputElement).checked = true;
      }
    } else {
      // Drawer is being opened
      this.openDrawer();
    }
  }

  private allowDrawerClose = false;

  canCloseDrawer(): boolean {
    return this.allowDrawerClose;
  }

  private resetAndCloseDrawer(): void {
    this.editingVideo.set(null);
    this.formDirty = false;
    this.isAddVideoModalOpen.set(false);
    const drawerToggle = document.getElementById('add-video-drawer') as HTMLInputElement;
    if (drawerToggle) {
      drawerToggle.checked = false;
    }
    if (this.VideoDetailsComponent) {
      this.VideoDetailsComponent.resetForm();
    }
    this.removeUrlParams();
  }

  handleFormDirtyChange(isDirty: boolean): void {
    console.log('VideosComponent: Received form dirty change:', isDirty);
    this.formDirty = isDirty;
  }

  onVideoRecorded(videoData: { blob: Blob, duration: number }) {
    console.log('Video recorded:', videoData);
    const videoUrl = URL.createObjectURL(videoData.blob);
    const duration = videoData.duration || 0; // Use 0 as a fallback if duration is undefined
    
    this.editingVideoData.set({ url: videoUrl, duration: duration });
    this.openDrawer();
  }

  @ViewChild(VideoDetailsComponent) VideoDetailsComponent!: VideoDetailsComponent;

  handleAttemptCloseWithUnsavedChanges(hasUnsavedChanges: boolean): void {
    if (hasUnsavedChanges) {
      if (confirm('You have unsaved changes. Are you sure you want to close?')) {
        this.resetAndCloseDrawer();
      }
    } else {
      this.resetAndCloseDrawer();
    }
  }

  attemptCloseDrawer(event: MouseEvent): void {
    // Prevent the default behavior
    event.preventDefault();
    
    // Attempt to close the drawer
    this.closeDrawer();
  }

  onVideoDeleted(videoId: string) {
    // Remove the deleted video from the list
    const updatedVideos = this.videosSignal().filter((v: Video) => v.id !== videoId);
    this.videosSignal.set(updatedVideos);
    // Close the detail drawer
    this.closeDrawer();
  }

  closeDrawer(): void {
    console.log('VideosComponent: closeDrawer called, form dirty:', this.formDirty);
    if (this.formDirty) {
      if (confirm('You have unsaved changes. Are you sure you want to close?')) {
        this.resetAndCloseDrawer();
      } else {
        // Keep the drawer open
        return;
      }
    } else {
      this.resetAndCloseDrawer();
    }
    this.removeUrlParams();
  }

  openEditDrawer(videoId: string, state?: string) {
    console.log('Opening edit drawer for video:', videoId);
    if (!this.videosLoaded()) {
      console.log('Videos not loaded yet, waiting...');
      this.loadVideos().then(() => {
        this.findAndOpenVideo(videoId, state);
      });
    } else {
      this.findAndOpenVideo(videoId, state);
    }
  }

  private findAndOpenVideo(videoId: string, state?: string) {
    const videoToEdit = this.videos().find(v => v.id === videoId);
    if (videoToEdit) {
      console.log('Video found:', videoToEdit);
      this.editingVideo.set(videoToEdit);
      this.isAddVideoModalOpen.set(true);
      this.updateUrl(videoId, state);
    } else {
      console.error('Video not found for editing');
      this.toastService.show('Video not found', 'error');
    }
  }

  async loadVideos() {
    const currentUser = this.user();
    if (!currentUser) {
      console.log('User not authenticated, skipping video load');
      return;
    }
    try {
      const videos = await this.fetchVideos();
      this.videosSignal.set(videos);
    } catch (error) {
      console.error('Error loading videos:', error);
      this.toastService.show('Error loading videos', 'error');
    }
  }


  private triggerRecordingModal(videoId: string) {
    // This method should trigger the recording modal in the VideoDetailsComponent
    // You might need to use a service or an event emitter to communicate between components
    console.log('Triggering recording modal for video:', videoId);
    // Implement the logic to open the recording modal
  }

  onCloseDrawer() {
    this.isAddVideoModalOpen.set(false);
    this.editingVideo.set(null);
    // The URL update is now handled in the VideoDetailsComponent
  }

  async confirmDelete(videoId: string | undefined, videoUrl: string | undefined): Promise<void> {
    if (!videoId) {
      this.toastService.show('Invalid video ID', 'error');
      return;
    }

    if (confirm('Are you sure you want to delete this video? This action cannot be undone.')) {
      try {
        if (videoUrl) {
          await this.deleteRunwayService.moveToDeleteRunway(videoUrl, 'videos');
        }
        await this.deleteRunwayService.deleteFirestoreDocument('videos', videoId);
        this.toastService.show('Video deleted successfully', 'success');
        this.loadVideos(); // Refresh the video list
      } catch (error) {
        console.error('Error deleting video:', error);
        this.toastService.show('Error deleting video', 'error');
      }
    }
  }

  private cleanUrlOnRefresh() {
    // Remove all query parameters on page refresh
    if (!this.router.navigated) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {},
        replaceUrl: true
      });
    }
  }

  private removeUrlParams(): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {},
      replaceUrl: true
    });
  }

  onVideoTrimmed(trimmedVideoUrl: string) {
    if (this.editingVideo()) {
      this.editingVideo.update(currentVideo => {
        if (currentVideo) {
          return {
            ...currentVideo,
            videoUrl: trimmedVideoUrl
          };
        }
        return currentVideo; // Return the current video if it's null or undefined
      });
      // You might want to save this change to your backend here
    }
  }
}