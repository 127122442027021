import { Component, OnInit, signal, computed, inject, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { collection, query, where, onSnapshot, Timestamp } from 'firebase/firestore';
import { firestore } from '@shared/firebase-config';
import { UserService } from '@services/user.service';
import { User } from '@shared/models/user.model';
import { Subscription } from 'rxjs';
import { ToastService } from '@shared/services/toast.service';
import { Video, VideoStatus } from '@shared/models/video.model';
import { VideoDetails, PatientDataFields } from '@shared/models/video-details.model';
import { FormatDatePipe } from '@shared/pipes/format-date.pipe';

@Component({
  selector: 'app-patient-data',
  standalone: true,
  imports: [CommonModule, FormatDatePipe, RouterModule],
  templateUrl: './patient-data.component.html',
  styles: []
})
export class PatientDataComponent implements OnInit, OnDestroy {
  private videosSignal = signal<Video[]>([]);
  videos = computed(() => this.videosSignal().filter(video => video.status === 'submitted'));

  private userService = inject(UserService);
  private toastService = inject(ToastService);
  private formatDatePipe = inject(FormatDatePipe);
  user = signal<User | null>(null);
  private userSubscription: Subscription | null = null;

  patientDataFields: PatientDataFields = {
    eventDuration: true,
    frequency: true,
    duration: true,
    epilepsy: true,
    medication: true,
    seizureOccurrence: true,
    visitDuration: false,
    easeOfUse: false,
    patientEaseOfUse: false,
    additionalComments: false,
    patientConsent: true
  };

  ngOnInit() {
    this.subscribeToUser();
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  private subscribeToUser() {
    this.userSubscription = this.userService.getUserState().subscribe(user => {
      this.user.set(user);
      if (user) {
        this.loadVideos(user.uid);
      } else {
        this.videosSignal.set([]);
      }
    });
  }

  private loadVideos(userId: string) {
    const videosCollection = collection(firestore, 'videos');
    const userVideosQuery = query(videosCollection, where('userId', '==', userId));
    
    onSnapshot(userVideosQuery, (snapshot) => {
      const videosArray = snapshot.docs.map(doc => this.convertToVideo(doc));
      this.videosSignal.set(videosArray);
    }, (error) => {
      console.error('Error fetching videos:', error);
      this.toastService.show('Error loading patient data', 'error');
    });
  }

  private convertToVideo(doc: any): Video {
    const data = doc.data();
    const createdAt = this.ensureValidDate(data['createdAt']);
    const patientDateOfBirth = this.parseDate(data['patientDateOfBirth']);
    
    return {
      id: doc.id,
      patientFirstName: data['patientFirstName'] as string,
      patientLastName: data['patientLastName'] as string,
      patientDateOfBirth: patientDateOfBirth ? new Date(this.formatDatePipe.transform(patientDateOfBirth) || '') : null,
      description: data['description'] as string,
      status: data['status'] as VideoStatus,
      createdAt: createdAt,
      userId: data['userId'] as string,
      videoUrl: data['videoUrl'] as string | undefined,
      priorityScore: data['priorityScore'] as number,
      additionalInfo: data['additionalInfo'] as VideoDetails || { symptoms: {}, conditions: {} }
    };
  }

  private ensureValidDate(value: any): Date | Timestamp {
    if (value instanceof Timestamp) {
      return value;
    }
    if (value instanceof Date) {
      return value;
    }
    if (typeof value === 'string') {
      const parsedDate = new Date(value);
      return isNaN(parsedDate.getTime()) ? new Date() : parsedDate;
    }
    if (typeof value === 'object' && value && 'seconds' in value && 'nanoseconds' in value) {
      return new Timestamp(value.seconds, value.nanoseconds);
    }
    return new Date(); // Default to current date if all else fails
  }

  private parseDate(value: any): Date | null {
    if (value instanceof Timestamp) {
      return value.toDate();
    }
    if (value instanceof Date) {
      return value;
    }
    if (typeof value === 'string') {
      const parsedDate = new Date(value);
      return isNaN(parsedDate.getTime()) ? null : parsedDate;
    }
    if (typeof value === 'object' && value && 'seconds' in value && 'nanoseconds' in value) {
      return new Date(value.seconds * 1000 + value.nanoseconds / 1000000);
    }
    return null;
  }
}